@charset "UTF-8";
* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-backface-visibility: hidden;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img,
ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody,
tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time,
mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

html:not(.isMobile) {
  overflow: hidden;
}

html.isMobile {
  overflow-x: hidden;
}

body {
  line-height: 1;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}

ol, ul {
  list-style: none;
}

strong, b {
  font-weight: bold;
}

input:focus {
  outline: none;
}

blockquote, q {
  quotes: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a[ng-click], div[ng-click], p[ng-click], span[ng-click], img[ng-click], a[ui-sref], div[ui-sref], p[ui-sref], span[ui-sref], img[ui-sref] {
  cursor: pointer;
}

textarea:hover, input:hover, textarea:active, input:active, textarea:focus, input:focus, button:focus, button:active, button:hover, label:focus, .btn:active, .btn.active {
  outline: 0px !important;
  -webkit-appearance: none;
}

.slick-slide, .slick-slide a, .slick-slide:focus {
  outline: none !important;
}

input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus input:-webkit-autofill, textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus, select:-webkit-autofill, select:-webkit-autofill:hover, select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px #fff inset !important;
}

a {
  -webkit-tap-highlight-color: transparent;
}

.row:after {
  content: "";
  display: block;
  clear: both;
}

.fixPadding {
  width: calc(100% + 1% + 1%);
  margin-left: -1%;
  margin-right: -1%;
}

.col1 {
  width: 4.1666666667%;
}

.col2 {
  width: 8.3333333333%;
}

.col3 {
  width: 12.5%;
}

.col4 {
  width: 16.6666666667%;
}

.col5 {
  width: 20.8333333333%;
}

.col6 {
  width: 25%;
}

.col7 {
  width: 29.1666666667%;
}

.col8 {
  width: 33.3333333333%;
}

.col9 {
  width: 37.5%;
}

.col10 {
  width: 41.6666666667%;
}

.col11 {
  width: 45.8333333333%;
}

.col12 {
  width: 50%;
}

.col13 {
  width: 54.1666666667%;
}

.col14 {
  width: 58.3333333333%;
}

.col15 {
  width: 62.5%;
}

.col16 {
  width: 66.6666666667%;
}

.col17 {
  width: 70.8333333333%;
}

.col18 {
  width: 75%;
}

.col19 {
  width: 79.1666666667%;
}

.col20 {
  width: 83.3333333333%;
}

.col21 {
  width: 87.5%;
}

.col22 {
  width: 91.6666666667%;
}

.col23 {
  width: 95.8333333333%;
}

.col24 {
  width: 100%;
}

.col, .col1, .col2, .col3, .col4, .col5, .col6, .col7, .col8, .col9, .col10, .col11, .col12,
.col13, .col14, .col15, .col16, .col17, .col18, .col19, .col20, .col21, .col22, .col23, .col24 {
  display: block;
  float: left;
  min-height: 1px;
  padding: 0 1%;
}

@media screen and (max-width: 1800px) {
  .col1_1800 {
    width: 4.1666666667%;
  }
  .col2_1800 {
    width: 8.3333333333%;
  }
  .col3_1800 {
    width: 12.5%;
  }
  .col4_1800 {
    width: 16.6666666667%;
  }
  .col5_1800 {
    width: 20.8333333333%;
  }
  .col6_1800 {
    width: 25%;
  }
  .col7_1800 {
    width: 29.1666666667%;
  }
  .col8_1800 {
    width: 33.3333333333%;
  }
  .col9_1800 {
    width: 37.5%;
  }
  .col10_1800 {
    width: 41.6666666667%;
  }
  .col11_1800 {
    width: 45.8333333333%;
  }
  .col12_1800 {
    width: 50%;
  }
  .col13_1800 {
    width: 54.1666666667%;
  }
  .col14_1800 {
    width: 58.3333333333%;
  }
  .col15_1800 {
    width: 62.5%;
  }
  .col16_1800 {
    width: 66.6666666667%;
  }
  .col17_1800 {
    width: 70.8333333333%;
  }
  .col18_1800 {
    width: 75%;
  }
  .col19_1800 {
    width: 79.1666666667%;
  }
  .col20_1800 {
    width: 83.3333333333%;
  }
  .col21_1800 {
    width: 87.5%;
  }
  .col22_1800 {
    width: 91.6666666667%;
  }
  .col23_1800 {
    width: 95.8333333333%;
  }
  .col24_1800 {
    width: 100%;
  }
  .col1_1800, .col2_1800, .col3_1800, .col4_1800, .col5_1800, .col6_1800,
  .col7_1800, .col8_1800, .col9_1800, .col10_1800, .col11_1800, .col12_1800,
  .col13_1800, .col14_1800, .col15_1800, .col16_1800, .col17_1800, .col18_1800,
  .col19_1800, .col20_1800, .col21_1800, .col22_1800, .col23_1800, .col24_1800 {
    display: block;
    float: left;
    min-height: 1px;
    padding: 0 1%;
  }
}
@media screen and (max-width: 1700px) {
  .col1_1700 {
    width: 4.1666666667%;
  }
  .col2_1700 {
    width: 8.3333333333%;
  }
  .col3_1700 {
    width: 12.5%;
  }
  .col4_1700 {
    width: 16.6666666667%;
  }
  .col5_1700 {
    width: 20.8333333333%;
  }
  .col6_1700 {
    width: 25%;
  }
  .col7_1700 {
    width: 29.1666666667%;
  }
  .col8_1700 {
    width: 33.3333333333%;
  }
  .col9_1700 {
    width: 37.5%;
  }
  .col10_1700 {
    width: 41.6666666667%;
  }
  .col11_1700 {
    width: 45.8333333333%;
  }
  .col12_1700 {
    width: 50%;
  }
  .col13_1700 {
    width: 54.1666666667%;
  }
  .col14_1700 {
    width: 58.3333333333%;
  }
  .col15_1700 {
    width: 62.5%;
  }
  .col16_1700 {
    width: 66.6666666667%;
  }
  .col17_1700 {
    width: 70.8333333333%;
  }
  .col18_1700 {
    width: 75%;
  }
  .col19_1700 {
    width: 79.1666666667%;
  }
  .col20_1700 {
    width: 83.3333333333%;
  }
  .col21_1700 {
    width: 87.5%;
  }
  .col22_1700 {
    width: 91.6666666667%;
  }
  .col23_1700 {
    width: 95.8333333333%;
  }
  .col24_1700 {
    width: 100%;
  }
  .col1_1700, .col2_1700, .col3_1700, .col4_1700, .col5_1700, .col6_1700,
  .col7_1700, .col8_1700, .col9_1700, .col10_1700, .col11_1700, .col12_1700,
  .col13_1700, .col14_1700, .col15_1700, .col16_1700, .col17_1700, .col18_1700,
  .col19_1700, .col20_1700, .col21_1700, .col22_1700, .col23_1700, .col24_1700 {
    display: block;
    float: left;
    min-height: 1px;
    padding: 0 1%;
  }
}
@media screen and (max-width: 1600px) {
  .col1_1600 {
    width: 4.1666666667%;
  }
  .col2_1600 {
    width: 8.3333333333%;
  }
  .col3_1600 {
    width: 12.5%;
  }
  .col4_1600 {
    width: 16.6666666667%;
  }
  .col5_1600 {
    width: 20.8333333333%;
  }
  .col6_1600 {
    width: 25%;
  }
  .col7_1600 {
    width: 29.1666666667%;
  }
  .col8_1600 {
    width: 33.3333333333%;
  }
  .col9_1600 {
    width: 37.5%;
  }
  .col10_1600 {
    width: 41.6666666667%;
  }
  .col11_1600 {
    width: 45.8333333333%;
  }
  .col12_1600 {
    width: 50%;
  }
  .col13_1600 {
    width: 54.1666666667%;
  }
  .col14_1600 {
    width: 58.3333333333%;
  }
  .col15_1600 {
    width: 62.5%;
  }
  .col16_1600 {
    width: 66.6666666667%;
  }
  .col17_1600 {
    width: 70.8333333333%;
  }
  .col18_1600 {
    width: 75%;
  }
  .col19_1600 {
    width: 79.1666666667%;
  }
  .col20_1600 {
    width: 83.3333333333%;
  }
  .col21_1600 {
    width: 87.5%;
  }
  .col22_1600 {
    width: 91.6666666667%;
  }
  .col23_1600 {
    width: 95.8333333333%;
  }
  .col24_1600 {
    width: 100%;
  }
  .col1_1600, .col2_1600, .col3_1600, .col4_1600, .col5_1600, .col6_1600,
  .col7_1600, .col8_1600, .col9_1600, .col10_1600, .col11_1600, .col12_1600,
  .col13_1600, .col14_1600, .col15_1600, .col16_1600, .col17_1600, .col18_1600,
  .col19_1600, .col20_1600, .col21_1600, .col22_1600, .col23_1600, .col24_1600 {
    display: block;
    float: left;
    min-height: 1px;
    padding: 0 1%;
  }
}
@media screen and (max-width: 1500px) {
  .col1_1500 {
    width: 4.1666666667%;
  }
  .col2_1500 {
    width: 8.3333333333%;
  }
  .col3_1500 {
    width: 12.5%;
  }
  .col4_1500 {
    width: 16.6666666667%;
  }
  .col5_1500 {
    width: 20.8333333333%;
  }
  .col6_1500 {
    width: 25%;
  }
  .col7_1500 {
    width: 29.1666666667%;
  }
  .col8_1500 {
    width: 33.3333333333%;
  }
  .col9_1500 {
    width: 37.5%;
  }
  .col10_1500 {
    width: 41.6666666667%;
  }
  .col11_1500 {
    width: 45.8333333333%;
  }
  .col12_1500 {
    width: 50%;
  }
  .col13_1500 {
    width: 54.1666666667%;
  }
  .col14_1500 {
    width: 58.3333333333%;
  }
  .col15_1500 {
    width: 62.5%;
  }
  .col16_1500 {
    width: 66.6666666667%;
  }
  .col17_1500 {
    width: 70.8333333333%;
  }
  .col18_1500 {
    width: 75%;
  }
  .col19_1500 {
    width: 79.1666666667%;
  }
  .col20_1500 {
    width: 83.3333333333%;
  }
  .col21_1500 {
    width: 87.5%;
  }
  .col22_1500 {
    width: 91.6666666667%;
  }
  .col23_1500 {
    width: 95.8333333333%;
  }
  .col24_1500 {
    width: 100%;
  }
  .col1_1500, .col2_1500, .col3_1500, .col4_1500, .col5_1500, .col6_1500,
  .col7_1500, .col8_1500, .col9_1500, .col10_1500, .col11_1500, .col12_1500,
  .col13_1500, .col14_1500, .col15_1500, .col16_1500, .col17_1500, .col18_1500,
  .col19_1500, .col20_1500, .col21_1500, .col22_1500, .col23_1500, .col24_1500 {
    display: block;
    float: left;
    min-height: 1px;
    padding: 0 1%;
  }
}
@media screen and (max-width: 1400px) {
  .col1_1400 {
    width: 4.1666666667%;
  }
  .col2_1400 {
    width: 8.3333333333%;
  }
  .col3_1400 {
    width: 12.5%;
  }
  .col4_1400 {
    width: 16.6666666667%;
  }
  .col5_1400 {
    width: 20.8333333333%;
  }
  .col6_1400 {
    width: 25%;
  }
  .col7_1400 {
    width: 29.1666666667%;
  }
  .col8_1400 {
    width: 33.3333333333%;
  }
  .col9_1400 {
    width: 37.5%;
  }
  .col10_1400 {
    width: 41.6666666667%;
  }
  .col11_1400 {
    width: 45.8333333333%;
  }
  .col12_1400 {
    width: 50%;
  }
  .col13_1400 {
    width: 54.1666666667%;
  }
  .col14_1400 {
    width: 58.3333333333%;
  }
  .col15_1400 {
    width: 62.5%;
  }
  .col16_1400 {
    width: 66.6666666667%;
  }
  .col17_1400 {
    width: 70.8333333333%;
  }
  .col18_1400 {
    width: 75%;
  }
  .col19_1400 {
    width: 79.1666666667%;
  }
  .col20_1400 {
    width: 83.3333333333%;
  }
  .col21_1400 {
    width: 87.5%;
  }
  .col22_1400 {
    width: 91.6666666667%;
  }
  .col23_1400 {
    width: 95.8333333333%;
  }
  .col24_1400 {
    width: 100%;
  }
  .col1_1400, .col2_1400, .col3_1400, .col4_1400, .col5_1400, .col6_1400,
  .col7_1400, .col8_1400, .col9_1400, .col10_1400, .col11_1400, .col12_1400,
  .col13_1400, .col14_1400, .col15_1400, .col16_1400, .col17_1400, .col18_1400,
  .col19_1400, .col20_1400, .col21_1400, .col22_1400, .col23_1400, .col24_1400 {
    display: block;
    float: left;
    min-height: 1px;
    padding: 0 1%;
  }
}
@media screen and (max-width: 1300px) {
  .col1_1300 {
    width: 4.1666666667%;
  }
  .col2_1300 {
    width: 8.3333333333%;
  }
  .col3_1300 {
    width: 12.5%;
  }
  .col4_1300 {
    width: 16.6666666667%;
  }
  .col5_1300 {
    width: 20.8333333333%;
  }
  .col6_1300 {
    width: 25%;
  }
  .col7_1300 {
    width: 29.1666666667%;
  }
  .col8_1300 {
    width: 33.3333333333%;
  }
  .col9_1300 {
    width: 37.5%;
  }
  .col10_1300 {
    width: 41.6666666667%;
  }
  .col11_1300 {
    width: 45.8333333333%;
  }
  .col12_1300 {
    width: 50%;
  }
  .col13_1300 {
    width: 54.1666666667%;
  }
  .col14_1300 {
    width: 58.3333333333%;
  }
  .col15_1300 {
    width: 62.5%;
  }
  .col16_1300 {
    width: 66.6666666667%;
  }
  .col17_1300 {
    width: 70.8333333333%;
  }
  .col18_1300 {
    width: 75%;
  }
  .col19_1300 {
    width: 79.1666666667%;
  }
  .col20_1300 {
    width: 83.3333333333%;
  }
  .col21_1300 {
    width: 87.5%;
  }
  .col22_1300 {
    width: 91.6666666667%;
  }
  .col23_1300 {
    width: 95.8333333333%;
  }
  .col24_1300 {
    width: 100%;
  }
  .col1_1300, .col2_1300, .col3_1300, .col4_1300, .col5_1300, .col6_1300,
  .col7_1300, .col8_1300, .col9_1300, .col10_1300, .col11_1300, .col12_1300,
  .col13_1300, .col14_1300, .col15_1300, .col16_1300, .col17_1300, .col18_1300,
  .col19_1300, .col20_1300, .col21_1300, .col22_1300, .col23_1300, .col24_1300 {
    display: block;
    float: left;
    min-height: 1px;
    padding: 0 1%;
  }
}
@media screen and (max-width: 1200px) {
  .col1_1200 {
    width: 4.1666666667%;
  }
  .col2_1200 {
    width: 8.3333333333%;
  }
  .col3_1200 {
    width: 12.5%;
  }
  .col4_1200 {
    width: 16.6666666667%;
  }
  .col5_1200 {
    width: 20.8333333333%;
  }
  .col6_1200 {
    width: 25%;
  }
  .col7_1200 {
    width: 29.1666666667%;
  }
  .col8_1200 {
    width: 33.3333333333%;
  }
  .col9_1200 {
    width: 37.5%;
  }
  .col10_1200 {
    width: 41.6666666667%;
  }
  .col11_1200 {
    width: 45.8333333333%;
  }
  .col12_1200 {
    width: 50%;
  }
  .col13_1200 {
    width: 54.1666666667%;
  }
  .col14_1200 {
    width: 58.3333333333%;
  }
  .col15_1200 {
    width: 62.5%;
  }
  .col16_1200 {
    width: 66.6666666667%;
  }
  .col17_1200 {
    width: 70.8333333333%;
  }
  .col18_1200 {
    width: 75%;
  }
  .col19_1200 {
    width: 79.1666666667%;
  }
  .col20_1200 {
    width: 83.3333333333%;
  }
  .col21_1200 {
    width: 87.5%;
  }
  .col22_1200 {
    width: 91.6666666667%;
  }
  .col23_1200 {
    width: 95.8333333333%;
  }
  .col24_1200 {
    width: 100%;
  }
  .col1_1200, .col2_1200, .col3_1200, .col4_1200, .col5_1200, .col6_1200,
  .col7_1200, .col8_1200, .col9_1200, .col10_1200, .col11_1200, .col12_1200,
  .col13_1200, .col14_1200, .col15_1200, .col16_1200, .col17_1200, .col18_1200,
  .col19_1200, .col20_1200, .col21_1200, .col22_1200, .col23_1200, .col24_1200 {
    display: block;
    float: left;
    min-height: 1px;
    padding: 0 1%;
  }
}
@media screen and (max-width: 1100px) {
  .col1_1100 {
    width: 4.1666666667%;
  }
  .col2_1100 {
    width: 8.3333333333%;
  }
  .col3_1100 {
    width: 12.5%;
  }
  .col4_1100 {
    width: 16.6666666667%;
  }
  .col5_1100 {
    width: 20.8333333333%;
  }
  .col6_1100 {
    width: 25%;
  }
  .col7_1100 {
    width: 29.1666666667%;
  }
  .col8_1100 {
    width: 33.3333333333%;
  }
  .col9_1100 {
    width: 37.5%;
  }
  .col10_1100 {
    width: 41.6666666667%;
  }
  .col11_1100 {
    width: 45.8333333333%;
  }
  .col12_1100 {
    width: 50%;
  }
  .col13_1100 {
    width: 54.1666666667%;
  }
  .col14_1100 {
    width: 58.3333333333%;
  }
  .col15_1100 {
    width: 62.5%;
  }
  .col16_1100 {
    width: 66.6666666667%;
  }
  .col17_1100 {
    width: 70.8333333333%;
  }
  .col18_1100 {
    width: 75%;
  }
  .col19_1100 {
    width: 79.1666666667%;
  }
  .col20_1100 {
    width: 83.3333333333%;
  }
  .col21_1100 {
    width: 87.5%;
  }
  .col22_1100 {
    width: 91.6666666667%;
  }
  .col23_1100 {
    width: 95.8333333333%;
  }
  .col24_1100 {
    width: 100%;
  }
  .col1_1100, .col2_1100, .col3_1100, .col4_1100, .col5_1100, .col6_1100,
  .col7_1100, .col8_1100, .col9_1100, .col10_1100, .col11_1100, .col12_1100,
  .col13_1100, .col14_1100, .col15_1100, .col16_1100, .col17_1100, .col18_1100,
  .col19_1100, .col20_1100, .col21_1100, .col22_1100, .col23_1100, .col24_1100 {
    display: block;
    float: left;
    min-height: 1px;
    padding: 0 1%;
  }
}
@media screen and (max-width: 1024px) {
  .col1_1024 {
    width: 4.1666666667%;
  }
  .col2_1024 {
    width: 8.3333333333%;
  }
  .col3_1024 {
    width: 12.5%;
  }
  .col4_1024 {
    width: 16.6666666667%;
  }
  .col5_1024 {
    width: 20.8333333333%;
  }
  .col6_1024 {
    width: 25%;
  }
  .col7_1024 {
    width: 29.1666666667%;
  }
  .col8_1024 {
    width: 33.3333333333%;
  }
  .col9_1024 {
    width: 37.5%;
  }
  .col10_1024 {
    width: 41.6666666667%;
  }
  .col11_1024 {
    width: 45.8333333333%;
  }
  .col12_1024 {
    width: 50%;
  }
  .col13_1024 {
    width: 54.1666666667%;
  }
  .col14_1024 {
    width: 58.3333333333%;
  }
  .col15_1024 {
    width: 62.5%;
  }
  .col16_1024 {
    width: 66.6666666667%;
  }
  .col17_1024 {
    width: 70.8333333333%;
  }
  .col18_1024 {
    width: 75%;
  }
  .col19_1024 {
    width: 79.1666666667%;
  }
  .col20_1024 {
    width: 83.3333333333%;
  }
  .col21_1024 {
    width: 87.5%;
  }
  .col22_1024 {
    width: 91.6666666667%;
  }
  .col23_1024 {
    width: 95.8333333333%;
  }
  .col24_1024 {
    width: 100%;
  }
  .col1_1024, .col2_1024, .col3_1024, .col4_1024, .col5_1024, .col6_1024,
  .col7_1024, .col8_1024, .col9_1024, .col10_1024, .col11_1024, .col12_1024,
  .col13_1024, .col14_1024, .col15_1024, .col16_1024, .col17_1024, .col18_1024,
  .col19_1024, .col20_1024, .col21_1024, .col22_1024, .col23_1024, .col24_1024 {
    display: block;
    float: left;
    min-height: 1px;
    padding: 0 1%;
  }
}
@media screen and (max-width: 1000px) {
  .col1_1000 {
    width: 4.1666666667%;
  }
  .col2_1000 {
    width: 8.3333333333%;
  }
  .col3_1000 {
    width: 12.5%;
  }
  .col4_1000 {
    width: 16.6666666667%;
  }
  .col5_1000 {
    width: 20.8333333333%;
  }
  .col6_1000 {
    width: 25%;
  }
  .col7_1000 {
    width: 29.1666666667%;
  }
  .col8_1000 {
    width: 33.3333333333%;
  }
  .col9_1000 {
    width: 37.5%;
  }
  .col10_1000 {
    width: 41.6666666667%;
  }
  .col11_1000 {
    width: 45.8333333333%;
  }
  .col12_1000 {
    width: 50%;
  }
  .col13_1000 {
    width: 54.1666666667%;
  }
  .col14_1000 {
    width: 58.3333333333%;
  }
  .col15_1000 {
    width: 62.5%;
  }
  .col16_1000 {
    width: 66.6666666667%;
  }
  .col17_1000 {
    width: 70.8333333333%;
  }
  .col18_1000 {
    width: 75%;
  }
  .col19_1000 {
    width: 79.1666666667%;
  }
  .col20_1000 {
    width: 83.3333333333%;
  }
  .col21_1000 {
    width: 87.5%;
  }
  .col22_1000 {
    width: 91.6666666667%;
  }
  .col23_1000 {
    width: 95.8333333333%;
  }
  .col24_1000 {
    width: 100%;
  }
  .col1_1000, .col2_1000, .col3_1000, .col4_1000, .col5_1000, .col6_1000,
  .col7_1000, .col8_1000, .col9_1000, .col10_1000, .col11_1000, .col12_1000,
  .col13_1000, .col14_1000, .col15_1000, .col16_1000, .col17_1000, .col18_1000,
  .col19_1000, .col20_1000, .col21_1000, .col22_1000, .col23_1000, .col24_1000 {
    display: block;
    float: left;
    min-height: 1px;
    padding: 0 1%;
  }
}
@media screen and (max-width: 900px) {
  .col1_900 {
    width: 4.1666666667%;
  }
  .col2_900 {
    width: 8.3333333333%;
  }
  .col3_900 {
    width: 12.5%;
  }
  .col4_900 {
    width: 16.6666666667%;
  }
  .col5_900 {
    width: 20.8333333333%;
  }
  .col6_900 {
    width: 25%;
  }
  .col7_900 {
    width: 29.1666666667%;
  }
  .col8_900 {
    width: 33.3333333333%;
  }
  .col9_900 {
    width: 37.5%;
  }
  .col10_900 {
    width: 41.6666666667%;
  }
  .col11_900 {
    width: 45.8333333333%;
  }
  .col12_900 {
    width: 50%;
  }
  .col13_900 {
    width: 54.1666666667%;
  }
  .col14_900 {
    width: 58.3333333333%;
  }
  .col15_900 {
    width: 62.5%;
  }
  .col16_900 {
    width: 66.6666666667%;
  }
  .col17_900 {
    width: 70.8333333333%;
  }
  .col18_900 {
    width: 75%;
  }
  .col19_900 {
    width: 79.1666666667%;
  }
  .col20_900 {
    width: 83.3333333333%;
  }
  .col21_900 {
    width: 87.5%;
  }
  .col22_900 {
    width: 91.6666666667%;
  }
  .col23_900 {
    width: 95.8333333333%;
  }
  .col24_900 {
    width: 100%;
  }
  .col1_900, .col2_900, .col3_900, .col4_900, .col5_900, .col6_900,
  .col7_900, .col8_900, .col9_900, .col10_900, .col11_900, .col12_900,
  .col13_900, .col14_900, .col15_900, .col16_900, .col17_900, .col18_900,
  .col19_900, .col20_900, .col21_900, .col22_900, .col23_900, .col24_900 {
    display: block;
    float: left;
    min-height: 1px;
    padding: 0 1%;
  }
}
@media screen and (max-width: 800px) {
  .col1_800 {
    width: 4.1666666667%;
  }
  .col2_800 {
    width: 8.3333333333%;
  }
  .col3_800 {
    width: 12.5%;
  }
  .col4_800 {
    width: 16.6666666667%;
  }
  .col5_800 {
    width: 20.8333333333%;
  }
  .col6_800 {
    width: 25%;
  }
  .col7_800 {
    width: 29.1666666667%;
  }
  .col8_800 {
    width: 33.3333333333%;
  }
  .col9_800 {
    width: 37.5%;
  }
  .col10_800 {
    width: 41.6666666667%;
  }
  .col11_800 {
    width: 45.8333333333%;
  }
  .col12_800 {
    width: 50%;
  }
  .col13_800 {
    width: 54.1666666667%;
  }
  .col14_800 {
    width: 58.3333333333%;
  }
  .col15_800 {
    width: 62.5%;
  }
  .col16_800 {
    width: 66.6666666667%;
  }
  .col17_800 {
    width: 70.8333333333%;
  }
  .col18_800 {
    width: 75%;
  }
  .col19_800 {
    width: 79.1666666667%;
  }
  .col20_800 {
    width: 83.3333333333%;
  }
  .col21_800 {
    width: 87.5%;
  }
  .col22_800 {
    width: 91.6666666667%;
  }
  .col23_800 {
    width: 95.8333333333%;
  }
  .col24_800 {
    width: 100%;
  }
  .col1_800, .col2_800, .col3_800, .col4_800, .col5_800, .col6_800,
  .col7_800, .col8_800, .col9_800, .col10_800, .col11_800, .col12_800,
  .col13_800, .col14_800, .col15_800, .col16_800, .col17_800, .col18_800,
  .col19_800, .col20_800, .col21_800, .col22_800, .col23_800, .col24_800 {
    display: block;
    float: left;
    min-height: 1px;
    padding: 0 1%;
  }
}
@media screen and (max-width: 768px) {
  .col1_768 {
    width: 4.1666666667%;
  }
  .col2_768 {
    width: 8.3333333333%;
  }
  .col3_768 {
    width: 12.5%;
  }
  .col4_768 {
    width: 16.6666666667%;
  }
  .col5_768 {
    width: 20.8333333333%;
  }
  .col6_768 {
    width: 25%;
  }
  .col7_768 {
    width: 29.1666666667%;
  }
  .col8_768 {
    width: 33.3333333333%;
  }
  .col9_768 {
    width: 37.5%;
  }
  .col10_768 {
    width: 41.6666666667%;
  }
  .col11_768 {
    width: 45.8333333333%;
  }
  .col12_768 {
    width: 50%;
  }
  .col13_768 {
    width: 54.1666666667%;
  }
  .col14_768 {
    width: 58.3333333333%;
  }
  .col15_768 {
    width: 62.5%;
  }
  .col16_768 {
    width: 66.6666666667%;
  }
  .col17_768 {
    width: 70.8333333333%;
  }
  .col18_768 {
    width: 75%;
  }
  .col19_768 {
    width: 79.1666666667%;
  }
  .col20_768 {
    width: 83.3333333333%;
  }
  .col21_768 {
    width: 87.5%;
  }
  .col22_768 {
    width: 91.6666666667%;
  }
  .col23_768 {
    width: 95.8333333333%;
  }
  .col24_768 {
    width: 100%;
  }
  .col1_768, .col2_768, .col3_768, .col4_768, .col5_768, .col6_768,
  .col7_768, .col8_768, .col9_768, .col10_768, .col11_768, .col12_768,
  .col13_768, .col14_768, .col15_768, .col16_768, .col17_768, .col18_768,
  .col19_768, .col20_768, .col21_768, .col22_768, .col23_768, .col24_768 {
    display: block;
    float: left;
    min-height: 1px;
    padding: 0 1%;
  }
}
@media screen and (max-width: 700px) {
  .col1_700 {
    width: 4.1666666667%;
  }
  .col2_700 {
    width: 8.3333333333%;
  }
  .col3_700 {
    width: 12.5%;
  }
  .col4_700 {
    width: 16.6666666667%;
  }
  .col5_700 {
    width: 20.8333333333%;
  }
  .col6_700 {
    width: 25%;
  }
  .col7_700 {
    width: 29.1666666667%;
  }
  .col8_700 {
    width: 33.3333333333%;
  }
  .col9_700 {
    width: 37.5%;
  }
  .col10_700 {
    width: 41.6666666667%;
  }
  .col11_700 {
    width: 45.8333333333%;
  }
  .col12_700 {
    width: 50%;
  }
  .col13_700 {
    width: 54.1666666667%;
  }
  .col14_700 {
    width: 58.3333333333%;
  }
  .col15_700 {
    width: 62.5%;
  }
  .col16_700 {
    width: 66.6666666667%;
  }
  .col17_700 {
    width: 70.8333333333%;
  }
  .col18_700 {
    width: 75%;
  }
  .col19_700 {
    width: 79.1666666667%;
  }
  .col20_700 {
    width: 83.3333333333%;
  }
  .col21_700 {
    width: 87.5%;
  }
  .col22_700 {
    width: 91.6666666667%;
  }
  .col23_700 {
    width: 95.8333333333%;
  }
  .col24_700 {
    width: 100%;
  }
  .col1_700, .col2_700, .col3_700, .col4_700, .col5_700, .col6_700,
  .col7_700, .col8_700, .col9_700, .col10_700, .col11_700, .col12_700,
  .col13_700, .col14_700, .col15_700, .col16_700, .col17_700, .col18_700,
  .col19_700, .col20_700, .col21_700, .col22_700, .col23_700, .col24_700 {
    display: block;
    float: left;
    min-height: 1px;
    padding: 0 1%;
  }
}
@media screen and (max-width: 600px) {
  .col1_600 {
    width: 4.1666666667%;
  }
  .col2_600 {
    width: 8.3333333333%;
  }
  .col3_600 {
    width: 12.5%;
  }
  .col4_600 {
    width: 16.6666666667%;
  }
  .col5_600 {
    width: 20.8333333333%;
  }
  .col6_600 {
    width: 25%;
  }
  .col7_600 {
    width: 29.1666666667%;
  }
  .col8_600 {
    width: 33.3333333333%;
  }
  .col9_600 {
    width: 37.5%;
  }
  .col10_600 {
    width: 41.6666666667%;
  }
  .col11_600 {
    width: 45.8333333333%;
  }
  .col12_600 {
    width: 50%;
  }
  .col13_600 {
    width: 54.1666666667%;
  }
  .col14_600 {
    width: 58.3333333333%;
  }
  .col15_600 {
    width: 62.5%;
  }
  .col16_600 {
    width: 66.6666666667%;
  }
  .col17_600 {
    width: 70.8333333333%;
  }
  .col18_600 {
    width: 75%;
  }
  .col19_600 {
    width: 79.1666666667%;
  }
  .col20_600 {
    width: 83.3333333333%;
  }
  .col21_600 {
    width: 87.5%;
  }
  .col22_600 {
    width: 91.6666666667%;
  }
  .col23_600 {
    width: 95.8333333333%;
  }
  .col24_600 {
    width: 100%;
  }
  .col1_600, .col2_600, .col3_600, .col4_600, .col5_600, .col6_600,
  .col7_600, .col8_600, .col9_600, .col10_600, .col11_600, .col12_600,
  .col13_600, .col14_600, .col15_600, .col16_600, .col17_600, .col18_600,
  .col19_600, .col20_600, .col21_600, .col22_600, .col23_600, .col24_600 {
    display: block;
    float: left;
    min-height: 1px;
    padding: 0 1%;
  }
}
@media screen and (max-width: 500px) {
  .col1_500 {
    width: 4.1666666667%;
  }
  .col2_500 {
    width: 8.3333333333%;
  }
  .col3_500 {
    width: 12.5%;
  }
  .col4_500 {
    width: 16.6666666667%;
  }
  .col5_500 {
    width: 20.8333333333%;
  }
  .col6_500 {
    width: 25%;
  }
  .col7_500 {
    width: 29.1666666667%;
  }
  .col8_500 {
    width: 33.3333333333%;
  }
  .col9_500 {
    width: 37.5%;
  }
  .col10_500 {
    width: 41.6666666667%;
  }
  .col11_500 {
    width: 45.8333333333%;
  }
  .col12_500 {
    width: 50%;
  }
  .col13_500 {
    width: 54.1666666667%;
  }
  .col14_500 {
    width: 58.3333333333%;
  }
  .col15_500 {
    width: 62.5%;
  }
  .col16_500 {
    width: 66.6666666667%;
  }
  .col17_500 {
    width: 70.8333333333%;
  }
  .col18_500 {
    width: 75%;
  }
  .col19_500 {
    width: 79.1666666667%;
  }
  .col20_500 {
    width: 83.3333333333%;
  }
  .col21_500 {
    width: 87.5%;
  }
  .col22_500 {
    width: 91.6666666667%;
  }
  .col23_500 {
    width: 95.8333333333%;
  }
  .col24_500 {
    width: 100%;
  }
  .col1_500, .col2_500, .col3_500, .col4_500, .col5_500, .col6_500,
  .col7_500, .col8_500, .col9_500, .col10_500, .col11_500, .col12_500,
  .col13_500, .col14_500, .col15_500, .col16_500, .col17_500, .col18_500,
  .col19_500, .col20_500, .col21_500, .col22_500, .col23_500, .col24_500 {
    display: block;
    float: left;
    min-height: 1px;
    padding: 0 1%;
  }
}
@media screen and (max-width: 400px) {
  .col1_400 {
    width: 4.1666666667%;
  }
  .col2_400 {
    width: 8.3333333333%;
  }
  .col3_400 {
    width: 12.5%;
  }
  .col4_400 {
    width: 16.6666666667%;
  }
  .col5_400 {
    width: 20.8333333333%;
  }
  .col6_400 {
    width: 25%;
  }
  .col7_400 {
    width: 29.1666666667%;
  }
  .col8_400 {
    width: 33.3333333333%;
  }
  .col9_400 {
    width: 37.5%;
  }
  .col10_400 {
    width: 41.6666666667%;
  }
  .col11_400 {
    width: 45.8333333333%;
  }
  .col12_400 {
    width: 50%;
  }
  .col13_400 {
    width: 54.1666666667%;
  }
  .col14_400 {
    width: 58.3333333333%;
  }
  .col15_400 {
    width: 62.5%;
  }
  .col16_400 {
    width: 66.6666666667%;
  }
  .col17_400 {
    width: 70.8333333333%;
  }
  .col18_400 {
    width: 75%;
  }
  .col19_400 {
    width: 79.1666666667%;
  }
  .col20_400 {
    width: 83.3333333333%;
  }
  .col21_400 {
    width: 87.5%;
  }
  .col22_400 {
    width: 91.6666666667%;
  }
  .col23_400 {
    width: 95.8333333333%;
  }
  .col24_400 {
    width: 100%;
  }
  .col1_400, .col2_400, .col3_400, .col4_400, .col5_400, .col6_400,
  .col7_400, .col8_400, .col9_400, .col10_400, .col11_400, .col12_400,
  .col13_400, .col14_400, .col15_400, .col16_400, .col17_400, .col18_400,
  .col19_400, .col20_400, .col21_400, .col22_400, .col23_400, .col24_400 {
    display: block;
    float: left;
    min-height: 1px;
    padding: 0 1%;
  }
}
@media screen and (max-width: 300px) {
  .col1_300 {
    width: 4.1666666667%;
  }
  .col2_300 {
    width: 8.3333333333%;
  }
  .col3_300 {
    width: 12.5%;
  }
  .col4_300 {
    width: 16.6666666667%;
  }
  .col5_300 {
    width: 20.8333333333%;
  }
  .col6_300 {
    width: 25%;
  }
  .col7_300 {
    width: 29.1666666667%;
  }
  .col8_300 {
    width: 33.3333333333%;
  }
  .col9_300 {
    width: 37.5%;
  }
  .col10_300 {
    width: 41.6666666667%;
  }
  .col11_300 {
    width: 45.8333333333%;
  }
  .col12_300 {
    width: 50%;
  }
  .col13_300 {
    width: 54.1666666667%;
  }
  .col14_300 {
    width: 58.3333333333%;
  }
  .col15_300 {
    width: 62.5%;
  }
  .col16_300 {
    width: 66.6666666667%;
  }
  .col17_300 {
    width: 70.8333333333%;
  }
  .col18_300 {
    width: 75%;
  }
  .col19_300 {
    width: 79.1666666667%;
  }
  .col20_300 {
    width: 83.3333333333%;
  }
  .col21_300 {
    width: 87.5%;
  }
  .col22_300 {
    width: 91.6666666667%;
  }
  .col23_300 {
    width: 95.8333333333%;
  }
  .col24_300 {
    width: 100%;
  }
  .col1_300, .col2_300, .col3_300, .col4_300, .col5_300, .col6_300,
  .col7_300, .col8_300, .col9_300, .col10_300, .col11_300, .col12_300,
  .col13_300, .col14_300, .col15_300, .col16_300, .col17_300, .col18_300,
  .col19_300, .col20_300, .col21_300, .col22_300, .col23_300, .col24_300 {
    display: block;
    float: left;
    min-height: 1px;
    padding: 0 1%;
  }
}
@font-face {
  font-family: "Financier_Display_Semibold";
  src: url("../fonts/financier/Financier_Display_Semibold.otf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "hinted-Geomanist-Regular-italic";
  src: url("../fonts/geomanist/hinted-Geomanist-Regular-Italic.otf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "hinted-Geomanist-Regular";
  src: url("../fonts/geomanist/hinted-Geomanist-Regular.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "hinted-Geomanist-Book";
  src: url("../fonts/geomanist/hinted-Geomanist-Book.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "hinted-Geomanist-Medium";
  src: url("../fonts/geomanist/hinted-Geomanist-Medium.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
.overlay, .dropDownOverlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0);
  visibility: hidden;
  z-index: 9;
  transition: background 500ms ease, visibility 0ms ease 300ms, z-index 0ms ease 700ms;
}

.iconPlay {
  position: absolute;
  width: 50px;
  height: 50px;
  border: solid 2px rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  top: calc(50% - 25px);
  left: calc(50% - 25px);
  z-index: 1;
  transition: border-color 300ms;
}
.iconPlay:after {
  content: "";
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 5px);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 0 10px 15px;
  border-color: transparent transparent transparent rgba(255, 255, 255, 0.5);
  transition: border-color 300ms;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.table {
  display: table;
}

.tableCell {
  display: table-cell;
}

.flex {
  display: flex;
  -webkit-box-orient: vertical;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.fixed {
  position: fixed;
}

.sticky {
  position: sticky;
  top: 50px;
}

.floatLeft {
  float: left;
}

.floatRight {
  float: right;
}

.textLeft {
  text-align: left;
}

.textCenter {
  text-align: center;
}

.textRight {
  text-align: right;
}

.alignTop {
  vertical-align: top;
}

.alignMiddle {
  vertical-align: middle;
}

.alignBottom {
  vertical-align: bottom;
}

.caps {
  text-transform: capitalize;
}

.allCaps {
  text-transform: uppercase;
}

.bgLeft {
  background-position: left center;
}

.bgCenter {
  background-position: center;
}

.bgRight {
  background-position: right center;
}

.wrapper1600 {
  max-width: 1600px;
  margin: 0 auto;
}
@media screen and (max-width: 1600px) {
  .wrapper1600 {
    margin: 0;
  }
}

.wrapper1500 {
  max-width: 1500px;
  margin: 0 auto;
}
@media screen and (max-width: 1500px) {
  .wrapper1500 {
    margin: 0;
  }
}

.wrapper1400 {
  max-width: 1400px;
  margin: 0 auto;
}
@media screen and (max-width: 1400px) {
  .wrapper1400 {
    margin: 0;
  }
}

.wrapper1300 {
  max-width: 1300px;
  margin: 0 auto;
}
@media screen and (max-width: 1300px) {
  .wrapper1300 {
    margin: 0;
  }
}

.wrapper1200 {
  max-width: 1200px;
  margin: 0 auto;
}
@media screen and (max-width: 1200px) {
  .wrapper1200 {
    margin: 0;
  }
}

.wrapper1100 {
  max-width: 1100px;
  margin: 0 auto;
}
@media screen and (max-width: 1100px) {
  .wrapper1100 {
    margin: 0;
  }
}

.wrapper1024 {
  max-width: 1024px;
  margin: 0 auto;
}
@media screen and (max-width: 1024px) {
  .wrapper1024 {
    margin: 0;
  }
}

.wrapper900 {
  max-width: 900px;
  margin: 0 auto;
}
@media screen and (max-width: 900px) {
  .wrapper900 {
    margin: 0;
  }
}

.yPadding250 {
  padding-top: 250px;
  padding-bottom: 250px;
}
@media screen and (max-width: 1600px) {
  .yPadding250 {
    padding-top: 200px;
    padding-bottom: 200px;
  }
}
@media screen and (max-width: 1400px) {
  .yPadding250 {
    padding-top: 150px;
    padding-bottom: 150px;
  }
}
@media screen and (max-width: 1200px) {
  .yPadding250 {
    padding-top: 125px;
    padding-bottom: 125px;
  }
}
@media screen and (max-width: 1024px) {
  .yPadding250 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media screen and (max-width: 768px) {
  .yPadding250 {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}

.yTopPadding250 {
  padding-top: 250px;
}
@media screen and (max-width: 1600px) {
  .yTopPadding250 {
    padding-top: 200px;
  }
}
@media screen and (max-width: 1400px) {
  .yTopPadding250 {
    padding-top: 150px;
  }
}
@media screen and (max-width: 1200px) {
  .yTopPadding250 {
    padding-top: 125px;
  }
}
@media screen and (max-width: 1024px) {
  .yTopPadding250 {
    padding-top: 80px;
  }
}
@media screen and (max-width: 768px) {
  .yTopPadding250 {
    padding-top: 70px;
  }
}

.yBottomPadding250 {
  padding-bottom: 250px;
}
@media screen and (max-width: 1600px) {
  .yBottomPadding250 {
    padding-bottom: 200px;
  }
}
@media screen and (max-width: 1400px) {
  .yBottomPadding250 {
    padding-bottom: 150px;
  }
}
@media screen and (max-width: 1200px) {
  .yBottomPadding250 {
    padding-bottom: 125px;
  }
}
@media screen and (max-width: 1024px) {
  .yBottomPadding250 {
    padding-bottom: 80px;
  }
}
@media screen and (max-width: 768px) {
  .yBottomPadding250 {
    padding-bottom: 70px;
  }
}

.yPadding200 {
  padding-top: 200px;
  padding-bottom: 200px;
}
@media screen and (max-width: 1600px) {
  .yPadding200 {
    padding-top: 175px;
    padding-bottom: 175px;
  }
}
@media screen and (max-width: 1400px) {
  .yPadding200 {
    padding-top: 150px;
    padding-bottom: 150px;
  }
}
@media screen and (max-width: 1200px) {
  .yPadding200 {
    padding-top: 125px;
    padding-bottom: 125px;
  }
}
@media screen and (max-width: 1024px) {
  .yPadding200 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media screen and (max-width: 768px) {
  .yPadding200 {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}

.topPadding200 {
  padding-top: 200px;
}
@media screen and (max-width: 1600px) {
  .topPadding200 {
    padding-top: 175px;
  }
}
@media screen and (max-width: 1400px) {
  .topPadding200 {
    padding-top: 150px;
  }
}
@media screen and (max-width: 1200px) {
  .topPadding200 {
    padding-top: 125px;
  }
}
@media screen and (max-width: 1024px) {
  .topPadding200 {
    padding-top: 80px;
  }
}
@media screen and (max-width: 768px) {
  .topPadding200 {
    padding-top: 70px;
  }
}

.bottomPadding200 {
  padding-bottom: 200px;
}
@media screen and (max-width: 1600px) {
  .bottomPadding200 {
    padding-bottom: 175px;
  }
}
@media screen and (max-width: 1400px) {
  .bottomPadding200 {
    padding-bottom: 150px;
  }
}
@media screen and (max-width: 1200px) {
  .bottomPadding200 {
    padding-bottom: 125px;
  }
}
@media screen and (max-width: 1024px) {
  .bottomPadding200 {
    padding-bottom: 80px;
  }
}
@media screen and (max-width: 768px) {
  .bottomPadding200 {
    padding-bottom: 70px;
  }
}

.yPadding150 {
  padding-top: 150px;
  padding-bottom: 150px;
}
@media screen and (max-width: 1600px) {
  .yPadding150 {
    padding-top: 125px;
    padding-bottom: 125px;
  }
}
@media screen and (max-width: 1400px) {
  .yPadding150 {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
@media screen and (max-width: 1200px) {
  .yPadding150 {
    padding-top: 90px;
    padding-bottom: 90px;
  }
}
@media screen and (max-width: 1024px) {
  .yPadding150 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media screen and (max-width: 768px) {
  .yPadding150 {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}

.topPadding150 {
  padding-top: 150px;
}
@media screen and (max-width: 1600px) {
  .topPadding150 {
    padding-top: 125px;
  }
}
@media screen and (max-width: 1400px) {
  .topPadding150 {
    padding-top: 100px;
  }
}
@media screen and (max-width: 1200px) {
  .topPadding150 {
    padding-top: 90px;
  }
}
@media screen and (max-width: 1024px) {
  .topPadding150 {
    padding-top: 80px;
  }
}
@media screen and (max-width: 768px) {
  .topPadding150 {
    padding-top: 70px;
  }
}

.bottomPadding150 {
  padding-bottom: 150px;
}
@media screen and (max-width: 1600px) {
  .bottomPadding150 {
    padding-bottom: 125px;
  }
}
@media screen and (max-width: 1400px) {
  .bottomPadding150 {
    padding-bottom: 100px;
  }
}
@media screen and (max-width: 1200px) {
  .bottomPadding150 {
    padding-bottom: 90px;
  }
}
@media screen and (max-width: 1024px) {
  .bottomPadding150 {
    padding-bottom: 80px;
  }
}
@media screen and (max-width: 768px) {
  .bottomPadding150 {
    padding-bottom: 70px;
  }
}

.yPadding110 {
  padding-top: 110px;
  padding-bottom: 110px;
}
@media screen and (max-width: 1400px) {
  .yPadding110 {
    padding-top: 90px;
    padding-bottom: 90px;
  }
}
@media screen and (max-width: 1024px) {
  .yPadding110 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media screen and (max-width: 768px) {
  .yPadding110 {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}

.topPadding110 {
  padding-top: 110px;
}
@media screen and (max-width: 1400px) {
  .topPadding110 {
    padding-top: 90px;
  }
}
@media screen and (max-width: 1024px) {
  .topPadding110 {
    padding-top: 80px;
  }
}
@media screen and (max-width: 768px) {
  .topPadding110 {
    padding-top: 70px;
  }
}

.bottomPadding110 {
  padding-bottom: 110px;
}
@media screen and (max-width: 1400px) {
  .bottomPadding110 {
    padding-bottom: 90px;
  }
}
@media screen and (max-width: 1024px) {
  .bottomPadding110 {
    padding-bottom: 80px;
  }
}
@media screen and (max-width: 768px) {
  .bottomPadding110 {
    padding-bottom: 70px;
  }
}

.yPadding70 {
  padding-top: 70px;
  padding-bottom: 70px;
}

.topPadding70 {
  padding-top: 70px;
}

.bottomPadding70 {
  padding-bottom: 70px;
}

.yPadding50 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.yTopPadding50 {
  padding-top: 50px;
}

.yBottomPadding50 {
  padding-bottom: 50px;
}

.xPadding200 {
  padding-right: 200px;
  padding-left: 200px;
}
@media screen and (max-width: 1400px) {
  .xPadding200 {
    padding-right: 150px;
    padding-left: 150px;
  }
}
@media screen and (max-width: 1200px) {
  .xPadding200 {
    padding-right: 110px;
    padding-left: 110px;
  }
}
@media screen and (max-width: 1024px) {
  .xPadding200 {
    padding-right: 50px;
    padding-left: 50px;
  }
}
@media screen and (max-width: 768px) {
  .xPadding200 {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media screen and (max-width: 500px) {
  .xPadding200 {
    padding-right: 20px;
    padding-left: 20px;
  }
}

.rightPadding200 {
  padding-right: 200px;
}
@media screen and (max-width: 1400px) {
  .rightPadding200 {
    padding-right: 150px;
  }
}
@media screen and (max-width: 1200px) {
  .rightPadding200 {
    padding-right: 110px;
  }
}
@media screen and (max-width: 1024px) {
  .rightPadding200 {
    padding-right: 50px;
  }
}
@media screen and (max-width: 768px) {
  .rightPadding200 {
    padding-right: 30px;
  }
}
@media screen and (max-width: 500px) {
  .rightPadding200 {
    padding-right: 20px;
  }
}

.leftPadding200 {
  padding-left: 200px;
}
@media screen and (max-width: 1400px) {
  .leftPadding200 {
    padding-left: 150px;
  }
}
@media screen and (max-width: 1200px) {
  .leftPadding200 {
    padding-left: 110px;
  }
}
@media screen and (max-width: 1024px) {
  .leftPadding200 {
    padding-left: 50px;
  }
}
@media screen and (max-width: 768px) {
  .leftPadding200 {
    padding-left: 30px;
  }
}
@media screen and (max-width: 500px) {
  .leftPadding200 {
    padding-left: 20px;
  }
}

.xPadding120 {
  padding-right: 120px;
  padding-left: 120px;
}
@media screen and (max-width: 1200px) {
  .xPadding120 {
    padding-right: 90px;
    padding-left: 90px;
  }
}
@media screen and (max-width: 1024px) {
  .xPadding120 {
    padding-right: 50px;
    padding-left: 50px;
  }
}
@media screen and (max-width: 768px) {
  .xPadding120 {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media screen and (max-width: 500px) {
  .xPadding120 {
    padding-right: 20px;
    padding-left: 20px;
  }
}

.rightPadding120 {
  padding-right: 120px;
}
@media screen and (max-width: 1200px) {
  .rightPadding120 {
    padding-right: 90px;
  }
}
@media screen and (max-width: 1024px) {
  .rightPadding120 {
    padding-right: 50px;
  }
}
@media screen and (max-width: 768px) {
  .rightPadding120 {
    padding-right: 30px;
  }
}
@media screen and (max-width: 500px) {
  .rightPadding120 {
    padding-right: 20px;
  }
}

.leftPadding120 {
  padding-left: 120px;
}
@media screen and (max-width: 1200px) {
  .leftPadding120 {
    padding-left: 90px;
  }
}
@media screen and (max-width: 1024px) {
  .leftPadding120 {
    padding-left: 50px;
  }
}
@media screen and (max-width: 768px) {
  .leftPadding120 {
    padding-left: 30px;
  }
}
@media screen and (max-width: 500px) {
  .leftPadding120 {
    padding-left: 20px;
  }
}

.xPadding80 {
  padding-right: 80px;
  padding-left: 80px;
}
@media screen and (max-width: 1024px) {
  .xPadding80 {
    padding-right: 50px;
    padding-left: 50px;
  }
}
@media screen and (max-width: 768px) {
  .xPadding80 {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media screen and (max-width: 500px) {
  .xPadding80 {
    padding-right: 20px;
    padding-left: 20px;
  }
}

.rightPadding80 {
  padding-right: 80px;
}
@media screen and (max-width: 1024px) {
  .rightPadding80 {
    padding-right: 50px;
  }
}
@media screen and (max-width: 768px) {
  .rightPadding80 {
    padding-right: 30px;
  }
}
@media screen and (max-width: 500px) {
  .rightPadding80 {
    padding-right: 20px;
  }
}

.leftPadding80 {
  padding-left: 80px;
}
@media screen and (max-width: 1024px) {
  .leftPadding80 {
    padding-left: 50px;
  }
}
@media screen and (max-width: 768px) {
  .leftPadding80 {
    padding-left: 30px;
  }
}
@media screen and (max-width: 500px) {
  .leftPadding80 {
    padding-left: 20px;
  }
}

.xPadding12 {
  padding-right: 12%;
  padding-left: 12%;
}
@media screen and (max-width: 1200px) {
  .xPadding12 {
    padding-right: 10%;
    padding-left: 10%;
  }
}
@media screen and (max-width: 1024px) {
  .xPadding12 {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media screen and (max-width: 768px) {
  .xPadding12 {
    padding-right: 20px;
    padding-left: 20px;
  }
}

.xRightPadding12 {
  padding-right: 12%;
}
@media screen and (max-width: 1200px) {
  .xRightPadding12 {
    padding-right: 10%;
  }
}
@media screen and (max-width: 1024px) {
  .xRightPadding12 {
    padding-right: 30px;
  }
}
@media screen and (max-width: 768px) {
  .xRightPadding12 {
    padding-right: 20px;
  }
}

.xLeftPadding12 {
  padding-left: 12%;
}
@media screen and (max-width: 1200px) {
  .xLeftPadding12 {
    padding-left: 10%;
  }
}
@media screen and (max-width: 1024px) {
  .xLeftPadding12 {
    padding-left: 30px;
  }
}
@media screen and (max-width: 768px) {
  .xLeftPadding12 {
    padding-left: 20px;
  }
}

.xPadding8 {
  padding-right: 8%;
  padding-left: 8%;
}
@media screen and (max-width: 1200px) {
  .xPadding8 {
    padding-right: 6%;
    padding-left: 6%;
  }
}
@media screen and (max-width: 1024px) {
  .xPadding8 {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media screen and (max-width: 768px) {
  .xPadding8 {
    padding-right: 20px;
    padding-left: 20px;
  }
}

.xRightPadding8 {
  padding-right: 8%;
}
@media screen and (max-width: 1200px) {
  .xRightPadding8 {
    padding-right: 6%;
  }
}
@media screen and (max-width: 1024px) {
  .xRightPadding8 {
    padding-right: 30px;
  }
}
@media screen and (max-width: 768px) {
  .xRightPadding8 {
    padding-right: 20px;
  }
}

.xLeftPadding8 {
  padding-left: 8%;
}
@media screen and (max-width: 1200px) {
  .xLeftPadding8 {
    padding-left: 6%;
  }
}
@media screen and (max-width: 1024px) {
  .xLeftPadding8 {
    padding-left: 30px;
  }
}
@media screen and (max-width: 768px) {
  .xLeftPadding8 {
    padding-left: 20px;
  }
}

.xPadding6 {
  padding-right: 6%;
  padding-left: 6%;
}
@media screen and (max-width: 1200px) {
  .xPadding6 {
    padding-right: 5%;
    padding-left: 5%;
  }
}
@media screen and (max-width: 1024px) {
  .xPadding6 {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media screen and (max-width: 768px) {
  .xPadding6 {
    padding-right: 20px;
    padding-left: 20px;
  }
}

.xRightPadding6 {
  padding-right: 6%;
}
@media screen and (max-width: 1200px) {
  .xRightPadding6 {
    padding-right: 5%;
  }
}
@media screen and (max-width: 1024px) {
  .xRightPadding6 {
    padding-right: 30px;
  }
}
@media screen and (max-width: 768px) {
  .xRightPadding6 {
    padding-right: 20px;
  }
}

.xLeftPadding6 {
  padding-left: 6%;
}
@media screen and (max-width: 1200px) {
  .xLeftPadding6 {
    padding-left: 5%;
  }
}
@media screen and (max-width: 1024px) {
  .xLeftPadding6 {
    padding-left: 30px;
  }
}
@media screen and (max-width: 768px) {
  .xLeftPadding6 {
    padding-left: 20px;
  }
}

.xPadding5 {
  padding-right: 5%;
  padding-left: 5%;
}
@media screen and (max-width: 1024px) {
  .xPadding5 {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media screen and (max-width: 768px) {
  .xPadding5 {
    padding-right: 20px;
    padding-left: 20px;
  }
}

.xRightPadding5 {
  padding-right: 5%;
}
@media screen and (max-width: 1024px) {
  .xRightPadding5 {
    padding-right: 30px;
  }
}
@media screen and (max-width: 768px) {
  .xRightPadding5 {
    padding-right: 20px;
  }
}

.xLeftPadding5 {
  padding-left: 5%;
}
@media screen and (max-width: 1024px) {
  .xLeftPadding5 {
    padding-left: 30px;
  }
}
@media screen and (max-width: 768px) {
  .xLeftPadding5 {
    padding-left: 20px;
  }
}

.xPadding50 {
  padding-right: 50px;
  padding-left: 50px;
}
@media screen and (max-width: 1024px) {
  .xPadding50 {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media screen and (max-width: 768px) {
  .xPadding50 {
    padding-right: 20px;
    padding-left: 20px;
  }
}

.xRightPadding50 {
  padding-right: 50px;
}
@media screen and (max-width: 1024px) {
  .xRightPadding50 {
    padding-right: 30px;
  }
}
@media screen and (max-width: 768px) {
  .xRightPadding50 {
    padding-right: 20px;
  }
}

.xLeftPadding50 {
  padding-left: 50px;
}
@media screen and (max-width: 1024px) {
  .xLeftPadding50 {
    padding-left: 30px;
  }
}
@media screen and (max-width: 768px) {
  .xLeftPadding50 {
    padding-left: 20px;
  }
}

.noTopPadding {
  padding-top: 0;
}

.noRightPadding {
  padding-right: 0;
}

.noBottomPadding {
  padding-bottom: 0;
}

.noLeftPadding {
  padding-left: 0;
}

.noXPadding {
  padding-right: 0;
  padding-left: 0;
}

.noYPadding {
  padding-top: 0;
  padding-bottom: 0;
}

.noPadding {
  padding: 0;
}

.noTopMargin {
  margin-top: 0;
}

.noRightMargin {
  margin-right: 0;
}

.noBottomMargin {
  margin-bottom: 0;
}

.noLeftMargin {
  margin-left: 0;
}

.noXMargin {
  margin-right: 0;
  margin-left: 0;
}

.noYMargin {
  margin-top: 0;
  margin-bottom: 0;
}

.top0 {
  top: 0;
}

.right0 {
  right: 0;
}

.bottom0 {
  bottom: 0;
}

.left0 {
  left: 0;
}

.trbl0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.nowrap {
  white-space: nowrap;
}

.clearfix {
  display: block;
  clear: both;
}

.noscroll {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}
@media screen and (max-width: 768px) {
  .noscroll {
    overflow-y: hidden;
  }
}

.noselect {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.hide {
  display: none;
}

@media screen and (max-width: 1800px) {
  .hide_1800 {
    display: none;
  }
}
@media screen and (max-width: 1700px) {
  .hide_1700 {
    display: none;
  }
}
@media screen and (max-width: 1600px) {
  .hide_1600 {
    display: none;
  }
}
@media screen and (max-width: 1500px) {
  .hide_1500 {
    display: none;
  }
}
@media screen and (max-width: 1400px) {
  .hide_1400 {
    display: none;
  }
}
@media screen and (max-width: 1300px) {
  .hide_1300 {
    display: none;
  }
}
@media screen and (max-width: 1200px) {
  .hide_1200 {
    display: none;
  }
}
@media screen and (max-width: 1100px) {
  .hide_1100 {
    display: none;
  }
}
@media screen and (max-width: 1024px) {
  .hide_1024 {
    display: none;
  }
}
@media screen and (max-width: 1000px) {
  .hide_1000 {
    display: none;
  }
}
@media screen and (max-width: 900px) {
  .hide_900 {
    display: none;
  }
}
@media screen and (max-width: 800px) {
  .hide_800 {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .hide_768 {
    display: none;
  }
}
@media screen and (max-width: 700px) {
  .hide_700 {
    display: none;
  }
}
@media screen and (max-width: 600px) {
  .hide_600 {
    display: none;
  }
}
@media screen and (max-width: 500px) {
  .hide_500 {
    display: none;
  }
}
@media screen and (max-width: 400px) {
  .hide_400 {
    display: none;
  }
}
@media screen and (max-width: 300px) {
  .hide_300 {
    display: none;
  }
}
.show {
  display: block;
}

.show_1800 {
  display: none;
}
@media screen and (max-width: 1800px) {
  .show_1800 {
    display: block;
  }
}

.show_1700 {
  display: none;
}
@media screen and (max-width: 1700px) {
  .show_1700 {
    display: block;
  }
}

.show_1600 {
  display: none;
}
@media screen and (max-width: 1600px) {
  .show_1600 {
    display: block;
  }
}

.show_1500 {
  display: none;
}
@media screen and (max-width: 1500px) {
  .show_1500 {
    display: block;
  }
}

.show_1400 {
  display: none;
}
@media screen and (max-width: 1400px) {
  .show_1400 {
    display: block;
  }
}

.show_1300 {
  display: none;
}
@media screen and (max-width: 1300px) {
  .show_1300 {
    display: block;
  }
}

.show_1200 {
  display: none;
}
@media screen and (max-width: 1200px) {
  .show_1200 {
    display: block;
  }
}

.show_1100 {
  display: none;
}
@media screen and (max-width: 1100px) {
  .show_1100 {
    display: block;
  }
}

.show_1024 {
  display: none;
}
@media screen and (max-width: 1024px) {
  .show_1024 {
    display: block;
  }
}

.show_1000 {
  display: none;
}
@media screen and (max-width: 1000px) {
  .show_1000 {
    display: block;
  }
}

.show_900 {
  display: none;
}
@media screen and (max-width: 900px) {
  .show_900 {
    display: block;
  }
}

.show_800 {
  display: none;
}
@media screen and (max-width: 800px) {
  .show_800 {
    display: block;
  }
}

.show_768 {
  display: none;
}
@media screen and (max-width: 768px) {
  .show_768 {
    display: block;
  }
}

.show_700 {
  display: none;
}
@media screen and (max-width: 700px) {
  .show_700 {
    display: block;
  }
}

.show_600 {
  display: none;
}
@media screen and (max-width: 600px) {
  .show_600 {
    display: block;
  }
}

.show_500 {
  display: none;
}
@media screen and (max-width: 500px) {
  .show_500 {
    display: block;
  }
}

.show_400 {
  display: none;
}
@media screen and (max-width: 400px) {
  .show_400 {
    display: block;
  }
}

.show_300 {
  display: none;
}
@media screen and (max-width: 300px) {
  .show_300 {
    display: block;
  }
}

.zIndex-1 {
  z-index: -1;
}

.zIndex0 {
  z-index: 0;
}

.zIndex1 {
  z-index: 1;
}

.zIndex2 {
  z-index: 2;
}

.zIndex3 {
  z-index: 3;
}

.zIndex4 {
  z-index: 4;
}

.zIndex5 {
  z-index: 5;
}

.zIndex6 {
  z-index: 6;
}

.zIndex7 {
  z-index: 7;
}

.zIndex8 {
  z-index: 8;
}

.zIndex9 {
  z-index: 9;
}

.zIndex10 {
  z-index: 10;
}

h1, .h1 {
  font: 100px/1 "Financier_Display_Semibold", serif;
  color: #7aa77f;
}
@media screen and (max-height: 720px) {
  h1, .h1 {
    font-size: 85px;
  }
}
@media screen and (max-width: 1400px) {
  h1, .h1 {
    font-size: 85px;
  }
}
@media screen and (max-height: 620px) {
  h1, .h1 {
    font-size: 70px;
  }
}
@media screen and (max-width: 1200px) {
  h1, .h1 {
    font-size: 70px;
  }
}
@media screen and (max-width: 1024px) {
  h1, .h1 {
    font-size: 60px;
  }
}
@media screen and (max-width: 768px) {
  h1, .h1 {
    font-size: 50px;
  }
}
h1.small, .h1.small {
  font-size: 65px;
}
@media screen and (max-width: 1024px) {
  h1.small, .h1.small {
    font-size: 60px;
  }
}
@media screen and (max-width: 768px) {
  h1.small, .h1.small {
    font-size: 50px;
  }
}

h2, .h2 {
  display: inline-block;
  font: 118px/1.1 "Financier_Display_Semibold", serif;
  color: #3e3e2d;
}
@media screen and (max-width: 1400px) {
  h2, .h2 {
    font-size: 105px;
  }
}
@media screen and (max-width: 1200px) {
  h2, .h2 {
    font-size: 90px;
  }
}
@media screen and (max-width: 1024px) {
  h2, .h2 {
    font-size: 75px;
  }
}
@media screen and (max-width: 768px) {
  h2, .h2 {
    font-size: 65px;
  }
}
@media screen and (max-width: 600px) {
  h2, .h2 {
    font-size: 55px;
  }
}
@media screen and (max-width: 500px) {
  h2, .h2 {
    font-size: 45px;
  }
}
h2 span, .h2 span {
  display: block;
  margin-top: 10px;
  font: 18px/1 "Financier_Display_Semibold", serif;
  color: #3e3e2d;
  text-align: right;
}

h3, .h3 {
  font: 35px/1.1 "Financier_Display_Semibold", serif;
  color: #3e3e2d;
}
@media screen and (max-width: 1200px) {
  h3, .h3 {
    font-size: 33px;
  }
}
@media screen and (max-width: 1024px) {
  h3, .h3 {
    font-size: 31px;
  }
}
@media screen and (max-width: 768px) {
  h3, .h3 {
    font-size: 29px;
  }
}
@media screen and (max-width: 600px) {
  h3, .h3 {
    font-size: 27px;
  }
}
@media screen and (max-width: 400px) {
  h3, .h3 {
    font-size: 25px;
  }
}

h4, .h4 {
  font: 30px/1.1 "hinted-Geomanist-Regular", sans-serif;
  color: #3e3e2d;
}
@media screen and (max-width: 1024px) {
  h4, .h4 {
    font-size: 28px;
  }
}
@media screen and (max-width: 768px) {
  h4, .h4 {
    font-size: 26px;
  }
}
@media screen and (max-width: 600px) {
  h4, .h4 {
    font-size: 24px;
  }
}
@media screen and (max-width: 400px) {
  h4, .h4 {
    font-size: 22px;
  }
}

h5, .h5 {
  font: 25px/1.1 "Financier_Display_Semibold", serif;
  color: #3e3e2d;
}
@media screen and (max-width: 1024px) {
  h5, .h5 {
    font-size: 23px;
  }
}
@media screen and (max-width: 768px) {
  h5, .h5 {
    font-size: 21px;
  }
}
@media screen and (max-width: 600px) {
  h5, .h5 {
    font-size: 19px;
  }
}

h6, .h6 {
  font: 18px/1 "Financier_Display_Semibold", serif;
  color: #3e3e2d;
}

p {
  font: 18px/1.5 "hinted-Geomanist-Regular", sans-serif;
  color: #3e3e2d;
}
@media screen and (max-width: 768px) {
  p {
    font-size: 16px;
  }
}
p.small {
  font: 12px/1.5 "hinted-Geomanist-Regular", sans-serif;
  color: #a6a297;
}

a {
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
}

.link {
  position: relative;
  padding-bottom: 5px;
  font: 18px/1 "hinted-Geomanist-Book", sans-serif;
  color: #7aa77f;
}
.link:after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  right: 0;
  bottom: 0;
  background: #7aa77f;
  transition: width 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
.link:hover:after {
  width: 100%;
  right: auto;
  left: 0;
}

.btn {
  position: relative;
  height: 40px;
  font: 12px/40px "hinted-Geomanist-Book", sans-serif;
  color: #3e3e2d;
  padding: 0 40px;
  border: 1px solid #7aa77f;
  border-radius: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: color 300ms, background 300ms, border 300ms;
}
.btn:hover {
  color: #fff;
  background: #7aa77f;
  border-color: #7aa77f;
}
.btn.white {
  color: #fff;
  border: 1px solid #fff;
}
.btn.white:hover {
  color: #3e3e2d;
  background: #fff;
}
.btn.white.full {
  color: #3e3e2d;
  background: #fff;
  border: none;
}
.btn.white.full:hover {
  color: #fff;
  background: #7aa77f;
}
.btn.green {
  color: #fff;
  border: 1px solid #7aa77f;
  background: #7aa77f;
}
.btn.green:hover {
  color: #7aa77f;
  background: transparent;
}

.liste li {
  padding: 20px 0;
}
.liste li:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.liste li, .liste li p {
  font: 18px/1.5 "hinted-Geomanist-Medium", sans-serif;
  color: #7aa77f;
}

p.subInfo {
  font: 12px/1 "hinted-Geomanist-Book", sans-serif;
  color: #e4b787;
  text-transform: uppercase;
  margin-bottom: 0;
}
p.subInfo span:first-child {
  position: relative;
  padding-right: 20px;
}
p.subInfo span:first-child:after {
  content: "/";
  position: absolute;
  top: 2px;
  right: 7px;
  position: absolute;
  font: 12px/1 "hinted-Geomanist-Book", sans-serif;
  color: #e4b787;
}

.arrowWrapper {
  position: absolute;
  width: 50px;
  height: 14px;
  overflow: hidden;
  transition: width 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.arrowWrapper svg {
  position: absolute;
  top: 50%;
  right: 0;
  width: 50px;
  height: 14px;
  transform: translateY(-50%);
}

html:not(.isMobile) {
  overflow: hidden;
}
html:not(.isMobile) [data-mainScrollbar] {
  position: static;
  width: 100%;
  height: 100vh;
}

[data-mainScrollbar] {
  overflow: hidden;
}

.imageWrapper {
  position: relative;
}
.imageWrapper p {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 15px 25px;
  font: 12px/1 "hinted-Geomanist-Book", sans-serif;
  color: #fff;
  background: #e4b787;
  z-index: 1;
}

.image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

textarea,
input[type=text],
input[type=button],
input[type=submit] {
  -webkit-appearance: none !important;
  border-radius: 0px !important;
}

@media print {
  #page-transition,
  #landing,
  .alertBlock,
  header,
  .sideNav,
  footer,
  .copyright {
    display: none;
  }
  div[data-mainscrollbar] {
    overflow: show !important;
    height: auto !important;
  }
  div[data-mainscrollbar] .scroll-content {
    transform: translate3d(0px, 0px, 0px) !important;
  }
  [data-scrollfire] {
    opacity: 1 !important;
    transform: none !important;
    transition-delay: 0s !important;
    transition-duration: 0s !important;
  }
  .content .twoColBlock {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .content .twoColBlock .col16 {
    width: 100% !important;
  }
  .content .twoColBlock .col16 > div {
    max-width: none !important;
  }
  .content .twoColBlock .col16 > div .dynamic {
    max-width: none !important;
  }
}
.jobs li:not(:last-child) {
  margin-bottom: 10px;
}

.job {
  display: block;
  position: relative;
  padding: 40px 120px 40px 40px;
  background: #fff;
}
@media screen and (max-width: 600px) {
  .job {
    padding: 30px 70px 30px 20px;
  }
}
.job .icon {
  position: absolute;
  width: 100px;
  top: 0;
  right: 0;
  bottom: 0;
  background: #7aa77f;
}
@media screen and (max-width: 600px) {
  .job .icon {
    width: 50px;
  }
}
.job .icon svg {
  position: absolute;
  width: 20px;
  height: 20px;
  top: calc(50% - 10px);
  right: 40px;
  fill: #3e3e2d;
}
@media screen and (max-width: 600px) {
  .job .icon svg {
    right: 15px;
  }
}
.job .icon svg use {
  fill: #3e3e2d;
  transition: fill 300ms;
}
.job div {
  width: 100%;
}
.job div h6 {
  font: 12px/1 "hinted-Geomanist-Book", sans-serif;
  color: #e4b787;
  margin-bottom: 5px;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.job:hover .icon svg, .job:hover .icon svg use {
  fill: #fff;
}
.job:hover .imageWrapper .image:after {
  background: rgba(122, 167, 127, 0.5);
}

.news {
  display: block;
  position: relative;
  padding: 70px 40px;
  background: #fff;
  max-width: 380px;
}
.news:after {
  content: "";
  position: absolute;
  width: 50px;
  height: 50px;
  right: 0;
  bottom: 0;
  background: #7aa77f;
  transition: width 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.news .subInfo {
  position: absolute;
  top: 35px;
  right: 40px;
}
.news .imageWrapper {
  position: relative;
  width: 250px;
  height: 250px;
  margin: 0 auto 40px;
  border-radius: 50%;
  overflow: hidden;
}
@media screen and (max-width: 400px) {
  .news .imageWrapper {
    width: 220px;
    height: 220px;
  }
}
.news .imageWrapper:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(122, 167, 127, 0);
  transition: background 300ms;
}
.news .imageWrapper .image {
  position: absolute;
  top: -50px;
  right: 0;
  bottom: -50px;
  left: 0;
}
.news h5 {
  font-size: 25px;
}
.news .arrowWrapper {
  right: 20px;
  bottom: 18px;
  z-index: 1;
}
.news .arrowWrapper svg {
  stroke: #3e3e2d;
}
.news .arrowWrapper svg use {
  stroke: #3e3e2d;
}
.news:hover:after {
  width: 70px;
}
.news:hover .imageWrapper:after {
  background: rgba(122, 167, 127, 0.65);
}
.news:hover .arrowWrapper {
  width: 30px;
}

.event {
  display: block;
}
.event .imageWrapper .image {
  height: 600px;
}
@media screen and (max-width: 1500px) {
  .event .imageWrapper .image {
    height: 500px;
  }
}
@media screen and (max-width: 1024px) {
  .event .imageWrapper .image {
    height: 450px;
  }
}
@media screen and (max-width: 768px) {
  .event .imageWrapper .image {
    height: 400px;
  }
}
@media screen and (max-width: 600px) {
  .event .imageWrapper .image {
    height: 350px;
  }
}
@media screen and (max-width: 500px) {
  .event .imageWrapper .image {
    height: 300px;
  }
}
@media screen and (max-width: 400px) {
  .event .imageWrapper .image {
    height: 250px;
  }
}
.event .flex {
  position: relative;
  width: auto;
  height: 250px;
  margin: -60px 75px 0;
  align-items: center;
  background: #fff;
  z-index: 1;
}
@media screen and (max-width: 1300px) {
  .event .flex {
    margin: -60px 50px 0;
  }
}
@media screen and (max-width: 1024px) {
  .event .flex {
    margin: -60px 30px 0;
  }
}
@media screen and (max-width: 900px) {
  .event .flex {
    margin: -60px 20px 0;
  }
}
@media screen and (max-width: 768px) {
  .event .flex {
    display: block;
    height: 100%;
    margin: 0;
  }
}
.event .flex .row {
  width: 100%;
  position: relative;
  padding: 60px 100px;
}
@media screen and (max-width: 1400px) {
  .event .flex .row {
    padding: 50px 80px;
  }
}
@media screen and (max-width: 1200px) {
  .event .flex .row {
    padding: 40px 60px;
  }
}
@media screen and (max-width: 1100px) {
  .event .flex .row {
    padding: 40px 40px;
  }
}
@media screen and (max-width: 1024px) {
  .event .flex .row {
    padding: 30px 30px;
  }
}
@media screen and (max-width: 768px) {
  .event .flex .row {
    padding: 30px 20px;
    text-align: center;
  }
}
@media screen and (max-width: 600px) {
  .event .flex .row {
    padding: 30px 15px;
  }
}
.event .flex .row > div {
  padding: 0;
}
.event .flex .row h3 {
  margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
  .event .flex .row h3 {
    margin-bottom: 10px;
  }
}
.event .flex .row .info {
  width: auto;
}
@media screen and (max-width: 768px) {
  .event .flex .row .info {
    margin-bottom: 10px;
  }
}
.event .flex .row .info span {
  display: block;
  font: 12px/1.3 "hinted-Geomanist-Book", sans-serif;
  color: #7aa77f;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.event .flex .row .time {
  font: 18px/1.5 "Financier_Display_Semibold", serif;
  color: #3e3e2d;
  margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
  .event .flex .row .time {
    line-height: 1.2;
    margin-bottom: 10px;
  }
}
.event .flex .row .col10 div {
  max-width: 217px;
  margin-left: auto;
}
@media screen and (max-width: 768px) {
  .event .flex .row .col10 div {
    max-width: 100%;
    margin-left: 0;
  }
}
.event:hover .btn {
  color: #fff;
  background: #7aa77f;
  border-color: #7aa77f;
}

.documents li:not(:last-child) {
  margin-bottom: 10px;
}

.document {
  display: block;
  position: relative;
}
.document .icon {
  position: absolute;
  width: 100px;
  top: 0;
  right: 0;
  bottom: 0;
}
@media screen and (max-width: 600px) {
  .document .icon {
    width: 50px;
  }
}
.document .icon svg {
  position: absolute;
  width: 20px;
  height: 20px;
  top: calc(50% - 10px);
  right: 40px;
}
@media screen and (max-width: 600px) {
  .document .icon svg {
    right: 15px;
  }
}
.document .icon svg use {
  transition: fill 300ms;
}
.document.normal {
  padding: 40px 120px 40px 40px;
  background: #fff;
}
@media screen and (max-width: 1024px) {
  .document.normal {
    padding: 30px 120px 30px 30px;
  }
}
@media screen and (max-width: 600px) {
  .document.normal {
    padding: 30px 70px 30px 20px;
  }
}
.document.normal .icon {
  background: #7aa77f;
}
.document.normal .icon svg {
  fill: #3e3e2d;
}
.document.normal .icon svg use {
  fill: #3e3e2d;
}
.document.normal:hover .icon svg {
  fill: #fff;
}
.document.normal:hover .icon svg use {
  fill: #fff;
}
.document.plus {
  padding: 10px 120px 10px 15px;
  background: #fff;
}
@media screen and (max-width: 600px) {
  .document.plus {
    padding: 30px 70px 30px 20px;
  }
}
.document.plus:hover .icon svg, .document.plus:hover .icon svg use {
  fill: #fff;
}
.document.plus:hover .imageWrapper .image:after {
  background: rgba(122, 167, 127, 0.5);
}
.document.plus .imageWrapper {
  position: relative;
  float: left;
  width: 100px;
  height: 100px;
  margin-right: 20px;
  border-radius: 50%;
  overflow: hidden;
  vertical-align: middle;
}
@media screen and (max-width: 600px) {
  .document.plus .imageWrapper {
    display: none;
  }
}
.document.plus .imageWrapper .image {
  position: absolute;
  top: -20px;
  right: 0;
  bottom: -20px;
  left: 0;
}
.document.plus .imageWrapper .image:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(122, 167, 127, 0);
  transition: background 300ms;
}
.document.plus .flex {
  height: 100px;
  align-items: center;
}
@media screen and (max-width: 600px) {
  .document.plus .flex {
    height: 100%;
  }
}
.document.plus .flex div {
  width: 100%;
}
.document.plus .flex div h6 {
  font: 12px/1 "hinted-Geomanist-Book", sans-serif;
  color: #e4b787;
  margin-bottom: 5px;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.document.plus .icon {
  background: #7aa77f;
}
.document.plus .icon svg {
  fill: #3e3e2d;
}
.document.plus .icon svg use {
  fill: #3e3e2d;
}
.document.inRubric {
  padding: 30px 120px 30px 40px;
  background: #e4b787;
}
@media screen and (max-width: 1024px) {
  .document.inRubric {
    padding: 30px 120px 30px 30px;
  }
}
@media screen and (max-width: 600px) {
  .document.inRubric {
    padding: 30px 70px 30px 20px;
  }
}
.document.inRubric h5 {
  color: #fff;
}
.document.inRubric .icon {
  background: #e4b787;
}
.document.inRubric .icon svg {
  fill: #fff;
}
.document.inRubric .icon svg use {
  fill: #fff;
}
.document.inRubric:hover .icon svg {
  fill: #3e3e2d;
}
.document.inRubric:hover .icon svg use {
  fill: #3e3e2d;
}

.hamburger {
  position: relative;
  display: none;
  width: 40px;
  height: 40px;
  border: 1px solid #fff;
  border-radius: 50%;
  font: inherit;
  color: inherit;
  text-transform: none;
  vertical-align: middle;
  cursor: pointer;
  overflow: visible;
  background: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: background 300ms;
}
@media screen and (max-height: 600px) {
  .hamburger {
    display: inline-block;
  }
}
@media screen and (max-width: 1024px) {
  .hamburger {
    display: inline-block;
  }
}
.hamburger .box {
  position: absolute;
  width: 20px;
  height: 14px;
  top: 50%;
  left: 50%;
  vertical-align: middle;
  transform: translate(-50%, -50%);
}
.hamburger .box::before, .hamburger .box::after {
  content: "";
  position: absolute;
  width: 20px;
  display: block;
  height: 2px;
  background: #fff;
}
.hamburger .box:before {
  top: 0px;
}
.hamburger .box:after {
  bottom: 0px;
}
.hamburger .box .inner {
  display: block;
  height: 2px;
  background: #fff;
  margin-top: 6px;
}
.hamburger:hover {
  background: #fff;
}
.hamburger:hover .box .inner, .hamburger:hover .box:before, .hamburger:hover .box:after {
  background: #7aa77f;
}

.showOverlayMenu .hamburger {
  background: #fff;
}
.showOverlayMenu .box:before, .showOverlayMenu .box:after, .showOverlayMenu .box .inner {
  background: #7aa77f;
}
.showOverlayMenu .box:before {
  top: 6px;
  transform: rotate(45deg);
  transition: background 150ms, top 150ms cubic-bezier(0.215, 0.61, 0.355, 1), transform 150ms cubic-bezier(0.215, 0.61, 0.355, 1) 150ms;
}
.showOverlayMenu .box:after {
  bottom: 6px;
  transform: rotate(-45deg);
  transition: background 150ms, bottom 150ms cubic-bezier(0.215, 0.61, 0.355, 1), transform 150ms cubic-bezier(0.215, 0.61, 0.355, 1) 150ms;
}
.showOverlayMenu .box .inner {
  width: 0px !important;
  transition: background 150ms, width 0ms ease 150ms;
}

.showOverlayMenu.overlayClosing .box:before {
  top: 0px;
  transform: none;
  transition: background 300ms, top 150ms cubic-bezier(0.215, 0.61, 0.355, 1) 150ms, transform 150ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
.showOverlayMenu.overlayClosing .box:after {
  bottom: 0px;
  transform: none;
  transition: background 300ms, bottom 150ms cubic-bezier(0.215, 0.61, 0.355, 1) 150ms, transform 150ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
.showOverlayMenu.overlayClosing .box .inner {
  width: 20px !important;
  transition: background 300ms, transform 150ms cubic-bezier(0.215, 0.61, 0.355, 1), width 150ms;
}

.dynamic :last-child {
  margin-bottom: 0;
}
.dynamic h2, .dynamic h3, .dynamic h4, .dynamic .special, .dynamic .surtitre, .dynamic p, .dynamic a, .dynamic ul, .dynamic ol, .dynamic table, .dynamic hr, .dynamic img {
  margin: 0;
}
.dynamic h2 {
  display: block;
  font: 35px/1.1 "Financier_Display_Semibold", serif;
  color: #3e3e2d;
}
@media screen and (max-width: 1200px) {
  .dynamic h2 {
    font-size: 33px;
  }
}
@media screen and (max-width: 1024px) {
  .dynamic h2 {
    font-size: 31px;
  }
}
@media screen and (max-width: 768px) {
  .dynamic h2 {
    font-size: 29px;
  }
}
@media screen and (max-width: 600px) {
  .dynamic h2 {
    font-size: 27px;
  }
}
@media screen and (max-width: 400px) {
  .dynamic h2 {
    font-size: 25px;
  }
}
.dynamic h3 {
  font: 25px/1.4 "hinted-Geomanist-Regular", sans-serif;
  color: #3e3e2d;
}
@media screen and (max-width: 1200px) {
  .dynamic h3 {
    font-size: 23px;
  }
}
@media screen and (max-width: 1024px) {
  .dynamic h3 {
    font-size: 21px;
  }
}
@media screen and (max-width: 768px) {
  .dynamic h3 {
    font-size: 19px;
  }
}
@media screen and (max-width: 600px) {
  .dynamic h3 {
    font-size: 18px;
  }
}
.dynamic h4 {
  font: 25px/1.4 "Financier_Display_Semibold", serif;
  color: #3e3e2d;
}
@media screen and (max-width: 1024px) {
  .dynamic h4 {
    font-size: 23px;
  }
}
@media screen and (max-width: 768px) {
  .dynamic h4 {
    font-size: 21px;
  }
}
@media screen and (max-width: 600px) {
  .dynamic h4 {
    font-size: 19px;
  }
}
.dynamic .special {
  padding-left: 25px;
  font: 25px/1.5 "hinted-Geomanist-Regular", sans-serif;
  color: #a6a297;
  border-left: 1px solid #a6a297;
}
@media screen and (max-width: 1024px) {
  .dynamic .special {
    font-size: 23px;
  }
}
@media screen and (max-width: 768px) {
  .dynamic .special {
    font-size: 21px;
  }
}
.dynamic .surtitre {
  font: 12px/1.3 "hinted-Geomanist-Regular", sans-serif;
  color: #a6a297;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.dynamic p {
  font: 18px/1.5 "hinted-Geomanist-Regular", sans-serif;
  color: #3e3e2d;
}
.dynamic ul {
  list-style-type: none;
}
.dynamic ul > li {
  position: relative;
  padding-left: 15px;
  font: 18px/1.5 "hinted-Geomanist-Regular", sans-serif;
  color: #3e3e2d;
}
.dynamic ul > li:not(:last-child) {
  margin-bottom: 20px;
}
@media screen and (max-width: 1024px) {
  .dynamic ul > li:not(:last-child) {
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 768px) {
  .dynamic ul > li:not(:last-child) {
    margin-bottom: 10px;
  }
}
.dynamic ul > li:before {
  content: "";
  position: absolute;
  width: 6px;
  height: 1px;
  top: 14px;
  left: 0;
  background: #3e3e2d;
}
.dynamic ul > li p {
  margin-bottom: 0;
  font: 18px/1.5 "hinted-Geomanist-Regular", sans-serif;
  color: #3e3e2d;
}
.dynamic ol {
  counter-reset: list;
}
.dynamic ol > li {
  position: relative;
  padding-left: 28px;
  font: 18px/1.5 "hinted-Geomanist-Regular", sans-serif;
  color: #3e3e2d;
}
.dynamic ol > li:not(:last-child) {
  margin-bottom: 20px;
}
@media screen and (max-width: 1024px) {
  .dynamic ol > li:not(:last-child) {
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 768px) {
  .dynamic ol > li:not(:last-child) {
    margin-bottom: 10px;
  }
}
.dynamic ol > li:before {
  counter-increment: list;
  content: counter(list) ". ";
  position: absolute;
  left: 0;
}
.dynamic ol > li p {
  margin-bottom: 0;
  font: 18px/1.5 "hinted-Geomanist-Regular", sans-serif;
  color: #3e3e2d;
}
.dynamic ul li li, .dynamic ol li li {
  margin-top: 10px !important;
  margin-bottom: 0 !important;
}
.dynamic a:not(.btn):not(.link) {
  position: relative;
  padding-bottom: 5px;
  font: 18px/1 "hinted-Geomanist-Book", sans-serif;
  color: #7aa77f;
}
.dynamic a:not(.btn):not(.link):after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  right: 0;
  bottom: 0;
  background: #7aa77f;
  transition: width 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
.dynamic a:not(.btn):not(.link):hover:after {
  width: 100%;
  right: auto;
  left: 0;
}
.dynamic a.btn {
  position: relative;
  height: 40px;
  font: 12px/40px "hinted-Geomanist-Book", sans-serif;
  color: #3e3e2d;
  padding: 0 40px;
  border: 1px solid #7aa77f;
  border-radius: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: color 300ms, background 300ms, border 300ms;
}
.dynamic a.btn:hover {
  color: #fff;
  background: #7aa77f;
  border-color: #7aa77f;
}
.dynamic img {
  max-width: 100%;
  width: 100%;
  margin: 30px 0 20px;
}
.dynamic hr {
  margin-block-start: 0;
  margin-block-end: 0;
  border-bottom-width: 0;
}
.dynamic strong {
  font-style: normal;
  font-weight: normal;
  font-family: "hinted-Geomanist-Medium", sans-serif;
}
.dynamic em {
  font-style: normal;
  font-weight: normal;
  font-family: "hinted-Geomanist-Regular-italic", sans-serif;
}
.dynamic strong em, .dynamic em strong {
  font-style: normal;
  font-weight: bold;
  font-family: "hinted-Geomanist-Regular-italic", sans-serif;
}
.dynamic table {
  border: 1px solid;
}
.dynamic table thead tr th, .dynamic table thead tr td {
  padding: 20px;
  font: 16px/1.5 "hinted-Geomanist-Medium", sans-serif;
  color: #fff;
  background: #7aa77f;
  border: 1px solid #7aa77f;
}
.dynamic table tbody tr td, .dynamic table tbody tr th {
  padding: 20px;
  font: 16px/1.5 "hinted-Geomanist-Book", sans-serif;
  text-align: center !important;
  border: 1px solid #c0cfb9;
  vertical-align: middle !important;
}
@media screen and (max-width: 1400px) {
  .dynamic table thead tr th, .dynamic table thead tr td {
    padding: 20px 10px;
    font: 14px/1.5 "hinted-Geomanist-Medium", sans-serif;
  }
  .dynamic table tbody tr td, .dynamic table tbody tr th {
    padding: 20px 10px;
    font: 14px/1.5 "hinted-Geomanist-Book", sans-serif;
  }
}
@media screen and (max-width: 1200px) {
  .dynamic table thead tr th, .dynamic table thead tr td {
    padding: 15px 7px;
    font: 13px/1.5 "hinted-Geomanist-Medium", sans-serif;
  }
  .dynamic table tbody tr td, .dynamic table tbody tr th {
    padding: 15px 7px;
    font: 13px/1.5 "hinted-Geomanist-Book", sans-serif;
  }
}
@media screen and (max-width: 600px) {
  .dynamic table thead tr th, .dynamic table thead tr td {
    padding: 10px 5px;
    font: 12px/1.5 "hinted-Geomanist-Medium", sans-serif;
  }
  .dynamic table tbody tr td, .dynamic table tbody tr th {
    padding: 10px 5px;
    font: 12px/1.5 "hinted-Geomanist-Book", sans-serif;
  }
}
.dynamic h2 + h2,
.dynamic h3 + h2,
.dynamic h4 + h2,
.dynamic p + h2,
.dynamic a + h2,
.dynamic ul + h2,
.dynamic ol + h2,
.dynamic hr + h2,
.dynamic table + h2 .special + h2 {
  margin-top: 85px;
}
@media screen and (max-width: 1400px) {
  .dynamic h2 + h2,
  .dynamic h3 + h2,
  .dynamic h4 + h2,
  .dynamic p + h2,
  .dynamic a + h2,
  .dynamic ul + h2,
  .dynamic ol + h2,
  .dynamic hr + h2,
  .dynamic table + h2 .special + h2 {
    margin-top: 75px;
  }
}
@media screen and (max-width: 1200px) {
  .dynamic h2 + h2,
  .dynamic h3 + h2,
  .dynamic h4 + h2,
  .dynamic p + h2,
  .dynamic a + h2,
  .dynamic ul + h2,
  .dynamic ol + h2,
  .dynamic hr + h2,
  .dynamic table + h2 .special + h2 {
    margin-top: 65px;
  }
}
@media screen and (max-width: 1024px) {
  .dynamic h2 + h2,
  .dynamic h3 + h2,
  .dynamic h4 + h2,
  .dynamic p + h2,
  .dynamic a + h2,
  .dynamic ul + h2,
  .dynamic ol + h2,
  .dynamic hr + h2,
  .dynamic table + h2 .special + h2 {
    margin-top: 55px;
  }
}
@media screen and (max-width: 768px) {
  .dynamic h2 + h2,
  .dynamic h3 + h2,
  .dynamic h4 + h2,
  .dynamic p + h2,
  .dynamic a + h2,
  .dynamic ul + h2,
  .dynamic ol + h2,
  .dynamic hr + h2,
  .dynamic table + h2 .special + h2 {
    margin-top: 45px;
  }
}
@media screen and (max-width: 600px) {
  .dynamic h2 + h2,
  .dynamic h3 + h2,
  .dynamic h4 + h2,
  .dynamic p + h2,
  .dynamic a + h2,
  .dynamic ul + h2,
  .dynamic ol + h2,
  .dynamic hr + h2,
  .dynamic table + h2 .special + h2 {
    margin-top: 40px;
  }
}
.dynamic .surtitre + h2 {
  margin-top: 20px;
}
@media screen and (max-width: 600px) {
  .dynamic .surtitre + h2 {
    margin-top: 15px;
  }
}
.dynamic h2 + h3 {
  margin-top: 40px;
}
@media screen and (max-width: 1024px) {
  .dynamic h2 + h3 {
    margin-top: 35px;
  }
}
@media screen and (max-width: 768px) {
  .dynamic h2 + h3 {
    margin-top: 30px;
  }
}
@media screen and (max-width: 600px) {
  .dynamic h2 + h3 {
    margin-top: 25px;
  }
}
.dynamic h3 + h3,
.dynamic h4 + h3,
.dynamic p + h3,
.dynamic a + h3,
.dynamic ul + h3,
.dynamic ol + h3,
.dynamic hr + h3,
.dynamic table + h3,
.dynamic .special + h3 {
  margin-top: 30px;
}
@media screen and (max-width: 768px) {
  .dynamic h3 + h3,
  .dynamic h4 + h3,
  .dynamic p + h3,
  .dynamic a + h3,
  .dynamic ul + h3,
  .dynamic ol + h3,
  .dynamic hr + h3,
  .dynamic table + h3,
  .dynamic .special + h3 {
    margin-top: 20px;
  }
}
.dynamic .surtitre + h3 {
  margin-top: 20px;
}
@media screen and (max-width: 600px) {
  .dynamic .surtitre + h3 {
    margin-top: 15px;
  }
}
.dynamic h2 + h4 hr + h4 {
  margin-top: 40px;
}
@media screen and (max-width: 1024px) {
  .dynamic h2 + h4 hr + h4 {
    margin-top: 35px;
  }
}
@media screen and (max-width: 768px) {
  .dynamic h2 + h4 hr + h4 {
    margin-top: 30px;
  }
}
@media screen and (max-width: 600px) {
  .dynamic h2 + h4 hr + h4 {
    margin-top: 25px;
  }
}
.dynamic h3 + h4,
.dynamic h4 + h4,
.dynamic p + h4,
.dynamic a + h4,
.dynamic ul + h4,
.dynamic ol + h4,
.dynamic table + h4,
.dynamic .special + h4 {
  margin-top: 30px;
}
@media screen and (max-width: 768px) {
  .dynamic h3 + h4,
  .dynamic h4 + h4,
  .dynamic p + h4,
  .dynamic a + h4,
  .dynamic ul + h4,
  .dynamic ol + h4,
  .dynamic table + h4,
  .dynamic .special + h4 {
    margin-top: 20px;
  }
}
.dynamic .surtitre + h4 {
  margin-top: 20px;
}
@media screen and (max-width: 600px) {
  .dynamic .surtitre + h4 {
    margin-top: 15px;
  }
}
.dynamic h2 + .special,
.dynamic h3 + .special,
.dynamic h4 + .special,
.dynamic p + .special,
.dynamic a + .special,
.dynamic ul + .special,
.dynamic ol + .special,
.dynamic hr + .special,
.dynamic table + .special,
.dynamic .special + .special,
.dynamic .surtitre + .special {
  margin-top: 40px;
}
@media screen and (max-width: 1024px) {
  .dynamic h2 + .special,
  .dynamic h3 + .special,
  .dynamic h4 + .special,
  .dynamic p + .special,
  .dynamic a + .special,
  .dynamic ul + .special,
  .dynamic ol + .special,
  .dynamic hr + .special,
  .dynamic table + .special,
  .dynamic .special + .special,
  .dynamic .surtitre + .special {
    margin-top: 35px;
  }
}
@media screen and (max-width: 768px) {
  .dynamic h2 + .special,
  .dynamic h3 + .special,
  .dynamic h4 + .special,
  .dynamic p + .special,
  .dynamic a + .special,
  .dynamic ul + .special,
  .dynamic ol + .special,
  .dynamic hr + .special,
  .dynamic table + .special,
  .dynamic .special + .special,
  .dynamic .surtitre + .special {
    margin-top: 30px;
  }
}
@media screen and (max-width: 600px) {
  .dynamic h2 + .special,
  .dynamic h3 + .special,
  .dynamic h4 + .special,
  .dynamic p + .special,
  .dynamic a + .special,
  .dynamic ul + .special,
  .dynamic ol + .special,
  .dynamic hr + .special,
  .dynamic table + .special,
  .dynamic .special + .special,
  .dynamic .surtitre + .special {
    margin-top: 25px;
  }
}
.dynamic h2 + .surtitre,
.dynamic hr + .surtitre {
  margin-top: 40px;
}
@media screen and (max-width: 1024px) {
  .dynamic h2 + .surtitre,
  .dynamic hr + .surtitre {
    margin-top: 35px;
  }
}
@media screen and (max-width: 768px) {
  .dynamic h2 + .surtitre,
  .dynamic hr + .surtitre {
    margin-top: 30px;
  }
}
@media screen and (max-width: 600px) {
  .dynamic h2 + .surtitre,
  .dynamic hr + .surtitre {
    margin-top: 25px;
  }
}
.dynamic h3 + .surtitre,
.dynamic h4 + .surtitre,
.dynamic .special + .surtitre {
  margin-top: 30px;
}
@media screen and (max-width: 768px) {
  .dynamic h3 + .surtitre,
  .dynamic h4 + .surtitre,
  .dynamic .special + .surtitre {
    margin-top: 20px;
  }
}
.dynamic p + .surtitre,
.dynamic a + .surtitre,
.dynamic ul + .surtitre,
.dynamic ol + .surtitre,
.dynamic table + .surtitre,
.dynamic .surtitre + .surtitre {
  margin-top: 20px;
}
@media screen and (max-width: 600px) {
  .dynamic p + .surtitre,
  .dynamic a + .surtitre,
  .dynamic ul + .surtitre,
  .dynamic ol + .surtitre,
  .dynamic table + .surtitre,
  .dynamic .surtitre + .surtitre {
    margin-top: 15px;
  }
}
.dynamic h2 + p,
.dynamic hr + p {
  margin-top: 40px;
}
@media screen and (max-width: 1024px) {
  .dynamic h2 + p,
  .dynamic hr + p {
    margin-top: 35px;
  }
}
@media screen and (max-width: 768px) {
  .dynamic h2 + p,
  .dynamic hr + p {
    margin-top: 30px;
  }
}
@media screen and (max-width: 600px) {
  .dynamic h2 + p,
  .dynamic hr + p {
    margin-top: 25px;
  }
}
.dynamic h3 + p,
.dynamic h4 + p,
.dynamic .special + p {
  margin-top: 30px;
}
@media screen and (max-width: 768px) {
  .dynamic h3 + p,
  .dynamic h4 + p,
  .dynamic .special + p {
    margin-top: 20px;
  }
}
.dynamic a + p,
.dynamic ul + p,
.dynamic ol + p,
.dynamic table + p,
.dynamic .surtitre + p {
  margin-top: 20px;
}
@media screen and (max-width: 600px) {
  .dynamic a + p,
  .dynamic ul + p,
  .dynamic ol + p,
  .dynamic table + p,
  .dynamic .surtitre + p {
    margin-top: 15px;
  }
}
.dynamic p + p {
  margin-top: 10px;
}
.dynamic h2 + a,
.dynamic hr + a {
  margin-top: 40px;
}
@media screen and (max-width: 1024px) {
  .dynamic h2 + a,
  .dynamic hr + a {
    margin-top: 35px;
  }
}
@media screen and (max-width: 768px) {
  .dynamic h2 + a,
  .dynamic hr + a {
    margin-top: 30px;
  }
}
@media screen and (max-width: 600px) {
  .dynamic h2 + a,
  .dynamic hr + a {
    margin-top: 25px;
  }
}
.dynamic h3 + a,
.dynamic h4 + a,
.dynamic .special + a {
  margin-top: 30px;
}
@media screen and (max-width: 768px) {
  .dynamic h3 + a,
  .dynamic h4 + a,
  .dynamic .special + a {
    margin-top: 20px;
  }
}
.dynamic p + a,
.dynamic a + a,
.dynamic ul + a,
.dynamic ol + a,
.dynamic table + a,
.dynamic .surtitre + a {
  margin-top: 20px;
}
@media screen and (max-width: 600px) {
  .dynamic p + a,
  .dynamic a + a,
  .dynamic ul + a,
  .dynamic ol + a,
  .dynamic table + a,
  .dynamic .surtitre + a {
    margin-top: 15px;
  }
}
.dynamic h2 + ul,
.dynamic hr + ul {
  margin-top: 40px;
}
@media screen and (max-width: 1024px) {
  .dynamic h2 + ul,
  .dynamic hr + ul {
    margin-top: 35px;
  }
}
@media screen and (max-width: 768px) {
  .dynamic h2 + ul,
  .dynamic hr + ul {
    margin-top: 30px;
  }
}
@media screen and (max-width: 600px) {
  .dynamic h2 + ul,
  .dynamic hr + ul {
    margin-top: 25px;
  }
}
.dynamic h3 + ul,
.dynamic h4 + ul,
.dynamic .special + ul {
  margin-top: 30px;
}
@media screen and (max-width: 768px) {
  .dynamic h3 + ul,
  .dynamic h4 + ul,
  .dynamic .special + ul {
    margin-top: 20px;
  }
}
.dynamic p + ul,
.dynamic a + ul,
.dynamic ul + ul,
.dynamic ol + ul,
.dynamic table + ul,
.dynamic .surtitre + ul {
  margin-top: 20px;
}
@media screen and (max-width: 600px) {
  .dynamic p + ul,
  .dynamic a + ul,
  .dynamic ul + ul,
  .dynamic ol + ul,
  .dynamic table + ul,
  .dynamic .surtitre + ul {
    margin-top: 15px;
  }
}
.dynamic h2 + ol,
.dynamic hr + ol {
  margin-top: 40px;
}
@media screen and (max-width: 1024px) {
  .dynamic h2 + ol,
  .dynamic hr + ol {
    margin-top: 35px;
  }
}
@media screen and (max-width: 768px) {
  .dynamic h2 + ol,
  .dynamic hr + ol {
    margin-top: 30px;
  }
}
@media screen and (max-width: 600px) {
  .dynamic h2 + ol,
  .dynamic hr + ol {
    margin-top: 25px;
  }
}
.dynamic h3 + ol,
.dynamic h4 + ol,
.dynamic .special + ol {
  margin-top: 30px;
}
@media screen and (max-width: 768px) {
  .dynamic h3 + ol,
  .dynamic h4 + ol,
  .dynamic .special + ol {
    margin-top: 20px;
  }
}
.dynamic p + ol,
.dynamic a + ol,
.dynamic ul + ol,
.dynamic ol + ol,
.dynamic table + ol,
.dynamic .surtitre + ol {
  margin-top: 20px;
}
@media screen and (max-width: 600px) {
  .dynamic p + ol,
  .dynamic a + ol,
  .dynamic ul + ol,
  .dynamic ol + ol,
  .dynamic table + ol,
  .dynamic .surtitre + ol {
    margin-top: 15px;
  }
}
.dynamic h2 + img,
.dynamic hr + img {
  margin-top: 40px;
}
@media screen and (max-width: 1024px) {
  .dynamic h2 + img,
  .dynamic hr + img {
    margin-top: 35px;
  }
}
@media screen and (max-width: 768px) {
  .dynamic h2 + img,
  .dynamic hr + img {
    margin-top: 30px;
  }
}
@media screen and (max-width: 600px) {
  .dynamic h2 + img,
  .dynamic hr + img {
    margin-top: 25px;
  }
}
.dynamic h3 + img,
.dynamic h4 + img,
.dynamic .special + img {
  margin-top: 30px;
}
@media screen and (max-width: 768px) {
  .dynamic h3 + img,
  .dynamic h4 + img,
  .dynamic .special + img {
    margin-top: 20px;
  }
}
.dynamic p + img,
.dynamic a + img,
.dynamic ul + img,
.dynamic ol + img,
.dynamic table + img,
.dynamic .surtitre + img {
  margin-top: 20px;
}
@media screen and (max-width: 600px) {
  .dynamic p + img,
  .dynamic a + img,
  .dynamic ul + img,
  .dynamic ol + img,
  .dynamic table + img,
  .dynamic .surtitre + img {
    margin-top: 15px;
  }
}
.dynamic h2 + hr h3 + hr,
.dynamic h4 + hr,
.dynamic p + hr,
.dynamic a + hr,
.dynamic ul + hr,
.dynamic ol + hr,
.dynamic hr + hr,
.dynamic table + hr,
.dynamic .special + hr,
.dynamic .surtitre + hr {
  margin-top: 40px;
}
@media screen and (max-width: 1024px) {
  .dynamic h2 + hr h3 + hr,
  .dynamic h4 + hr,
  .dynamic p + hr,
  .dynamic a + hr,
  .dynamic ul + hr,
  .dynamic ol + hr,
  .dynamic hr + hr,
  .dynamic table + hr,
  .dynamic .special + hr,
  .dynamic .surtitre + hr {
    margin-top: 35px;
  }
}
@media screen and (max-width: 768px) {
  .dynamic h2 + hr h3 + hr,
  .dynamic h4 + hr,
  .dynamic p + hr,
  .dynamic a + hr,
  .dynamic ul + hr,
  .dynamic ol + hr,
  .dynamic hr + hr,
  .dynamic table + hr,
  .dynamic .special + hr,
  .dynamic .surtitre + hr {
    margin-top: 30px;
  }
}
@media screen and (max-width: 600px) {
  .dynamic h2 + hr h3 + hr,
  .dynamic h4 + hr,
  .dynamic p + hr,
  .dynamic a + hr,
  .dynamic ul + hr,
  .dynamic ol + hr,
  .dynamic hr + hr,
  .dynamic table + hr,
  .dynamic .special + hr,
  .dynamic .surtitre + hr {
    margin-top: 25px;
  }
}
.dynamic h2 + table,
.dynamic hr + table {
  margin-top: 40px;
}
@media screen and (max-width: 1024px) {
  .dynamic h2 + table,
  .dynamic hr + table {
    margin-top: 35px;
  }
}
@media screen and (max-width: 768px) {
  .dynamic h2 + table,
  .dynamic hr + table {
    margin-top: 30px;
  }
}
@media screen and (max-width: 600px) {
  .dynamic h2 + table,
  .dynamic hr + table {
    margin-top: 25px;
  }
}
.dynamic h3 + table,
.dynamic h4 + table,
.dynamic .special + table {
  margin-top: 30px;
}
@media screen and (max-width: 768px) {
  .dynamic h3 + table,
  .dynamic h4 + table,
  .dynamic .special + table {
    margin-top: 20px;
  }
}
.dynamic p + table,
.dynamic a + table,
.dynamic ul + table,
.dynamic ol + table,
.dynamic .surtitre + table {
  margin-top: 20px;
}
@media screen and (max-width: 600px) {
  .dynamic p + table,
  .dynamic a + table,
  .dynamic ul + table,
  .dynamic ol + table,
  .dynamic .surtitre + table {
    margin-top: 15px;
  }
}

.breadcrumb {
  white-space: nowrap;
  text-overflow: ellipsis;
}
@media screen and (max-width: 600px) {
  .breadcrumb {
    display: none;
  }
}
.breadcrumb a, .breadcrumb span {
  display: inline-block;
  position: relative;
  font: 18px/1 "Financier_Display_Semibold", serif;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
@media screen and (max-width: 768px) {
  .breadcrumb a, .breadcrumb span {
    font-size: 16px;
  }
}
.breadcrumb a:not(:last-child), .breadcrumb span:not(:last-child) {
  padding-right: 25px;
}
.breadcrumb a:not(:last-child):after, .breadcrumb span:not(:last-child):after {
  content: "";
  position: absolute;
  width: 6px;
  height: 1px;
  top: 8px;
  right: 6px;
  background: #3e3e2d;
}
.breadcrumb a {
  color: #7aa77f;
  transition: color 300ms;
}
.breadcrumb a:hover {
  color: #3e3e2d;
}
.breadcrumb a:hover svg {
  fill: #3e3e2d;
}
.breadcrumb a:hover svg use {
  fill: #3e3e2d;
}
.breadcrumb a svg {
  width: 12px;
  height: 13px;
  fill: #7aa77f;
  transition: fill 300ms;
}
.breadcrumb a svg use {
  fill: #7aa77f;
  transition: fill 300ms;
}
.breadcrumb span {
  color: #3e3e2d;
}

.btnFastLinks {
  position: fixed;
  top: 35px;
  right: 35px;
  width: 60px;
  height: 60px;
  opacity: 0;
  visibility: hidden;
  background: #dda973;
  border-radius: 50%;
  z-index: 50;
  transition: opacity 300ms, visibility 0ms ease 300ms, background 300ms, transform 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
@media screen and (max-width: 768px) {
  .btnFastLinks {
    top: 25px;
    right: 25px;
    width: 50px;
    height: 50px;
  }
}
.btnFastLinks:before, .btnFastLinks:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  background: #fff;
  transform: translate(-50%, -50%);
}
.btnFastLinks:before {
  width: 20px;
  height: 2px;
}
.btnFastLinks:after {
  width: 2px;
  height: 20px;
}
.btnFastLinks:hover {
  background: #e4b787;
}
.btnFastLinks.display {
  opacity: 1;
  visibility: visible;
  transition: opacity 300ms, background 300ms, transform 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.showOverlayFastLinks:not(.overlayClosing) .btnFastLinks {
  background: #dda973;
  transform: rotate(45deg);
}

.headerPage h1 {
  margin: 40px 0 60px;
  color: #3e3e2d;
}
@media screen and (max-width: 1200px) {
  .headerPage h1 {
    margin: 30px 0 50px;
  }
}
@media screen and (max-width: 1024px) {
  .headerPage h1 {
    margin: 20px 0 40px;
  }
}
@media screen and (max-width: 600px) {
  .headerPage h1 {
    margin: 20px 0 30px;
  }
}
@media screen and (max-width: 600px) {
  .headerPage h1 {
    margin: 0 0 30px;
  }
}
.headerPage .subInfo {
  margin-bottom: 20px;
  color: #3e3e2d;
}
.headerPage .subInfo:after {
  color: #3e3e2d;
}
.headerPage .imageWrapper {
  margin-bottom: 40px;
}
@media screen and (max-width: 768px) {
  .headerPage .imageWrapper {
    margin-bottom: 30px;
  }
}
.headerPage .imageWrapper .image {
  width: 100%;
  height: 400px;
}
@media screen and (max-width: 768px) {
  .headerPage .imageWrapper .image {
    height: 350px;
  }
}
@media screen and (max-width: 600px) {
  .headerPage .imageWrapper .image {
    height: 300px;
  }
}

.sideNav {
  position: absolute;
  width: 33.3333%;
  top: 150px;
  right: 0;
  bottom: 0;
}
@media screen and (max-width: 1200px) {
  .sideNav {
    width: 37.5%;
  }
}
@media screen and (max-width: 1024px) {
  .sideNav {
    display: none;
  }
}
.sideNav .fastLinks {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  padding: 0 80px;
  background: #fff;
}
@media screen and (max-width: 1400px) {
  .sideNav .fastLinks {
    padding: 0 40px;
  }
}
.sideNav .fastLinks #stickyElements {
  position: sticky;
  padding: 80px 0;
  top: 0;
}
@media screen and (max-width: 1400px) {
  .sideNav .fastLinks #stickyElements {
    padding: 60px 0;
  }
}
.sideNav .fastLinks .bold {
  margin-bottom: 35px;
  padding-left: 25px;
  font: 18px/1 "hinted-Geomanist-Medium", sans-serif;
  color: #3e3e2d;
}
.sideNav .fastLinks ul {
  margin-bottom: 35px;
  border-bottom: 1px solid rgba(62, 62, 45, 0.4);
}
.sideNav .fastLinks ul li {
  position: relative;
  margin-bottom: 35px;
}
@media screen and (max-height: 750px) {
  .sideNav .fastLinks ul li {
    margin-bottom: 30px;
  }
}
@media screen and (max-height: 650px) {
  .sideNav .fastLinks ul li {
    margin-bottom: 20px;
  }
}
.sideNav .fastLinks ul li a {
  font: 15px/1.3 "hinted-Geomanist-Regular", sans-serif;
  color: #3e3e2d;
  padding-left: 25px;
  transition: color 300ms;
}
.sideNav .fastLinks ul li a:before, .sideNav .fastLinks ul li a:after {
  content: "";
  position: absolute;
  width: 10px;
  height: 1px;
  margin-left: -10px;
  left: 0;
  background: #7aa77f;
  opacity: 0;
  transition: margin 300ms, opacity 300ms;
}
.sideNav .fastLinks ul li a:before {
  top: 5px;
  transform: rotate(45deg);
}
.sideNav .fastLinks ul li a:after {
  top: 12px;
  transform: rotate(-45deg);
}
.sideNav .fastLinks ul li a:hover, .sideNav .fastLinks ul li a.active {
  color: #7aa77f;
}
.sideNav .fastLinks ul li a:hover:before, .sideNav .fastLinks ul li a:hover:after, .sideNav .fastLinks ul li a.active:before, .sideNav .fastLinks ul li a.active:after {
  margin-left: 0;
  opacity: 1;
}

.sideNav.withImage .imageWrapper {
  position: relative;
  width: 100%;
  height: 600px;
  overflow: hidden;
}
@media screen and (max-width: 1300px) {
  .sideNav.withImage .imageWrapper {
    height: 500px;
  }
}
@media screen and (max-width: 1200px) {
  .sideNav.withImage .imageWrapper {
    height: 450px;
  }
}
.sideNav.withImage .imageWrapper .image {
  position: absolute;
  top: -50px;
  right: 0;
  bottom: -50px;
  left: 0;
}
.sideNav.withImage .fastLinks {
  top: 650px;
}
@media screen and (max-width: 1300px) {
  .sideNav.withImage .fastLinks {
    top: 550px;
  }
}
@media screen and (max-width: 1200px) {
  .sideNav.withImage .fastLinks {
    top: 500px;
  }
}

.rubrics {
  margin: 0;
  max-width: none;
  list-style-type: none;
}
.rubrics > li {
  overflow: hidden;
}
.rubrics > li:not(:last-child) {
  margin-bottom: 10px;
}
.rubrics > li > .toggle {
  position: relative;
  margin: 0;
  cursor: pointer;
}
.rubrics > li > .toggle .icon {
  position: absolute;
  width: 60px;
  top: 0;
  right: 0;
  bottom: 0;
  background: #7aa77f;
}
@media screen and (max-width: 600px) {
  .rubrics > li > .toggle .icon {
    width: 50px;
  }
}
.rubrics > li > .toggle .icon svg {
  position: absolute;
  width: 12px;
  height: 12px;
  top: calc(50% - 6px);
  right: 24px;
  transform: rotate(180deg);
  transition: transform 300ms ease;
  stroke: #3e3e2d;
}
@media screen and (max-width: 600px) {
  .rubrics > li > .toggle .icon svg {
    right: 19px;
  }
}
.rubrics > li > .toggle .icon svg use {
  stroke: #3e3e2d;
  transition: stroke 300ms;
}
.rubrics > li > .toggle:hover .icon svg {
  stroke: #fff;
}
.rubrics > li > .toggle:hover .icon svg use {
  stroke: #fff;
}
.rubrics > li > .rubric {
  display: none;
  max-width: 100%;
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 300ms, transform 300ms;
}
.rubrics > li > .rubric .documents {
  margin-top: 50px;
}
@media screen and (max-width: 600px) {
  .rubrics > li > .rubric .documents {
    margin-top: 30px;
  }
}
.rubrics > li > .rubric .documents:first-child {
  margin-top: 0;
}
@media screen and (max-width: 600px) {
  .rubrics > li > .rubric .documents:first-child {
    margin-top: 0;
  }
}
.rubrics > li > .rubric .btnWrapper {
  border-top: 1px solid rgba(62, 62, 45, 0.4);
  margin-top: 70px;
}
@media screen and (max-width: 768px) {
  .rubrics > li > .rubric .btnWrapper {
    margin-top: 50px;
  }
}
@media screen and (max-width: 600px) {
  .rubrics > li > .rubric .btnWrapper {
    margin-top: 40px;
  }
}
.rubrics > li > .rubric .btnWrapper .btnShare,
.rubrics > li > .rubric .btnWrapper .btnPrint {
  margin-top: 20px;
  font: 12px/1 "hinted-Geomanist-Book", sans-serif;
  color: #7aa77f;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: color 300ms;
  margin-right: 15px;
}
.rubrics > li > .rubric .btnWrapper .btnShare:hover,
.rubrics > li > .rubric .btnWrapper .btnPrint:hover {
  color: #3e3e2d;
}
.rubrics > li > .rubric .dynamic p, .rubrics > li > .rubric .dynamic a, .rubrics > li > .rubric .dynamic p b {
  font-size: 15px;
}
.rubrics > li > .rubric .dynamic ul li, .rubrics > li > .rubric .dynamic ul li p, .rubrics > li > .rubric .dynamic ol li, .rubrics > li > .rubric .dynamic ol li p {
  font-size: 15px;
}
.rubrics > li.open > .rubric {
  opacity: 1;
  transform: translateY(0);
  transition: transform 800ms cubic-bezier(0.165, 0.84, 0.44, 1) 300ms, opacity 600ms linear 300ms;
}
.rubrics > li.open > .toggle .icon svg {
  transform: none;
}
.rubrics > li.open > .toggle:hover .icon svg {
  stroke: #3e3e2d;
}
.rubrics > li.open > .toggle:hover .icon svg use {
  stroke: #3e3e2d;
}

.rubrics.plus > li {
  background: #fff;
}
.rubrics.plus > li.open .toggle .imageWrapper .image:after {
  background: rgba(122, 167, 127, 0.5);
}
.rubrics.plus > li > .toggle {
  position: relative;
  padding: 10px 80px 10px 15px;
  background: #fff;
}
@media screen and (max-width: 600px) {
  .rubrics.plus > li > .toggle {
    padding: 30px 70px 30px 20px;
  }
}
.rubrics.plus > li > .toggle.noThumb {
  padding-left: 40px;
}
@media screen and (max-width: 600px) {
  .rubrics.plus > li > .toggle.noThumb {
    padding: 30px 70px 30px 20px;
  }
}
.rubrics.plus > li > .toggle:hover .imageWrapper .image:after {
  background: rgba(122, 167, 127, 0.5);
}
.rubrics.plus > li > .toggle .imageWrapper {
  position: relative;
  float: left;
  width: 100px;
  height: 100px;
  margin-right: 20px;
  border-radius: 50%;
  overflow: hidden;
  vertical-align: middle;
}
@media screen and (max-width: 600px) {
  .rubrics.plus > li > .toggle .imageWrapper {
    display: none;
  }
}
.rubrics.plus > li > .toggle .imageWrapper .image {
  position: absolute;
  top: -20px;
  right: 0;
  bottom: -20px;
  left: 0;
}
.rubrics.plus > li > .toggle .imageWrapper .image:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(122, 167, 127, 0);
  transition: background 300ms;
}
.rubrics.plus > li > .toggle .flex {
  height: 100px;
  align-items: center;
}
@media screen and (max-width: 600px) {
  .rubrics.plus > li > .toggle .flex {
    height: 100%;
  }
}
.rubrics.plus > li > .toggle .flex div {
  width: 100%;
}
.rubrics.plus > li > .toggle .flex div h6 {
  font: 12px/1 "hinted-Geomanist-Book", sans-serif;
  color: #e4b787;
  margin-bottom: 5px;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.rubrics.plus > li > .rubric > div {
  padding: 60px 80px 20px;
  background: #faf9f6;
}
@media screen and (max-width: 768px) {
  .rubrics.plus > li > .rubric > div {
    padding: 50px 70px 20px;
  }
}
@media screen and (max-width: 600px) {
  .rubrics.plus > li > .rubric > div {
    padding: 50px 50px 20px;
  }
}
@media screen and (max-width: 500px) {
  .rubrics.plus > li > .rubric > div {
    padding: 40px 40px 20px;
  }
}
@media screen and (max-width: 400px) {
  .rubrics.plus > li > .rubric > div {
    padding: 30px 30px 20px;
  }
}

.rubrics.normal > li {
  background: #fff;
}
.rubrics.normal > li > .toggle {
  position: relative;
  padding: 40px 80px 40px 40px;
  background: #fff;
}
@media screen and (max-width: 1024px) {
  .rubrics.normal > li > .toggle {
    padding: 40px 80px 40px 30px;
  }
}
@media screen and (max-width: 768px) {
  .rubrics.normal > li > .toggle {
    padding: 40px 80px 40px 20px;
  }
}
@media screen and (max-width: 600px) {
  .rubrics.normal > li > .toggle {
    padding: 30px 70px 30px 20px;
  }
}
.rubrics.normal > li > .toggle h5 {
  font-size: 25px;
}
@media screen and (max-width: 768px) {
  .rubrics.normal > li > .toggle h5 {
    font-size: 23px;
  }
}
@media screen and (max-width: 600px) {
  .rubrics.normal > li > .toggle h5 {
    font-size: 21px;
  }
}
.rubrics.normal > li > .rubric > div {
  padding: 40px 80px 40px 40px;
  background: #faf9f6;
}
@media screen and (max-width: 1024px) {
  .rubrics.normal > li > .rubric > div {
    padding: 50px 70px 20px 30px;
  }
}
@media screen and (max-width: 768px) {
  .rubrics.normal > li > .rubric > div {
    padding: 50px 70px 20px 20px;
  }
}
@media screen and (max-width: 600px) {
  .rubrics.normal > li > .rubric > div {
    padding: 50px 50px 20px 20px;
  }
}
@media screen and (max-width: 500px) {
  .rubrics.normal > li > .rubric > div {
    padding: 40px 40px 20px 20px;
  }
}
@media screen and (max-width: 400px) {
  .rubrics.normal > li > .rubric > div {
    padding: 30px 30px 20px 20px;
  }
}

.calendar > div {
  position: relative;
  padding: 0 30px;
  max-width: 400px;
  background: #efeae0;
}
@media screen and (max-width: 1024px) {
  .calendar > div {
    margin: 0 auto;
  }
}
.calendar > div:before {
  content: "";
  position: absolute;
  height: 75px;
  top: 0;
  left: 0;
  right: 0;
  background: #7aa77f;
}

.datepicker {
  width: 300px;
  padding-bottom: 30px;
  border-radius: 4px;
  direction: ltr;
}
@media screen and (max-width: 1200px) {
  .datepicker {
    width: 250px;
  }
}
@media screen and (max-width: 400px) {
  .datepicker {
    width: 220px;
  }
}
.datepicker.datepicker-inline {
  width: 100%;
}
.datepicker.datepicker-rtl {
  direction: rtl;
}
.datepicker.datepicker-rtl.dropdown-menu {
  left: auto;
}
.datepicker.datepicker-rtl table tr td span {
  float: right;
}
.datepicker .datepicker-switch {
  width: 145px;
}
.datepicker .datepicker-switch, .datepicker .prev, .datepicker .next, .datepicker tfoot tr th {
  cursor: pointer;
}
.datepicker .prev.disabled, .datepicker .next.disabled {
  visibility: hidden;
}
.datepicker .cw {
  width: 12px;
  padding: 0 2px 0 5px;
  font-size: 10px;
  vertical-align: middle;
}
.datepicker table {
  position: relative;
  z-index: 1;
  width: 300px;
  margin: 0 auto;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
@media screen and (max-width: 1200px) {
  .datepicker table {
    width: 250px;
  }
}
@media screen and (max-width: 400px) {
  .datepicker table {
    width: 220px;
  }
}
.datepicker table thead tr .prev, .datepicker table thead tr .next, .datepicker table thead tr .datepicker-switch {
  background: #7aa77f;
}
.datepicker table thead tr .prev, .datepicker table thead tr .next {
  position: relative;
  font-size: 0;
}
.datepicker table thead tr .prev:after, .datepicker table thead tr .next:after {
  content: "";
  position: absolute;
  width: 8px;
  height: 14px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 1;
  background: url("../images/content/icons/pointerWhite.svg") no-repeat center/contain;
  transition: opacity 300ms;
}
.datepicker table thead tr .prev:hover:after, .datepicker table thead tr .next:hover:after {
  opacity: 0.5;
}
.datepicker table thead tr .prev:after {
  left: 10px;
  transform: translateY(-50%) rotate(180deg);
}
.datepicker table thead tr .next:after {
  right: 10px;
}
.datepicker table thead tr .datepicker-switch {
  padding: 30px 0 30px 0;
  font: 15px/1 "hinted-Geomanist-Book", sans-serif;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: default;
}
.datepicker table thead tr .dow {
  padding-top: 40px;
  padding-bottom: 15px;
  font: 12px/1 "hinted-Geomanist-Medium", sans-serif;
}
.datepicker table tbody tr td {
  padding: 10px 0;
}
.datepicker table tr td, .datepicker table tr th {
  width: 30px;
  text-align: center;
  border: none;
  font: 12px/1 "hinted-Geomanist-Book", sans-serif;
  color: #3e3e2d;
}
.datepicker table tr td {
  /*&.day:hover, &.focused {
      background: #eeeeee;
      cursor: pointer;
  }*/
  /*&:hover {
      color: white;
      background-color: rgba($color1,0.6);
  }*/
  /*&:active:hover, &.active:hover, &:active:focus, &.active:focus, &:active.focus, &.active.focus {
      color: white;
      background-color: #ffbc42;
  }

  &.focused { background: #ffc966; }

  &.disabled, &.disabled:active {
      background: #ffdb99;
      color: #777777;
  }*/
  /*&.active {
      &, &.highlighted {
          color: #fff;
          background-color: #337ab7;
          border-color: #2e6da4;
          text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
      }

      &:focus, &.highlighted:focus, &.focus, &.highlighted.focus {
          color: #fff;
          background-color: #286090;
          border-color: #122b40;
      }

      &:hover, &.highlighted:hover {
          color: #fff;
          background-color: #286090;
          border-color: #204d74;
      }

      &:active, &.highlighted:active, &.active, &.highlighted.active {
          color: #fff;
          background-color: #286090;
          border-color: #204d74;
      }

      &:active:hover, &.highlighted:active:hover, &.active:hover, &.highlighted.active:hover, &:active:focus, &.highlighted:active:focus, &.active:focus, &.highlighted.active:focus, &:active.focus, &.highlighted:active.focus, &.active.focus, &.highlighted.active.focus {
          color: #fff;
          background-color: #204d74;
          border-color: #122b40;
      }
  }*/
}
.datepicker table tr td.disabled, .datepicker table tr td.disabled:hover {
  background: none;
  color: #3e3e2d;
  cursor: default;
}
.datepicker table tr td:not(.disabled) {
  position: relative;
  cursor: pointer;
  transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.8s;
}
.datepicker table tr td:not(.disabled)::after {
  content: "";
  height: 6px;
  width: 6px;
  border-radius: 6px;
  background-color: #7aa77f;
  position: absolute;
  top: 5px;
  right: 5px;
}
.datepicker table tr td:not(.disabled):hover {
  background-color: #7aa77f;
  color: white;
}
.datepicker table tr td:not(.disabled):hover::after {
  transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.8s;
  background-color: white;
}
.datepicker table tr td.highlighted {
  color: #3e3e2d;
  border-radius: 0;
  background: #7aa77f;
  cursor: pointer;
}
.datepicker table tr td.highlighted:focus, .datepicker table tr td.highlighted.focus {
  color: #fff;
  background-color: #7aa77f;
}
.datepicker table tr td.highlighted:hover {
  color: #fff;
  background-color: #7aa77f;
}
.datepicker table tr td.highlighted:active, .datepicker table tr td.highlighted.active {
  color: #fff;
  background-color: #7aa77f;
}
.datepicker table tr td.highlighted:active:hover, .datepicker table tr td.highlighted.active:hover, .datepicker table tr td.highlighted:active:focus, .datepicker table tr td.highlighted.active:focus, .datepicker table tr td.highlighted:active.focus, .datepicker table tr td.highlighted.active.focus {
  color: #fff;
  background-color: #7aa77f;
}
.datepicker table tr td.highlighted.focused {
  color: #fff;
  background-color: #7aa77f;
}
.datepicker table tr td.highlighted.disabled, .datepicker table tr td.highlighted.disabled:active {
  background: #d9edf7;
  color: #777777;
}
.datepicker table tr td.today {
  color: white;
  background-color: #e4b787;
}
.datepicker table tr td.today.disabled:hover {
  color: white;
  background-color: #e4b787 !important;
}
.datepicker table tr td:focus, .datepicker table tr td.focus {
  color: white;
  background-color: rgba(122, 167, 127, 0.6);
}
.datepicker table tr td.active {
  color: white;
  background-color: #7aa77f;
}
.datepicker table tr td.active::after {
  background-color: white;
}
.datepicker table tr td.old, .datepicker table tr td.new {
  color: #777777;
}
.datepicker table tr td.old:hover, .datepicker table tr td.new:hover {
  color: #777777;
}
.datepicker table tr td.old:not(.disabled):hover, .datepicker table tr td.new:not(.disabled):hover {
  color: white;
}
.datepicker table tr td.range {
  color: #000;
  background-color: #eeeeee;
  border-color: #bbbbbb;
  border-radius: 0;
}
.datepicker table tr td.range:focus, .datepicker table tr td.range.focus {
  color: #000;
  background-color: #d5d5d5;
  border-color: #7c7c7c;
}
.datepicker table tr td.range:hover {
  color: #000;
  background-color: #d5d5d5;
  border-color: #9d9d9d;
}
.datepicker table tr td.range:active, .datepicker table tr td.range.active {
  color: #000;
  background-color: #d5d5d5;
  border-color: #9d9d9d;
}
.datepicker table tr td.range:active:hover, .datepicker table tr td.range.active:hover, .datepicker table tr td.range:active:focus, .datepicker table tr td.range.active:focus, .datepicker table tr td.range:active.focus, .datepicker table tr td.range.active.focus {
  color: #000;
  background-color: #c3c3c3;
  border-color: #7c7c7c;
}
.datepicker table tr td.range.focused {
  background: #d5d5d5;
}
.datepicker table tr td.range.disabled, .datepicker table tr td.range.disabled:active {
  background: #eeeeee;
  color: #777777;
}
.datepicker table tr td .range.highlighted {
  color: #000;
  background-color: #e4eef3;
  border-color: #9dc1d3;
}
.datepicker table tr td .range.highlighted:focus, .datepicker table tr td .range.highlighted.focus {
  color: #000;
  background-color: #c1d7e3;
  border-color: #4b88a6;
}
.datepicker table tr td .range.highlighted:hover {
  color: #000;
  background-color: #c1d7e3;
  border-color: #73a6c0;
}
.datepicker table tr td .range.highlighted:active, .datepicker table tr td .range.highlighted.active {
  color: #000;
  background-color: #c1d7e3;
  border-color: #73a6c0;
}
.datepicker table tr td .range.highlighted:active:hover, .datepicker table tr td .range.highlighted.active:hover, .datepicker table tr td .range.highlighted:active:focus, .datepicker table tr td .range.highlighted.active:focus, .datepicker table tr td .range.highlighted:active.focus, .datepicker table tr td .range.highlighted.active.focus {
  color: #000;
  background-color: #a8c8d8;
  border-color: #4b88a6;
}
.datepicker table tr td .range.highlighted.focused {
  background: #c1d7e3;
}
.datepicker table tr td .range.highlighted.disabled, .datepicker table tr td .range.highlighted.disabled:active {
  background: #e4eef3;
  color: #777777;
}
.datepicker table tr td .range.today {
  color: #000;
  background-color: #f7ca77;
  border-color: #f1a417;
}
.datepicker table tr td .range.today:focus, .datepicker table tr td .range.today.focus {
  color: #000;
  background-color: #f4b747;
  border-color: #815608;
}
.datepicker table tr td .range.today:hover {
  color: #000;
  background-color: #f4b747;
  border-color: #bf800c;
}
.datepicker table tr td .range.today:active, .datepicker table tr td .range.today.active {
  color: #000;
  background-color: #f4b747;
  border-color: #bf800c;
}
.datepicker table tr td .range.today:active:hover, .datepicker table tr td .range.today.active:hover, .datepicker table tr td .range.today:active:focus, .datepicker table tr td .range.today.active:focus, .datepicker table tr td .range.today:active.focus, .datepicker table tr td .range.today.active.focus {
  color: #000;
  background-color: #f2aa25;
  border-color: #815608;
}
.datepicker table tr td .range.today.disabled, .datepicker table tr td .range.today.disabled:active {
  background: #f7ca77;
  color: #777777;
}
.datepicker table tr td.selected, .datepicker table tr td.selected.highlighted {
  color: #fff;
  background-color: #777777;
  border-color: #555555;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.datepicker table tr td.selected:focus, .datepicker table tr td.selected.highlighted:focus, .datepicker table tr td.selected.focus, .datepicker table tr td.selected.highlighted.focus {
  color: #fff;
  background-color: #5e5e5e;
  border-color: #161616;
}
.datepicker table tr td.selected:hover, .datepicker table tr td.selected.highlighted:hover {
  color: #fff;
  background-color: #5e5e5e;
  border-color: #373737;
}
.datepicker table tr td.selected:active, .datepicker table tr td.selected.highlighted:active, .datepicker table tr td.selected.active, .datepicker table tr td.selected.highlighted.active {
  color: #fff;
  background-color: #5e5e5e;
  border-color: #373737;
}
.datepicker table tr td.selected:active:hover, .datepicker table tr td.selected.highlighted:active:hover, .datepicker table tr td.selected.active:hover, .datepicker table tr td.selected.highlighted.active:hover, .datepicker table tr td.selected:active:focus, .datepicker table tr td.selected.highlighted:active:focus, .datepicker table tr td.selected.active:focus, .datepicker table tr td.selected.highlighted.active:focus, .datepicker table tr td.selected:active.focus, .datepicker table tr td.selected.highlighted:active.focus, .datepicker table tr td.selected.active.focus, .datepicker table tr td.selected.highlighted.active.focus {
  color: #fff;
  background-color: #4c4c4c;
  border-color: #161616;
}
.datepicker table tr td span {
  display: block;
  width: 23%;
  height: 54px;
  line-height: 54px;
  float: left;
  margin: 1%;
  cursor: pointer;
  border-radius: 4px;
}
.datepicker table tr td span:hover, .datepicker table tr td span.focused {
  background: #eeeeee;
}
.datepicker table tr td span.disabled, .datepicker table tr td span.disabled:hover {
  background: none;
  color: #777777;
  cursor: default;
}
.datepicker table tr td span.active, .datepicker table tr td span.active:hover, .datepicker table tr td span.active.disabled, .datepicker table tr td span.active.disabled:hover {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.datepicker table tr td span.active:focus, .datepicker table tr td span.active:hover:focus, .datepicker table tr td span.active.disabled:focus, .datepicker table tr td span.active.disabled:hover:focus, .datepicker table tr td span.active.focus, .datepicker table tr td span.active:hover.focus, .datepicker table tr td span.active.disabled.focus, .datepicker table tr td span.active.disabled:hover.focus {
  color: #fff;
  background-color: #286090;
  border-color: #122b40;
}
.datepicker table tr td span.active:hover, .datepicker table tr td span.active:hover:hover, .datepicker table tr td span.active.disabled:hover, .datepicker table tr td span.active.disabled:hover:hover {
  color: #fff;
  background-color: #286090;
  border-color: #204d74;
}
.datepicker table tr td span.active:active, .datepicker table tr td span.active:hover:active, .datepicker table tr td span.active.disabled:active, .datepicker table tr td span.active.disabled:hover:active, .datepicker table tr td span.active.active, .datepicker table tr td span.active:hover.active, .datepicker table tr td span.active.disabled.active, .datepicker table tr td span.active.disabled:hover.active {
  color: #fff;
  background-color: #286090;
  border-color: #204d74;
}
.datepicker table tr td span.active:active:hover, .datepicker table tr td span.active:hover:active:hover, .datepicker table tr td span.active.disabled:active:hover, .datepicker table tr td span.active.disabled:hover:active:hover, .datepicker table tr td span.active.active:hover, .datepicker table tr td span.active:hover.active:hover, .datepicker table tr td span.active.disabled.active:hover, .datepicker table tr td span.active.disabled:hover.active:hover, .datepicker table tr td span.active:active:focus, .datepicker table tr td span.active:hover:active:focus, .datepicker table tr td span.active.disabled:active:focus, .datepicker table tr td span.active.disabled:hover:active:focus, .datepicker table tr td span.active.active:focus, .datepicker table tr td span.active:hover.active:focus, .datepicker table tr td span.active.disabled.active:focus, .datepicker table tr td span.active.disabled:hover.active:focus, .datepicker table tr td span.active:active.focus, .datepicker table tr td span.active:hover:active.focus, .datepicker table tr td span.active.disabled:active.focus, .datepicker table tr td span.active.disabled:hover:active.focus, .datepicker table tr td span.active.active.focus, .datepicker table tr td span.active:hover.active.focus, .datepicker table tr td span.active.disabled.active.focus, .datepicker table tr td span.active.disabled:hover.active.focus {
  color: #fff;
  background-color: #204d74;
  border-color: #122b40;
}
.datepicker table tr td span.old, .datepicker table tr td span.new {
  color: #777777;
}

.datepicker-dropdown {
  padding: 4px;
  top: 0;
  left: 0;
}
.datepicker-dropdown:before {
  content: "";
  display: inline-block;
  position: absolute;
  border-top: 0;
  border-right: 7px solid transparent;
  border-bottom: 7px solid rgba(0, 0, 0, 0.2);
  border-left: 7px solid transparent;
}
.datepicker-dropdown:after {
  content: "";
  position: absolute;
  display: inline-block;
  border-top: 0;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-left: 6px solid transparent;
}
.datepicker-dropdown.datepicker-orient-left:before {
  left: 6px;
}
.datepicker-dropdown.datepicker-orient-left:after {
  left: 7px;
}
.datepicker-dropdown.datepicker-orient-right:before {
  right: 6px;
}
.datepicker-dropdown.datepicker-orient-right:after {
  right: 7px;
}
.datepicker-dropdown.datepicker-orient-bottom:before {
  top: -7px;
}
.datepicker-dropdown.datepicker-orient-bottom:after {
  top: -6px;
}
.datepicker-dropdown.datepicker-orient-top:before {
  bottom: -7px;
  border-bottom: 0;
  border-top: 7px solid rgba(0, 0, 0, 0.15);
}
.datepicker-dropdown.datepicker-orient-top:after {
  bottom: -6px;
  border-bottom: 0;
  border-top: 6px solid #fff;
}

.table-striped .datepicker table tr td, .table-striped .datepicker table tr th {
  background-color: transparent;
}

.datepicker table tr td.highlighted.disabled:hover, .datepicker table tr td.highlighted[disabled]:hover,
fieldset[disabled] .datepicker table tr td.highlighted:hover,
.datepicker table tr td.highlighted.disabled:focus, .datepicker table tr td.highlighted[disabled]:focus,
fieldset[disabled] .datepicker table tr td.highlighted:focus,
.datepicker table tr td.highlighted.disabled.focus, .datepicker table tr td.highlighted[disabled].focus,
fieldset[disabled] .datepicker table tr td.highlighted.focus {
  background-color: #d9edf7;
  border-color: #85c5e5;
}

.datepicker table tr td.today.disabled:hover, .datepicker table tr td.today[disabled]:hover,
fieldset[disabled] .datepicker table tr td.today:hover,
.datepicker table tr td.today.disabled:focus, .datepicker table tr td.today[disabled]:focus,
fieldset[disabled] .datepicker table tr td.today:focus,
.datepicker table tr td.today.disabled.focus, .datepicker table tr td.today[disabled].focus,
fieldset[disabled] .datepicker table tr td.today.focus {
  background-color: #ffdb99;
}

.datepicker table tr td.range.disabled:hover, .datepicker table tr td.range[disabled]:hover,
fieldset[disabled] .datepicker table tr td.range:hover,
.datepicker table tr td.range.disabled:focus, .datepicker table tr td.range[disabled]:focus,
fieldset[disabled] .datepicker table tr td.range:focus,
.datepicker table tr td.range.disabled.focus, .datepicker table tr td.range[disabled].focus,
fieldset[disabled] .datepicker table tr td.range.focus {
  background-color: #eeeeee;
  border-color: #bbbbbb;
}

.datepicker table tr td.range.highlighted.disabled:hover, .datepicker table tr td.range.highlighted[disabled]:hover,
fieldset[disabled] .datepicker table tr td.range.highlighted:hover,
.datepicker table tr td.range.highlighted.disabled:focus, .datepicker table tr td.range.highlighted[disabled]:focus,
fieldset[disabled] .datepicker table tr td.range.highlighted:focus,
.datepicker table tr td.range.highlighted.disabled.focus, .datepicker table tr td.range.highlighted[disabled].focus,
fieldset[disabled] .datepicker table tr td.range.highlighted.focus {
  background-color: #e4eef3;
  border-color: #9dc1d3;
}

.datepicker table tr td.range.today.disabled:hover, .datepicker table tr td.range.today[disabled]:hover,
fieldset[disabled] .datepicker table tr td.range.today:hover,
.datepicker table tr td.range.today.disabled:focus, .datepicker table tr td.range.today[disabled]:focus,
fieldset[disabled] .datepicker table tr td.range.today:focus,
.datepicker table tr td.range.today.disabled.focus, .datepicker table tr td.range.today[disabled].focus,
fieldset[disabled] .datepicker table tr td.range.today.focus {
  background-color: #f7ca77;
  border-color: #f1a417;
}

.datepicker table tr td.selected.disabled:hover, .datepicker table tr td.selected.highlighted.disabled:hover,
.datepicker table tr td.selected[disabled]:hover, .datepicker table tr td.selected.highlighted[disabled]:hover,
fieldset[disabled] .datepicker table tr td.selected:hover, fieldset[disabled] .datepicker table tr td.selected.highlighted:hover,
.datepicker table tr td.selected.disabled:focus, .datepicker table tr td.selected.highlighted.disabled:focus,
.datepicker table tr td.selected[disabled]:focus, .datepicker table tr td.selected.highlighted[disabled]:focus,
fieldset[disabled] .datepicker table tr td.selected:focus, fieldset[disabled] .datepicker table tr td.selected.highlighted:focus,
.datepicker table tr td.selected.disabled.focus, .datepicker table tr td.selected.highlighted.disabled.focus,
.datepicker table tr td.selected[disabled].focus, .datepicker table tr td.selected.highlighted[disabled].focus,
fieldset[disabled] .datepicker table tr td.selected.focus, fieldset[disabled] .datepicker table tr td.selected.highlighted.focus {
  background-color: #777777;
  border-color: #555555;
}

.datepicker table tr td.active.disabled:hover, .datepicker table tr td.active.highlighted.disabled:hover,
.datepicker table tr td.active[disabled]:hover, .datepicker table tr td.active.highlighted[disabled]:hover,
fieldset[disabled] .datepicker table tr td.active:hover, fieldset[disabled] .datepicker table tr td.active.highlighted:hover,
.datepicker table tr td.active.disabled:focus, .datepicker table tr td.active.highlighted.disabled:focus,
.datepicker table tr td.active[disabled]:focus, .datepicker table tr td.active.highlighted[disabled]:focus,
fieldset[disabled] .datepicker table tr td.active:focus, fieldset[disabled] .datepicker table tr td.active.highlighted:focus,
.datepicker table tr td.active.disabled.focus, .datepicker table tr td.active.highlighted.disabled.focus,
.datepicker table tr td.active[disabled].focus, .datepicker table tr td.active.highlighted[disabled].focus,
fieldset[disabled] .datepicker table tr td.active.focus, fieldset[disabled] .datepicker table tr td.active.highlighted.focus {
  background-color: #337ab7;
  border-color: #2e6da4;
}

.datepicker table tr td span.active.disabled:hover, .datepicker table tr td span.active:hover.disabled:hover, .datepicker table tr td span.active.disabled.disabled:hover, .datepicker table tr td span.active.disabled:hover.disabled:hover,
.datepicker table tr td span.active[disabled]:hover, .datepicker table tr td span.active:hover[disabled]:hover, .datepicker table tr td span.active.disabled[disabled]:hover, .datepicker table tr td span.active.disabled:hover[disabled]:hover,
fieldset[disabled] .datepicker table tr td span.active:hover, fieldset[disabled] .datepicker table tr td span.active:hover:hover, fieldset[disabled] .datepicker table tr td span.active.disabled:hover, fieldset[disabled] .datepicker table tr td span.active.disabled:hover:hover,
.datepicker table tr td span.active.disabled:focus, .datepicker table tr td span.active:hover.disabled:focus, .datepicker table tr td span.active.disabled.disabled:focus, .datepicker table tr td span.active.disabled:hover.disabled:focus,
.datepicker table tr td span.active[disabled]:focus, .datepicker table tr td span.active:hover[disabled]:focus, .datepicker table tr td span.active.disabled[disabled]:focus, .datepicker table tr td span.active.disabled:hover[disabled]:focus,
fieldset[disabled] .datepicker table tr td span.active:focus, fieldset[disabled] .datepicker table tr td span.active:hover:focus, fieldset[disabled] .datepicker table tr td span.active.disabled:focus, fieldset[disabled] .datepicker table tr td span.active.disabled:hover:focus,
.datepicker table tr td span.active.disabled.focus, .datepicker table tr td span.active:hover.disabled.focus, .datepicker table tr td span.active.disabled.disabled.focus, .datepicker table tr td span.active.disabled:hover.disabled.focus,
.datepicker table tr td span.active[disabled].focus, .datepicker table tr td span.active:hover[disabled].focus, .datepicker table tr td span.active.disabled[disabled].focus, .datepicker table tr td span.active.disabled:hover[disabled].focus,
fieldset[disabled] .datepicker table tr td span.active.focus, fieldset[disabled] .datepicker table tr td span.active:hover.focus, fieldset[disabled] .datepicker table tr td span.active.disabled.focus, fieldset[disabled] .datepicker table tr td span.active.disabled:hover.focus {
  background-color: #337ab7;
  border-color: #2e6da4;
}

.input-group.date .input-group-addon {
  cursor: pointer;
}

.input-daterange {
  width: 100%;
}

.input-daterange input {
  text-align: center;
}

.input-daterange input:first-child {
  border-radius: 3px 0 0 3px;
}

.input-daterange input:last-child {
  border-radius: 0 3px 3px 0;
}

.input-daterange .input-group-addon {
  width: auto;
  min-width: 16px;
  padding: 4px 5px;
  line-height: 1.42857143;
  border-width: 1px 0;
  margin-left: -5px;
  margin-right: -5px;
}

.formBlock > div h3 {
  margin-bottom: 60px;
}
.formBlock > div .input-field input,
.formBlock > div .input-field textarea,
.formBlock > div .text-field input,
.formBlock > div .text-field textarea {
  background: white;
}

#formulaireCalculateurTaxe input {
  background: white;
  padding: 10px 15px;
  border: 2px solid #c0cfb9;
  font: 18px/1 "hinted-Geomanist-Regular", sans-serif;
  color: #3e3e2d;
  transition: color 300ms ease, background 300ms ease, border 300ms ease;
  -webkit-appearance: none;
}
#formulaireCalculateurTaxe input.evaluation {
  width: 140px;
}
#formulaireCalculateurTaxe input:disabled {
  background: transparent;
  border: 0;
}

.cc_div * {
  font-style: revert !important;
  font-family: "hinted-Geomanist-Regular", sans-serif !important;
}

#cm {
  max-width: 40em !important;
  border-radius: unset !important;
  padding: 1.7em !important;
}
#cm #c-inr {
  display: flex;
  flex-direction: column;
}
#cm #c-inr #c-inr-i {
  width: 100%;
  padding-right: unset;
  vertical-align: unset;
  color: #3e3e2d;
}
#cm #c-inr #c-inr-i #c-txt h2 {
  font-weight: 700;
  font-size: 28px;
}
#cm #c-inr #c-inr-i #c-txt h3 {
  font-weight: 600;
  font-size: 26px;
}
#cm #c-inr #c-inr-i #c-txt h4 {
  font-weight: 500;
  font-size: 24px;
}
#cm #c-inr #c-inr-i #c-txt strong {
  font-weight: bold;
}
#cm #c-inr #c-inr-i #c-txt sup {
  vertical-align: super;
  font-size: smaller;
}
#cm #c-inr #c-inr-i #c-txt sub {
  vertical-align: sub;
  font-size: smaller;
}
#cm #c-inr #c-inr-i #c-txt a {
  display: inline;
  border-bottom: unset;
  box-shadow: inset 0 -0.1em 0 0 rgba(122, 167, 127, 0.5);
  transition: color 300ms;
}
#cm #c-inr #c-inr-i #c-txt a:hover {
  color: #7aa77f;
}
#cm #c-inr #c-bns {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
@media screen and (max-width: 575px) {
  #cm #c-inr #c-bns {
    flex-direction: column;
  }
}
#cm #c-inr #c-bns .c-bn {
  margin: unset;
  position: relative;
  height: 50px;
  font: 12px/40px "hinted-Geomanist-Book", sans-serif;
  font-weight: 700;
  color: #3e3e2d;
  padding: 0 40px;
  border: 1px solid #7aa77f;
  border-radius: 25px;
  letter-spacing: 1px;
  transition: all 300ms !important;
}
#cm #c-inr #c-bns .c-bn:hover {
  color: #fff;
  background: #7aa77f;
  border-color: #7aa77f;
}
#cm #c-inr #c-bns .c-bn span {
  text-transform: uppercase;
}
#cm #c-inr #c-bns #c-s-bn {
  color: #000;
  border: 1px solid #fff;
}
#cm #c-inr #c-bns #c-s-bn:hover {
  color: #3e3e2d;
  background: #fff;
  border: 1px solid #000;
}
#cm #c-inr #c-bns #c-p-bn, #cm #c-inr #c-bns #c-t-bn {
  color: #fff;
  border: 1px solid #7aa77f;
  background: #7aa77f;
}
#cm #c-inr #c-bns #c-p-bn:hover, #cm #c-inr #c-bns #c-t-bn:hover {
  color: #7aa77f;
  background: transparent;
}

#s-inr #s-hdr {
  color: #3e3e2d;
}
#s-inr #s-hdr #s-c-bnc #s-c-bn {
  background: unset;
  border-radius: 100%;
  border: 2px solid #7aa77f;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 300ms !important;
}
#s-inr #s-hdr #s-c-bnc #s-c-bn::before, #s-inr #s-hdr #s-c-bnc #s-c-bn::after {
  background: #7aa77f;
  left: unset;
  top: unset;
}
#s-inr #s-hdr #s-c-bnc #s-c-bn:hover {
  opacity: 0.7;
}
#s-inr #s-bl {
  padding-bottom: 4em;
}
@media screen and (max-width: 688px) {
  #s-inr #s-bl {
    padding-bottom: unset;
  }
}
@media screen and (max-width: 575px) {
  #s-inr #s-bl {
    padding-bottom: 7em;
  }
}
#s-inr #s-bl .c-bl {
  margin-bottom: 0.5em;
}
#s-inr #s-bl .c-bl .title .b-tl {
  color: #3e3e2d;
}
#s-inr #s-bl .c-bl .desc .p {
  color: #3e3e2d;
}
#s-inr #s-bl .c-bl .desc .p h2 {
  font-weight: 700;
  font-size: 28px;
}
#s-inr #s-bl .c-bl .desc .p h3 {
  font-weight: 600;
  font-size: 26px;
}
#s-inr #s-bl .c-bl .desc .p h4 {
  font-weight: 500;
  font-size: 24px;
}
#s-inr #s-bl .c-bl .desc .p strong {
  font-weight: bold;
}
#s-inr #s-bl .c-bl .desc .p sup {
  vertical-align: super;
  font-size: smaller;
}
#s-inr #s-bl .c-bl .desc .p sub {
  vertical-align: sub;
  font-size: smaller;
}
#s-inr #s-bl .c-bl .desc .p a {
  display: inline;
  border-bottom: unset;
  box-shadow: inset 0 -0.1em 0 0 rgba(122, 167, 127, 0.5);
  transition: color 300ms;
}
#s-inr #s-bl .c-bl .desc .p a:hover {
  color: #7aa77f;
}
#s-inr #s-bl .b-ex {
  color: white;
  background: #7aa77f;
  border-radius: unset;
}
#s-inr #s-bl .b-ex .b-bn .b-tl {
  color: white;
  background: #7aa77f;
  background-color: #7aa77f;
  border-radius: unset;
  transition: opacity 300ms;
}
#s-inr #s-bl .b-ex .b-bn .b-tl::before {
  border: solid white;
  border-width: 0 2px 2px 0;
}
#s-inr #s-bl .b-ex .b-bn .b-tl:hover {
  opacity: 0.8;
}
#s-inr #s-bl .b-ex .b-bn .b-tg.c-ro {
  right: 1.1em;
}
#s-inr #s-bl .b-ex .b-bn .b-tg .c-tg {
  background: rgba(239, 234, 224, 0.5);
}
#s-inr #s-bl .b-ex .b-bn .b-tg .c-tgl:checked ~ .c-tg {
  background: rgb(80, 187, 92);
}
#s-inr #s-bl .b-ex .b-acc {
  border-radius: unset;
  background-color: #eeeeee;
}
#s-inr #s-bl .b-ex .b-acc .p {
  color: #3e3e2d;
}
#s-inr #s-bl .b-ex .b-acc .p h2 {
  font-weight: 700;
  font-size: 28px;
}
#s-inr #s-bl .b-ex .b-acc .p h3 {
  font-weight: 600;
  font-size: 26px;
}
#s-inr #s-bl .b-ex .b-acc .p h4 {
  font-weight: 500;
  font-size: 24px;
}
#s-inr #s-bl .b-ex .b-acc .p strong {
  font-weight: bold;
}
#s-inr #s-bl .b-ex .b-acc .p sup {
  vertical-align: super;
  font-size: smaller;
}
#s-inr #s-bl .b-ex .b-acc .p sub {
  vertical-align: sub;
  font-size: smaller;
}
#s-inr #s-bl .b-ex .b-acc .p a {
  display: inline;
  border-bottom: unset;
  box-shadow: inset 0 -0.1em 0 0 rgba(122, 167, 127, 0.5);
  transition: color 300ms;
}
#s-inr #s-bl .b-ex .b-acc .p a:hover {
  color: #7aa77f;
}
#s-inr #s-bl .b-ex .b-acc .b-tb {
  color: #3e3e2d;
}
#s-inr #s-bl .b-ex .b-acc .b-tb::before {
  border: solid #7aa77f;
  border-width: 0 2px 2px 0;
}
#s-inr #s-bl .b-ex .b-acc .tb-c {
  transition: font-size 0.12s ease, margin 0.12s ease, opacity 0.1s 0.15s ease;
}
#s-inr #s-bl .b-ex .b-acc .tb-c tbody {
  color: #3e3e2d;
}
#s-inr #s-bl .b-ex .b-acc .tb-c tbody tr:hover {
  background-color: #eeeeee;
}
#s-inr #s-bl .b-ex .b-acc .tb-c tbody tr td h2 {
  font-weight: 700;
  font-size: 28px;
}
#s-inr #s-bl .b-ex .b-acc .tb-c tbody tr td h3 {
  font-weight: 600;
  font-size: 26px;
}
#s-inr #s-bl .b-ex .b-acc .tb-c tbody tr td h4 {
  font-weight: 500;
  font-size: 24px;
}
#s-inr #s-bl .b-ex .b-acc .tb-c tbody tr td strong {
  font-weight: bold;
}
#s-inr #s-bl .b-ex .b-acc .tb-c tbody tr td sup {
  vertical-align: super;
  font-size: smaller;
}
#s-inr #s-bl .b-ex .b-acc .tb-c tbody tr td sub {
  vertical-align: sub;
  font-size: smaller;
}
#s-inr #s-bl .b-ex .b-acc .tb-c tbody tr td a {
  display: inline;
  border-bottom: unset;
  box-shadow: inset 0 -0.1em 0 0 rgba(122, 167, 127, 0.5);
  transition: color 300ms;
}
#s-inr #s-bl .b-ex .b-acc .tb-c tbody tr td a:hover {
  color: #7aa77f;
}
#s-inr #s-bl .b-ex .b-acc .tb-c tbody tr td::before {
  color: #3e3e2d;
}
#s-inr #s-bl .b-ex .b-acc.act .tb-c {
  background-color: #eeeeee;
  font-size: 0;
  opacity: 0;
  margin: 0;
  transition: opacity 0.1s ease, font-size 0.15s 0.15s ease, margin 0.15s 0.15s ease;
}
#s-inr #s-bl .b-ex .b-acc.act .tb-c tbody tr {
  margin-bottom: 0;
  transition: margin 0.3s 0.2s;
}
#s-inr #s-bl .b-ex > .b-acc {
  display: block;
  font-size: 0;
  opacity: 0;
  margin: 0;
  max-height: unset;
  transition: opacity 0.2s ease, font-size 0.3s 0.2s ease, margin 0.3s 0.2s ease;
}
#s-inr #s-bl .b-ex > .b-acc .b-tb {
  padding: 0 24px;
  transition: padding 0.3s 0.2s ease;
}
#s-inr #s-bl .b-ex > .b-acc .b-tb::before {
  border-width: 0;
  transition: border-width 0.3s 0.2s ease;
}
#s-inr #s-bl .b-ex > .b-acc tr {
  margin-bottom: 0;
  border-top: unset;
  transition: margin 0.3s 0.2s ease;
}
#s-inr #s-bl .b-ex.act > .b-acc {
  font-size: 15px;
  opacity: 1;
  margin: unset;
  transition: margin 0.3s ease, font-size 0.3s ease, opacity 0.2s 0.35s ease;
}
#s-inr #s-bl .b-ex.act > .b-acc .b-tb {
  padding: 10px 24px;
  transition: padding 0.3s ease;
}
#s-inr #s-bl .b-ex.act > .b-acc .b-tb::before {
  border-width: 0 2px 2px 0;
  transition: border-width 0.3s ease;
}
#s-inr #s-bl .b-ex.act > .b-acc tr {
  margin-bottom: 15px;
  border-top: 1px solid rgba(122, 167, 127, 0.6);
  transition: margin 0.3s ease;
}
#s-inr #s-bns {
  display: flex !important;
  flex-direction: row-reverse;
  height: -moz-fit-content;
  height: fit-content;
  gap: 10px;
}
@media screen and (max-width: 575px) {
  #s-inr #s-bns {
    flex-direction: column;
  }
}
#s-inr #s-bns .c-bn {
  margin: unset;
  position: relative;
  text-wrap: nowrap;
  justify-content: center;
  height: 50px;
  font: 12px/40px "hinted-Geomanist-Book", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  color: #3e3e2d;
  padding: 0 20px;
  border: 1px solid #7aa77f;
  border-radius: 25px;
  letter-spacing: 1px;
  transition: all 300ms !important;
}
#s-inr #s-bns .c-bn:hover {
  color: #fff;
  background: #7aa77f;
  border-color: #7aa77f;
}
#s-inr #s-bns #s-all-bn, #s-inr #s-bns #s-rall-bn {
  color: #fff;
  border: 1px solid #7aa77f;
  background: #7aa77f;
}
#s-inr #s-bns #s-all-bn:hover, #s-inr #s-bns #s-rall-bn:hover {
  color: #7aa77f;
  background: transparent;
}
#s-inr #s-bns #s-sv-bn {
  color: #000;
  border: 1px solid #fff;
}
#s-inr #s-bns #s-sv-bn:hover {
  color: #3e3e2d;
  background: #fff;
  border: 1px solid #000;
}

.placeholder_auto_div {
  background-color: #dcdcdc;
  padding: 20px;
}
.placeholder_auto_div .placeholder-txt {
  color: #3e3e2d;
  font-size: 17px;
  line-height: 1.25em;
  text-align: start;
  font-family: "hinted-Geomanist-Regular", sans-serif;
}
.placeholder_auto_div .placeholder-txt .linkStyle {
  font-size: 17px !important;
  font-weight: 500;
  box-shadow: inset 0 -0.1em 0 0 rgba(122, 167, 127, 0.5);
  padding-bottom: unset !important;
  text-decoration: none;
  transition: color 300ms ease;
}
.placeholder_auto_div .placeholder-txt .linkStyle:hover {
  color: #7aa77f;
}

.c-button-cookies {
  position: fixed;
  bottom: 10px;
  left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background-color: rgb(255, 255, 255);
  border-radius: 50%;
  border: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  transition: all 300ms;
  z-index: 109;
}
.c-button-cookies:hover {
  background-color: #7aa77f;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.35);
}
.c-button-cookies__svg {
  width: 20px;
  height: 20px;
  fill: #7aa77f;
  transition: fill 300ms;
}
.c-button-cookies:hover .c-button-cookies__svg {
  fill: rgb(255, 255, 255);
  cursor: pointer;
}

[data-fadeup] {
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 800ms, transform 800ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
@media screen and (max-width: 1024px) {
  [data-fadeup] {
    transform: translateY(50px);
  }
}
@media screen and (max-width: 768px) {
  [data-fadeup] {
    transform: translateY(30px);
  }
}
[data-fadeup][data-scrollfire=fire], [data-fadeup][data-multiscrollfire=fire] {
  opacity: 1;
  transform: none;
}

.transition [data-fadeup], .chargement [data-fadeup] {
  opacity: 0 !important;
  transform: translateY(100px) !important;
}
@media screen and (max-width: 1024px) {
  .transition [data-fadeup], .chargement [data-fadeup] {
    transform: translateY(50px) !important;
  }
}
@media screen and (max-width: 768px) {
  .transition [data-fadeup], .chargement [data-fadeup] {
    transform: translateY(30px) !important;
  }
}
.transition [data-scrollfire], .transition [data-multiscrollfire], .chargement [data-scrollfire], .chargement [data-multiscrollfire] {
  transition-delay: 100ms !important;
}

.transition section:not(.bannerBlock), .transition footer {
  opacity: 0 !important;
  transform: translateY(100px) !important;
  transition: opacity 800ms, transform 800ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
@media screen and (max-width: 1024px) {
  .transition section:not(.bannerBlock), .transition footer {
    transform: translateY(50px);
  }
}
@media screen and (max-width: 768px) {
  .transition section:not(.bannerBlock), .transition footer {
    transform: translateY(30px);
  }
}

[data-scrollfire], [data-multiscrollfire] {
  transition-delay: 0ms;
}
[data-scrollfire][data-delay1], [data-multiscrollfire][data-delay1] {
  transition-delay: 300ms;
}
[data-scrollfire][data-delay2], [data-multiscrollfire][data-delay2] {
  transition-delay: 400ms;
}
[data-scrollfire][data-delay3], [data-multiscrollfire][data-delay3] {
  transition-delay: 500ms;
}
[data-scrollfire][data-delay4], [data-multiscrollfire][data-delay4] {
  transition-delay: 600ms;
}
[data-scrollfire][data-delay5], [data-multiscrollfire][data-delay5] {
  transition-delay: 700ms;
}
[data-scrollfire][data-delay6], [data-multiscrollfire][data-delay6] {
  transition-delay: 800ms;
}
[data-scrollfire][data-delay7], [data-multiscrollfire][data-delay7] {
  transition-delay: 900ms;
}
@media screen and (max-width: 768px) {
  [data-scrollfire], [data-multiscrollfire] {
    transition-delay: 0ms !important;
  }
}

#landing {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  z-index: 9999;
  transition: visibility 0ms ease 1100ms;
}

.chargement #landing {
  opacity: 1;
  visibility: visible;
}

#page-transition {
  position: fixed;
  top: 0%;
  right: 0;
  bottom: 100%;
  left: 0;
  z-index: 9999;
  background: #efeae0;
  visibility: hidden;
}

.transition #page-transition {
  top: 0%;
  bottom: 0%;
  visibility: visible;
  transition: top 1000ms cubic-bezier(0.77, 0, 0.175, 1), bottom 1000ms cubic-bezier(0.77, 0, 0.175, 1);
}

.closing #page-transition {
  top: 100%;
  bottom: 0%;
  visibility: visible;
  transition: top 1000ms cubic-bezier(0.77, 0, 0.175, 1), bottom 1000ms cubic-bezier(0.77, 0, 0.175, 1);
}

.transition header, .closing header {
  pointer-events: none;
}

form {
  display: block;
  width: 100%;
}

div.error {
  position: absolute;
  padding-top: 7px;
  top: 100%;
  font: 12px/1 "hinted-Geomanist-Regular", sans-serif;
  color: #da4a4a;
}

.input-field, .text-field, .select-field, .checkbox-field, .radiobox-field, .file-field {
  position: relative;
  width: 100%;
  margin-bottom: 25px;
}
.input-field .hook, .text-field .hook, .select-field .hook, .checkbox-field .hook, .radiobox-field .hook, .file-field .hook {
  position: absolute;
  top: 50%;
  right: 30px;
  width: 12px;
  height: 6px;
  border-bottom: 1px solid #3e3e2d;
  border-left: 1px solid #3e3e2d;
  opacity: 0;
  transform: rotate(-45deg) translateY(-50%) scale(0);
  transition: transform 300ms, opacity 300ms;
}

.chosen-container .chosen-results li.highlighted {
  color: #3e3e2d;
}

.chosen-container-single .chosen-search {
  display: none;
}

.chosen-disabled .chosen-single {
  cursor: default;
}

.chosen-disabled {
  opacity: 0.35 !important;
  cursor: default;
}

.chosen-container {
  position: relative;
  display: inline-block;
  width: 100% !important;
  font-size: 15px;
  vertical-align: middle;
  background: #fff;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  z-index: 50;
}
.chosen-container .chosen-single {
  display: block;
  position: relative;
  width: 100%;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  z-index: 49;
  transition: background 300ms, color 300ms, border 300ms;
}
.chosen-container .chosen-single span {
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.chosen-container .chosen-single div {
  display: block;
  position: absolute;
  width: 40px;
  height: 100%;
  top: 0;
  right: 8px;
  transform: rotate(90deg);
  transition: transform 500ms;
}
.chosen-container .chosen-single div b {
  display: none;
}
.chosen-container .chosen-drop {
  position: absolute;
  width: 100%;
  top: 0;
  border: none;
  background: #fff;
  opacity: 0;
  visibility: hidden;
  z-index: 48;
  transition: padding-top 500ms, opacity 500ms, box-shadow 500ms, visibility 0ms ease 500ms;
}
.chosen-container.chosen-with-drop .chosen-drop {
  top: 0;
  padding-top: 55px;
  visibility: visible;
  opacity: 1;
  transition: padding 500ms, opacity 500ms, box-shadow 500ms;
}
.chosen-container .chosen-results {
  position: relative;
  max-height: 170px;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.chosen-container .chosen-results li {
  display: none;
  padding: 15px 20px;
  margin: 0;
  font: 13px/20px "hinted-Geomanist-Regular", sans-serif;
  letter-spacing: 1px;
  text-transform: uppercase;
  list-style: none;
  word-wrap: break-word;
  -webkit-touch-callout: none;
  transition: color 300ms;
}
.chosen-container .chosen-results li:first-child {
  padding-top: 25px;
}
.chosen-container .chosen-results li:last-child {
  padding-bottom: 25px;
}
.chosen-container .chosen-results li.active-result {
  display: list-item;
  cursor: pointer;
  color: #7aa77f;
}
.chosen-container .chosen-results li.disabled-result {
  display: list-item;
  color: rgba(122, 167, 127, 0.4);
  cursor: default;
}
.chosen-container .chosen-results li.result-selected {
  font-family: "hinted-Geomanist-Regular", sans-serif;
  cursor: default;
}
.chosen-container.chosen-container-active {
  z-index: 50;
}
.chosen-container.chosen-container-active.chosen-with-drop .chosen-single div {
  transform: rotate(-90deg);
}

select {
  display: block;
  width: 100%;
  opacity: 0;
}
select::-ms-expand {
  display: none;
}
select:focus, select:active {
  outline: none !important;
  border-color: #3e3e2d;
}

.select-field.style1 {
  margin-bottom: 80px;
}
.select-field.style1 .chosen-container {
  background: transparent;
}
.select-field.style1 .chosen-container .chosen-single, .select-field.style1 .chosen-container .chosen-default {
  padding: 0 45px 0 20px;
  font: 13px/56px "hinted-Geomanist-Regular", sans-serif;
  color: #3e3e2d;
  background: #fff;
  letter-spacing: 1px;
  text-transform: uppercase;
  border-bottom: 1px solid rgba(62, 62, 45, 0);
}
.select-field.style1 .chosen-container .chosen-single div, .select-field.style1 .chosen-container .chosen-default div {
  background: url("../images/content/icons/pointer.svg") no-repeat center center;
  background-size: 10px auto;
  transition: transform 300ms;
}
.select-field.style1 .chosen-container .chosen-results li.active-result {
  color: #3e3e2d;
}
.select-field.style1 .chosen-container .chosen-results li.active-result:hover {
  color: #7aa77f;
}
.select-field.style1 .chosen-container .chosen-results li.result-selected {
  color: #7aa77f;
}
.select-field.style1 .chosen-container.chosen-with-drop .chosen-single {
  border-bottom: 1px solid rgba(62, 62, 45, 0.2);
}
.select-field.style1 select {
  display: block;
  position: relative;
  height: 56px;
  padding: 0 45px 0 20px;
  font: 13px/56px "hinted-Geomanist-Regular", sans-serif;
  color: #3e3e2d;
  opacity: 1;
  background: #fff;
  text-transform: uppercase;
  text-overflow: ellipsis;
  letter-spacing: 1px;
  white-space: nowrap;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  overflow: hidden;
  outline: none;
  border: none;
}
.select-field.style1:after {
  content: "";
  position: absolute;
  width: 40px;
  height: 56px;
  right: 8px;
  top: 0;
  background: url("../images/content/icons/pointer.svg") no-repeat center;
  background-size: 10px auto;
  z-index: 49;
  cursor: pointer;
  pointer-events: none;
  transform: rotate(90deg);
}

.file-field {
  margin-bottom: 0;
}
.file-field .error {
  opacity: 0;
  visibility: hidden;
}
.file-field input.error + label span {
  color: #da4a4a;
}
.file-field .uploadBtnWrapper {
  position: relative;
  width: 100%;
  display: inline-block;
}
.file-field .uploadBtnWrapper input[type=file] {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.file-field .uploadBtnWrapper .btnUpload {
  display: block;
  position: relative;
  width: 100%;
  padding: 25px 60px 25px 30px;
  font: 12px/1 "hinted-Geomanist-Book", sans-serif;
  color: #3e3e2d;
  border: none;
  border-bottom: 1px solid #c0cfb9;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  transition: background 300ms, color 300ms;
}
.file-field .uploadBtnWrapper .btnUpload svg {
  position: absolute;
  width: 18px;
  height: 18px;
  top: calc(50% - 9px);
  right: 30px;
}
.file-field .uploadBtnWrapper .btnUpload svg use {
  fill: #3e3e2d;
  transition: fill 300ms;
}
.file-field .uploadBtnWrapper .btnUpload:hover {
  color: #7aa77f;
}
.file-field .uploadBtnWrapper .btnUpload:hover svg {
  fill: #7aa77f;
}
.file-field .uploadBtnWrapper .btnUpload:hover svg use {
  fill: #7aa77f;
}

.searchInput input {
  width: 100%;
  height: 46px;
  padding: 0 0 0 20px;
  border: none;
  border-left: 1px solid #fff;
  font: 25px/46px "hinted-Geomanist-Regular", sans-serif;
  color: #fff;
  background: transparent;
  transition: 300ms ease;
  caret-color: white;
}
@media screen and (max-width: 1400px) {
  .searchInput input {
    font-size: 22px;
  }
}
@media screen and (max-width: 1100px) {
  .searchInput input {
    font-size: 20px;
  }
}
@media screen and (max-width: 768px) {
  .searchInput input {
    font-size: 18px;
  }
}
@media screen and (max-width: 500px) {
  .searchInput input {
    font-size: 16px;
  }
}
@media screen and (max-width: 400px) {
  .searchInput input {
    font-size: 14px;
  }
}
.searchInput label {
  position: absolute;
  top: 0;
  left: 20px;
  font: 25px/46px "hinted-Geomanist-Regular", sans-serif;
  color: #fff;
  cursor: text;
  transition: opacity 300ms;
}
@media screen and (max-width: 1400px) {
  .searchInput label {
    font-size: 22px;
  }
}
@media screen and (max-width: 1100px) {
  .searchInput label {
    font-size: 20px;
  }
}
@media screen and (max-width: 768px) {
  .searchInput label {
    font-size: 18px;
  }
}
@media screen and (max-width: 500px) {
  .searchInput label {
    font-size: 16px;
  }
}
@media screen and (max-width: 400px) {
  .searchInput label {
    font-size: 14px;
  }
}
.searchInput input.notEmpty + label,
.searchInput input:focus + label,
.searchInput input.valid + label,
.searchInput input.error + label {
  opacity: 0;
  cursor: default;
  pointer-events: none;
}

.input-field input {
  width: 100%;
  padding: 25px 30px;
  border: 2px solid #c0cfb9;
  font: 18px/1 "hinted-Geomanist-Regular", sans-serif;
  color: #3e3e2d;
  background: transparent;
  transition: color 300ms ease, background 300ms ease, border 300ms ease;
}
.input-field input.error {
  background: #da4a4a !important;
  color: #fff;
  border-color: #da4a4a;
}
.input-field label {
  position: absolute;
  top: 50%;
  left: 30px;
  font: 18px/1 "hinted-Geomanist-Regular", sans-serif;
  color: #7aa77f;
  cursor: text;
  transform: translateY(-50%);
  transition: opacity 300ms ease, color 300ms;
}
.input-field input:focus + label,
.input-field input.valid + label,
.input-field input.notEmpty + label {
  opacity: 0;
}
.input-field input.error + label {
  color: #fff;
}
.input-field input.valid + label + .hook {
  opacity: 1;
  transform: rotate(-45deg) translateY(-50%) scale(1);
}
.input-field.white input {
  background: #fff;
}

.text-field textarea {
  width: 100%;
  padding: 25px 30px;
  height: 150px;
  border: 2px solid #c0cfb9;
  font: 18px/1 "hinted-Geomanist-Regular", sans-serif;
  color: #3e3e2d;
  background: transparent;
  resize: none;
  transition: color 300ms ease, background 300ms ease, border 300ms ease;
}
.text-field textarea.error {
  background: #da4a4a;
  color: #fff;
  border-color: #da4a4a;
}
.text-field label {
  position: absolute;
  top: 25px;
  left: 30px;
  font: 18px/1 "hinted-Geomanist-Regular", sans-serif;
  color: #7aa77f;
  cursor: text;
  transition: opacity 300ms ease;
}
.text-field textarea:focus + label,
.text-field textarea.valid + label,
.text-field textarea.notEmpty + label {
  opacity: 0;
}
.text-field textarea.error + label {
  color: #fff;
}
.text-field textarea.valid + label + .hook {
  opacity: 1;
  transform: rotate(-45deg) translateY(-50%) scale(1);
}

:root {
  --header-rightLinks-width: 420px;
}
@media screen and (max-width: 1400px) {
  :root {
    --header-rightLinks-width: 400px;
  }
}
@media screen and (max-width: 1200px) {
  :root {
    --header-rightLinks-width: 370px;
  }
}
@media screen and (max-width: 1024px) {
  :root {
    --header-rightLinks-width: 391px;
  }
}
@media screen and (max-width: 700px) {
  :root {
    --header-rightLinks-width: 218px;
  }
}
@media screen and (max-width: 600px) {
  :root {
    --header-rightLinks-width: 200px;
  }
}

header {
  position: relative;
  z-index: 105;
}
header .leftPadding80 {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 1400px) {
  header .leftPadding80 {
    padding-left: 50px;
  }
}
@media screen and (max-width: 768px) {
  header .leftPadding80 {
    padding-left: 30px;
  }
}
@media screen and (max-width: 500px) {
  header .leftPadding80 {
    padding-left: 20px;
  }
}
header .leftPadding80 .logo {
  flex: 1;
  margin-right: 20px;
}
header > div .mainLinks li a:after {
  content: "";
  position: absolute;
  min-height: 0px;
  right: 15px;
  bottom: 0;
  left: 15px;
  background: #7aa77f;
  transition: min-height 300ms ease;
}
@media screen and (max-width: 1400px) {
  header > div .mainLinks li a:after {
    right: 10px;
    left: 10px;
  }
}
@media screen and (max-width: 1200px) {
  header > div .mainLinks li a:after {
    right: 7px;
    left: 7px;
  }
}
header > div:not(#overlay-dropDown) {
  position: relative;
  height: 100px;
  padding-right: var(--header-rightLinks-width);
  border-bottom: 1px solid rgba(255, 255, 255, 0);
  background: #fff;
  z-index: 105;
  transition: border 500ms;
}
@media screen and (max-height: 720px) {
  header > div:not(#overlay-dropDown) {
    height: 70px;
  }
}
@media screen and (max-width: 1024px) {
  header > div:not(#overlay-dropDown) {
    padding-right: 0;
  }
}
@media screen and (max-width: 700px) {
  header > div:not(#overlay-dropDown) {
    height: 70px;
  }
}
header > div:not(#overlay-dropDown) > a {
  height: 100px;
  line-height: 100px;
}
@media screen and (max-height: 720px) {
  header > div:not(#overlay-dropDown) > a {
    height: 70px;
    line-height: 70px;
  }
}
@media screen and (max-width: 700px) {
  header > div:not(#overlay-dropDown) > a {
    height: 70px;
    line-height: 70px;
  }
}
header > div:not(#overlay-dropDown) > a img {
  width: 220px;
  max-width: 100%;
  vertical-align: middle;
}
@media screen and (max-height: 720px) {
  header > div:not(#overlay-dropDown) > a img {
    width: 170px;
  }
}
@media screen and (max-width: 700px) {
  header > div:not(#overlay-dropDown) > a img {
    width: 170px;
  }
}
@media screen and (max-width: 400px) {
  header > div:not(#overlay-dropDown) > a img {
    width: 130px;
  }
}
header > div:not(#overlay-dropDown) ul li {
  display: inline-block;
  vertical-align: middle;
}
header > div:not(#overlay-dropDown) ul li a:not(.icon) {
  height: 100px;
  font: 15px/100px "hinted-Geomanist-Regular", sans-serif;
}
@media screen and (max-height: 720px) {
  header > div:not(#overlay-dropDown) ul li a:not(.icon) {
    height: 70px;
    line-height: 70px;
  }
}
header > div:not(#overlay-dropDown) .mainLinks {
  float: right;
}
@media screen and (max-width: 1024px) {
  header > div:not(#overlay-dropDown) .mainLinks {
    display: none;
  }
}
@media screen and (max-height: 600px) {
  header > div:not(#overlay-dropDown) .mainLinks {
    display: none;
  }
}
header > div:not(#overlay-dropDown) .mainLinks li:last-child {
  padding-right: 25px;
}
@media screen and (max-width: 1400px) {
  header > div:not(#overlay-dropDown) .mainLinks li:last-child {
    padding-right: 20px;
  }
}
@media screen and (max-width: 1200px) {
  header > div:not(#overlay-dropDown) .mainLinks li:last-child {
    padding-right: 15px;
  }
}
header > div:not(#overlay-dropDown) .mainLinks li a {
  position: relative;
  padding: 0 25px;
}
@media screen and (max-width: 1400px) {
  header > div:not(#overlay-dropDown) .mainLinks li a {
    padding: 0 20px;
  }
}
@media screen and (max-width: 1200px) {
  header > div:not(#overlay-dropDown) .mainLinks li a {
    padding: 0 15px;
  }
}
header > div:not(#overlay-dropDown) .rightLinks {
  position: absolute;
  width: var(--header-rightLinks-width);
  height: 100px;
  top: 0;
  right: 0;
  background: #7aa77f;
}
@media screen and (max-height: 720px) {
  header > div:not(#overlay-dropDown) .rightLinks {
    height: 70px;
  }
}
@media screen and (max-width: 1024px) {
  header > div:not(#overlay-dropDown) .rightLinks {
    position: relative;
    float: right;
    padding: 0 25px 0 50px;
  }
}
@media screen and (max-width: 700px) {
  header > div:not(#overlay-dropDown) .rightLinks {
    position: relative;
    float: right;
    padding: 0 30px;
    height: 70px;
  }
}
@media screen and (max-width: 600px) {
  header > div:not(#overlay-dropDown) .rightLinks {
    padding: 0 20px;
  }
}
header > div:not(#overlay-dropDown) .rightLinks li {
  font-size: 0;
}
@media screen and (max-height: 720px) {
  header > div:not(#overlay-dropDown) .rightLinks li {
    height: 70px;
    line-height: 70px;
  }
}
@media screen and (max-width: 700px) {
  header > div:not(#overlay-dropDown) .rightLinks li {
    height: 70px;
    line-height: 70px;
  }
}
header > div:not(#overlay-dropDown) .rightLinks li.alerte {
  margin-right: 15px;
}
@media screen and (max-width: 700px) {
  header > div:not(#overlay-dropDown) .rightLinks li.alerte {
    display: none;
  }
}
header > div:not(#overlay-dropDown) .rightLinks li.alerte a {
  position: relative;
  margin-left: 25px;
  padding: 0 25px;
  color: #fff;
}
@media screen and (max-width: 1400px) {
  header > div:not(#overlay-dropDown) .rightLinks li.alerte a {
    margin-left: 15px;
  }
}
@media screen and (max-width: 1200px) {
  header > div:not(#overlay-dropDown) .rightLinks li.alerte a {
    margin-left: 10px;
    padding: 0 16px;
  }
}
@media screen and (max-width: 1024px) {
  header > div:not(#overlay-dropDown) .rightLinks li.alerte a {
    margin: 0 11px 0 0;
    padding: 0;
  }
}
header > div:not(#overlay-dropDown) .rightLinks li.alerte a:after {
  content: "";
  position: absolute;
  min-height: 0px;
  right: 15px;
  bottom: 0;
  left: 15px;
  background: #fff;
  transition: min-height 300ms ease;
}
@media screen and (max-width: 1400px) {
  header > div:not(#overlay-dropDown) .rightLinks li.alerte a:after {
    right: 10px;
    left: 10px;
  }
}
@media screen and (max-width: 1200px) {
  header > div:not(#overlay-dropDown) .rightLinks li.alerte a:after {
    right: 7px;
    left: 7px;
  }
}
@media screen and (max-width: 1024px) {
  header > div:not(#overlay-dropDown) .rightLinks li.alerte a:after {
    right: 0;
    left: 0;
  }
}
header > div:not(#overlay-dropDown) .rightLinks li.alerte a:hover:after {
  min-height: 20px;
}
@media screen and (max-height: 720px) {
  header > div:not(#overlay-dropDown) .rightLinks li.alerte a:hover:after {
    min-height: 10px;
  }
}
header > div:not(#overlay-dropDown) .rightLinks li.tel, header > div:not(#overlay-dropDown) .rightLinks li.loupe, header > div:not(#overlay-dropDown) .rightLinks li.menu {
  margin-left: 20px;
}
@media screen and (max-width: 1024px) {
  header > div:not(#overlay-dropDown) .rightLinks li.tel, header > div:not(#overlay-dropDown) .rightLinks li.loupe, header > div:not(#overlay-dropDown) .rightLinks li.menu {
    margin-left: 15px;
  }
}
@media screen and (max-height: 600px) {
  header > div:not(#overlay-dropDown) .rightLinks li.tel {
    margin-left: 0;
  }
}
@media screen and (max-width: 1024px) {
  header > div:not(#overlay-dropDown) .rightLinks li.tel {
    margin-left: 0px;
  }
}
@media screen and (max-height: 600px) {
  header > div:not(#overlay-dropDown) .rightLinks li.tel {
    display: none;
  }
}
@media screen and (max-width: 1024px) {
  header > div:not(#overlay-dropDown) .rightLinks li.tel {
    display: none;
  }
}
header > div:not(#overlay-dropDown) .rightLinks li .icon {
  position: relative;
  width: 40px;
  height: 40px;
  border: 1px solid #fff;
  border-radius: 50%;
  vertical-align: middle;
  transition: background 300ms;
}
header > div:not(#overlay-dropDown) .rightLinks li .icon:hover {
  background: #fff;
}
header > div:not(#overlay-dropDown) .rightLinks li .icon:hover svg, header > div:not(#overlay-dropDown) .rightLinks li .icon:hover svg use {
  fill: #7aa77f;
}
header > div:not(#overlay-dropDown) .rightLinks li .icon svg {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 16px;
  height: 16px;
  fill: #fff;
  transform: translate(-50%, -50%);
}
header > div:not(#overlay-dropDown) .rightLinks li .icon svg use {
  fill: #fff;
  transition: fill 300ms;
}

@media screen and (max-width: 700px) {
  footer {
    text-align: center;
  }
}
@media screen and (max-width: 700px) {
  footer .logo {
    margin-bottom: 30px;
  }
}
footer .logo img {
  max-width: 65px;
  max-height: 79px;
}
footer .info p {
  font-size: 15px;
  line-height: 1.75;
}
footer .info p:first-child {
  margin-bottom: 15px;
  font-family: "hinted-Geomanist-Medium", sans-serif;
}
@media screen and (max-width: 700px) {
  footer .info p:first-child {
    margin-bottom: 5px;
  }
}
footer .info p:last-child {
  font-family: "hinted-Geomanist-Regular", sans-serif;
}
footer .info a {
  margin-bottom: 15px;
  font: 24px/1 "hinted-Geomanist-Medium", sans-serif;
  color: #7aa77f;
  transition: color 300ms;
}
@media screen and (max-width: 700px) {
  footer .info a {
    margin-bottom: 5px;
  }
}
footer .info a:hover {
  color: #3e3e2d;
}
footer .links ul li:not(:last-child) {
  margin-bottom: 15px;
}
footer .links ul li a {
  font: 15px/1 "hinted-Geomanist-Regular", sans-serif;
  color: #3e3e2d;
  transition: color 300ms;
}
footer .links ul li a:hover {
  color: #7aa77f;
}
footer .infolettre {
  margin-left: auto;
  margin-bottom: 50px;
}
footer .infolettre form {
  max-width: 380px;
  margin-left: auto;
}
@media screen and (max-width: 700px) {
  footer .infolettre form {
    max-width: 100%;
    margin-left: 0;
  }
}
footer .infolettre form .newsletterInput {
  position: relative;
}
footer .infolettre form .newsletterInput input {
  width: 100%;
  height: 46px;
  padding: 20px 90px 20px 30px;
  border: 1px solid #7aa77f;
  font: 13px/46px "hinted-Geomanist-Book", sans-serif;
  color: #3e3e2d;
  background: transparent;
  transition: 300ms ease;
}
@media screen and (max-width: 1024px) {
  footer .infolettre form .newsletterInput input {
    padding-left: 20px;
  }
}
footer .infolettre form .newsletterInput label {
  position: absolute;
  top: 0;
  left: 30px;
  font: 13px/46px "hinted-Geomanist-Book", sans-serif;
  color: #3e3e2d;
  text-transform: uppercase;
  cursor: text;
  transition: opacity 300ms;
}
@media screen and (max-width: 1024px) {
  footer .infolettre form .newsletterInput label {
    left: 20px;
  }
}
footer .infolettre form .newsletterInput input:focus + label,
footer .infolettre form .newsletterInput input.valid + label,
footer .infolettre form .newsletterInput input.error + label {
  opacity: 0;
  cursor: default;
  pointer-events: none;
}
footer .infolettre form .newsletterInput input.vide + label {
  opacity: 0;
  font: 25px/46px "hinted-Geomanist-Regular", sans-serif;
}
footer .infolettre form .newsletterInput a {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
}
footer .infolettre form .newsletterInput a:before {
  content: "";
  position: absolute;
  width: 46px;
  top: 0;
  right: 0;
  bottom: 0;
  background: #7aa77f;
  transition: width 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
footer .infolettre form .newsletterInput a .arrowWrapper {
  height: 100%;
  top: 0;
  right: 20px;
  bottom: 0;
}
footer .infolettre form .newsletterInput a .arrowWrapper svg {
  stroke: #3c3d40;
}
footer .infolettre form .newsletterInput a .arrowWrapper svg use {
  stroke: #3c3d40;
}
footer .infolettre form .newsletterInput a:hover:before {
  width: 65px;
}
footer .infolettre form .newsletterInput a:hover .arrowWrapper {
  width: 30px;
}
footer .infolettre h3 {
  margin-bottom: 30px;
  color: #3e3e2d;
}
@media screen and (max-width: 700px) {
  footer .infolettre h3 {
    text-align: left;
    margin-bottom: 10px;
  }
}

.copyright {
  padding-top: 30px;
  padding-bottom: 30px;
  background: #7aa77f;
}
@media screen and (max-width: 600px) {
  .copyright {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .copyright .row > div {
    text-align: center;
  }
  .copyright .row > div:last-child {
    margin-top: 5px;
  }
}
.copyright a {
  transition: opacity 300ms;
}
.copyright a:hover {
  opacity: 1;
}
.copyright p, .copyright a {
  font: 13px/1.5 "hinted-Geomanist-Regular", sans-serif;
  color: #fff;
  opacity: 0.5;
}

.beforeFooterBlock {
  position: relative;
  background: #efeae0;
  overflow: hidden;
  z-index: 1;
}
@media screen and (max-width: 768px) {
  .beforeFooterBlock {
    padding-right: 50px;
    padding-left: 50px;
  }
}
@media screen and (max-width: 400px) {
  .beforeFooterBlock {
    padding-right: 20px;
    padding-left: 20px;
  }
}
.beforeFooterBlock .background {
  position: absolute;
  height: 100px;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
}
.beforeFooterBlock .scrollTop {
  position: absolute;
  right: -25px;
  bottom: 190px;
  transform: rotate(-90deg);
  font: 12px/1 "hinted-Geomanist-Regular", sans-serif;
  color: #3e3e2d;
  text-transform: uppercase;
  letter-spacing: 1px;
  white-space: nowrap;
}
@media screen and (max-width: 1024px) {
  .beforeFooterBlock .scrollTop {
    right: -40px;
  }
}
@media screen and (max-width: 400px) {
  .beforeFooterBlock .scrollTop {
    display: none;
  }
}
.beforeFooterBlock .scrollTop svg {
  margin-left: 8px;
  width: 10px;
  height: 8px;
  stroke: #3e3e2d;
  transform: rotate(90deg);
  transition: transform 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.beforeFooterBlock .scrollTop svg use {
  stroke: #3e3e2d;
}
.beforeFooterBlock .scrollTop:hover svg {
  transform: rotate(90deg) translateY(-10px);
}
.beforeFooterBlock .col12 {
  position: relative;
  padding: 0;
}
.beforeFooterBlock .col12 .imageWrapper {
  position: relative;
  height: 400px;
  overflow: hidden;
}
@media screen and (max-width: 1024px) {
  .beforeFooterBlock .col12 .imageWrapper {
    height: 350px;
  }
}
@media screen and (max-width: 500px) {
  .beforeFooterBlock .col12 .imageWrapper {
    height: 300px;
  }
}
@media screen and (max-width: 400px) {
  .beforeFooterBlock .col12 .imageWrapper {
    height: 250px;
  }
}
.beforeFooterBlock .col12 .imageWrapper .image {
  position: absolute;
  top: -50px;
  right: 0;
  bottom: -50px;
  left: 0;
  background-position: right;
}
@media screen and (max-width: 900px) {
  .beforeFooterBlock .col12 .imageWrapper .image {
    background-position-x: 80%;
  }
}
.beforeFooterBlock .col12 .flex {
  height: 400px;
  align-items: center;
  background: #7aa77f;
}
@media screen and (max-width: 1024px) {
  .beforeFooterBlock .col12 .flex {
    height: 350px;
  }
}
@media screen and (max-width: 600px) {
  .beforeFooterBlock .col12 .flex {
    height: 100%;
    text-align: center;
  }
}
.beforeFooterBlock .col12 .flex .text {
  padding: 0 80px;
}
@media screen and (max-width: 1200px) {
  .beforeFooterBlock .col12 .flex .text {
    padding: 0 50px;
  }
}
@media screen and (max-width: 1024px) {
  .beforeFooterBlock .col12 .flex .text {
    padding: 0 30px;
  }
}
@media screen and (max-width: 700px) {
  .beforeFooterBlock .col12 .flex .text {
    padding: 0 20px;
  }
}
@media screen and (max-width: 600px) {
  .beforeFooterBlock .col12 .flex .text {
    padding: 50px 20px;
  }
}
@media screen and (max-width: 500px) {
  .beforeFooterBlock .col12 .flex .text {
    padding: 30px 20px;
  }
}
.beforeFooterBlock .col12 .flex .text .icon {
  display: inline-block;
  position: relative;
  width: 66px;
  height: 66px;
  border: 1px solid #fff;
  border-radius: 50%;
}
@media screen and (max-width: 768px) {
  .beforeFooterBlock .col12 .flex .text .icon {
    width: 50px;
    height: 50px;
  }
}
.beforeFooterBlock .col12 .flex .text .icon svg {
  position: absolute;
  width: 34px;
  height: 34px;
  top: 50%;
  left: 50%;
  fill: #fff;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 768px) {
  .beforeFooterBlock .col12 .flex .text .icon svg {
    width: 26px;
    height: 26px;
  }
}
.beforeFooterBlock .col12 .flex .text .icon svg use {
  fill: #fff;
}
.beforeFooterBlock .col12 .flex .text h4 {
  margin: 30px 0 40px;
  color: #fff;
}
@media screen and (max-width: 768px) {
  .beforeFooterBlock .col12 .flex .text h4 {
    margin: 20px 0 30px;
  }
}
.beforeFooterBlock .col12 .flex .text .arrowWrapper {
  display: inline-block;
  position: relative;
  height: 14px;
  transition: width 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94), margin 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.beforeFooterBlock .col12 .flex .text .arrowWrapper svg {
  stroke: #fff;
}
.beforeFooterBlock .col12 .flex .text .arrowWrapper svg use {
  stroke: #fff;
}
.beforeFooterBlock .col12 .flex:hover .text .arrowWrapper {
  width: 30px;
  margin-left: 20px;
}

.twoColBlock {
  position: relative;
  background: #efeae0;
  z-index: 2;
}
@media screen and (max-width: 1400px) {
  .twoColBlock {
    padding-right: 110px;
    padding-left: 110px;
  }
}
@media screen and (max-width: 1300px) {
  .twoColBlock {
    padding-right: 50px;
    padding-left: 50px;
  }
}
@media screen and (max-width: 768px) {
  .twoColBlock {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media screen and (max-width: 600px) {
  .twoColBlock {
    padding-top: 50px;
  }
}
@media screen and (max-width: 500px) {
  .twoColBlock {
    padding-right: 20px;
    padding-left: 20px;
  }
}
.twoColBlock .select-field {
  margin: 80px 0 0 0;
  max-width: 300px;
}
@media screen and (max-width: 1200px) {
  .twoColBlock .select-field {
    margin-top: 50px;
  }
}
@media screen and (max-width: 600px) {
  .twoColBlock .select-field {
    margin-top: 30px;
  }
}
.twoColBlock .text > div {
  max-width: 800px;
}
@media screen and (max-width: 1024px) {
  .twoColBlock .text > div {
    max-width: 100%;
  }
}
.twoColBlock .text > div > .dynamic {
  max-width: 800px;
}

.backBlock {
  margin-top: 40px;
  padding-top: 40px;
  border-top: 1px solid rgba(60, 61, 64, 0.4);
}
@media screen and (max-width: 600px) {
  .backBlock {
    text-align: center;
  }
}

html.overlayOpening .btnOverlay, html.overlayClosing .btnOverlay {
  pointer-events: none;
}

#overlay-dropDown {
  display: flex;
  position: absolute;
  height: 0;
  top: 100%;
  right: 0;
  left: 0;
  background: #fff;
  align-items: center;
  overflow: hidden;
  visibility: hidden;
  pointer-events: none;
  scrollbar-width: none;
  z-index: 102;
  transition: visibility 0ms ease 700ms;
}
@media screen and (max-height: 600px) {
  #overlay-dropDown {
    display: none;
  }
}
@media screen and (max-width: 1024px) {
  #overlay-dropDown {
    display: none;
  }
}
#overlay-dropDown::-webkit-scrollbar {
  display: none;
}
#overlay-dropDown > div:not(.portail) {
  position: absolute;
  padding: 0 var(--header-rightLinks-width) 0 80px;
  top: 0;
  right: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
  transform: translateY(30px);
  transition: visibility 0ms ease 300ms, opacity 300ms, transform 300ms ease;
}
@media screen and (max-width: 1400px) {
  #overlay-dropDown > div:not(.portail) {
    padding: 0 var(--header-rightLinks-width) 0 50px;
  }
}
@media screen and (max-width: 1300px) {
  #overlay-dropDown > div:not(.portail) {
    padding: 0 50px;
  }
}
#overlay-dropDown > div:not(.portail) h3 {
  color: #7aa77f;
  margin-bottom: 40px;
}
#overlay-dropDown > div:not(.portail) ul {
  float: left;
  width: 33.3333%;
  padding: 60px 20px 60px 0;
}
@media screen and (max-height: 800px) {
  #overlay-dropDown > div:not(.portail) ul {
    padding: 50px 20px 50px 0;
  }
}
@media screen and (max-height: 700px) {
  #overlay-dropDown > div:not(.portail) ul {
    padding: 40px 20px 40px 0;
  }
}
@media screen and (max-width: 1400px) {
  #overlay-dropDown > div:not(.portail) ul {
    padding: 50px 20px 50px 0;
  }
}
@media screen and (max-width: 1200px) {
  #overlay-dropDown > div:not(.portail) ul {
    padding: 40px 20px 40px 0;
  }
}
#overlay-dropDown > div:not(.portail) ul li:not(:last-child) {
  margin-bottom: 15px;
}
#overlay-dropDown > div:not(.portail) ul li p {
  font: 15px/1 "hinted-Geomanist-Medium", sans-serif;
  color: #3e3e2d;
}
#overlay-dropDown > div:not(.portail) ul li a {
  font: 15px/1.3 "hinted-Geomanist-Regular", sans-serif;
  color: #3e3e2d;
  transition: color 300ms;
}
#overlay-dropDown > div:not(.portail) ul li a:hover {
  color: #7aa77f;
}
#overlay-dropDown .portail {
  position: absolute;
  width: var(--header-rightLinks-width);
  top: 0;
  right: 0;
  bottom: 0;
}
@media screen and (max-width: 1300px) {
  #overlay-dropDown .portail {
    display: none;
  }
}
#overlay-dropDown .portail:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(122, 167, 127, 0.85);
}
#overlay-dropDown .portail .flex {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  opacity: 0;
  z-index: 1;
  align-items: center;
  transform: translateY(50px);
  transition: opacity 300ms, transform 300ms ease;
}
#overlay-dropDown .portail .flex .text {
  padding: 0 50px;
}
@media screen and (max-width: 1400px) {
  #overlay-dropDown .portail .flex .text {
    padding: 0 30px;
  }
}
#overlay-dropDown .portail .flex .text h3 {
  font: 30px/1.5 "hinted-Geomanist-Regular", sans-serif;
  margin-bottom: 30px;
  color: #fff;
}

.showOverlayDropDown header > div:not(#overlay-dropDown) {
  border-bottom-color: rgba(122, 167, 127, 0.4);
}
.showOverlayDropDown.municipalite #overlay-dropDown [data-links=municipalite] {
  opacity: 1;
  visibility: visible;
  transform: none;
  transform: none;
  transition: opacity 300ms linear 300ms, transform 300ms ease 300ms;
}
.showOverlayDropDown.municipalite header .mainLinks li [data-section=municipalite]:after {
  min-height: 20px;
}
@media screen and (max-height: 720px) {
  .showOverlayDropDown.municipalite header .mainLinks li [data-section=municipalite]:after {
    min-height: 10px;
  }
}
.showOverlayDropDown.citoyens #overlay-dropDown [data-links=citoyens] {
  opacity: 1;
  visibility: visible;
  transform: none;
  transform: none;
  transition: opacity 300ms linear 300ms, transform 300ms ease 300ms;
}
.showOverlayDropDown.citoyens header .mainLinks li [data-section=citoyens]:after {
  min-height: 20px;
}
@media screen and (max-height: 720px) {
  .showOverlayDropDown.citoyens header .mainLinks li [data-section=citoyens]:after {
    min-height: 10px;
  }
}
.showOverlayDropDown.loisirs #overlay-dropDown [data-links=loisirs] {
  opacity: 1;
  visibility: visible;
  transform: none;
  transition: opacity 300ms linear 300ms, transform 300ms ease 300ms;
}
.showOverlayDropDown.loisirs header .mainLinks li [data-section=loisirs]:after {
  min-height: 20px;
}
@media screen and (max-height: 720px) {
  .showOverlayDropDown.loisirs header .mainLinks li [data-section=loisirs]:after {
    min-height: 10px;
  }
}
.showOverlayDropDown .dropDownOverlay {
  background: rgba(0, 0, 0, 0.3);
  visibility: visible;
  z-index: 100;
  pointer-events: all;
  transition: background 700ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
.showOverlayDropDown #overlay-dropDown {
  overflow-y: auto;
  visibility: visible;
  pointer-events: all;
  transition: none;
}
.showOverlayDropDown #overlay-dropDown::-webkit-scrollbar {
  display: none;
}
.showOverlayDropDown #overlay-dropDown > div:not(.portail) {
  transition: visibility 0ms ease 300ms opacity 300ms linear, transform 300ms ease;
}
.showOverlayDropDown #overlay-dropDown .portail .flex {
  opacity: 1;
  transform: none;
  transition: opacity 300ms linear 300ms, transform 300ms ease 300ms;
}

#overlay-menu {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  visibility: hidden;
  z-index: 99;
  pointer-events: none;
  transition: visibility 0ms ease 700ms, opacity 300ms;
}
#overlay-menu .wrapper {
  position: fixed;
  width: calc(100% - var(--header-rightLinks-width));
  height: calc(100vh - 100px);
  padding-top: 70px;
  padding-bottom: 70px;
  top: 100px;
  left: calc(-100% + 331px);
  background: #fff;
  overflow-x: hidden;
  overflow-y: auto;
  pointer-events: all;
  z-index: 101;
  scrollbar-width: none;
}
@media screen and (max-height: 720px) {
  #overlay-menu .wrapper {
    height: calc(100vh - 70px);
    top: 70px;
  }
}
@media screen and (max-width: 700px) {
  #overlay-menu .wrapper {
    height: calc(100vh - 70px);
    width: calc(100% - var(--header-rightLinks-width));
    top: 70px;
    left: calc(-100% + var(--header-rightLinks-width));
  }
}
@media screen and (max-width: 600px) {
  #overlay-menu .wrapper {
    top: 70px;
  }
}
@media screen and (max-width: 500px) {
  #overlay-menu .wrapper {
    width: 100%;
    left: -100%;
    padding-top: 50px;
    padding-bottom: 120px;
  }
}
#overlay-menu .wrapper::-webkit-scrollbar {
  display: none;
}
#overlay-menu .wrapper h3 {
  margin-bottom: 25px;
  font-size: 35px;
  color: #7aa77f;
}
#overlay-menu .wrapper > ul:not(:last-child) {
  margin-bottom: 50px;
}
#overlay-menu .wrapper > ul > li {
  position: relative;
}
#overlay-menu .wrapper > ul > li:not(:last-child) {
  margin-bottom: 15px;
}
#overlay-menu .wrapper > ul > li > a:not(.btn) {
  position: relative;
  padding-right: 24px;
  font: 24px/1.1 "hinted-Geomanist-Medium", sans-serif;
  color: #3e3e2d;
  transition: color 300ms;
}
@media screen and (max-width: 600px) {
  #overlay-menu .wrapper > ul > li > a:not(.btn) {
    font-size: 24px;
  }
}
@media screen and (max-width: 400px) {
  #overlay-menu .wrapper > ul > li > a:not(.btn) {
    font-size: 20px;
  }
}
#overlay-menu .wrapper > ul > li > a:not(.btn):hover {
  color: #7aa77f;
}
#overlay-menu .wrapper > ul > li > a:not(.btn) svg {
  position: absolute;
  width: 12px;
  height: 10px;
  top: 7px;
  right: 0;
  stroke: #3e3e2d;
  transform: rotate(180deg);
}
@media screen and (max-width: 400px) {
  #overlay-menu .wrapper > ul > li > a:not(.btn) svg {
    top: 6px;
  }
}
#overlay-menu .wrapper > ul > li > a:not(.btn) svg use {
  stroke: #3e3e2d;
}
#overlay-menu .wrapper > ul > li.active > a {
  color: #7aa77f;
}
#overlay-menu .wrapper > ul > li.active > a:after {
  transform: rotate(-180deg);
}
#overlay-menu .wrapper .subLvl1 {
  display: none;
  opacity: 0;
  overflow: hidden;
  transition: padding 500ms cubic-bezier(0.215, 0.61, 0.355, 1) 200ms, margin 500ms cubic-bezier(0.215, 0.61, 0.355, 1) 200ms, opacity 300ms;
}
#overlay-menu .wrapper .subLvl1 > li {
  margin-top: 15px;
}
#overlay-menu .wrapper .subLvl1 > li:first-child {
  margin-top: 25px;
}
#overlay-menu .wrapper .subLvl1 > li:last-child {
  margin-bottom: 25px;
}
#overlay-menu .wrapper .subLvl1 > li.noBottomMargin {
  margin-bottom: 0;
}
#overlay-menu .wrapper .subLvl1 > li a {
  font: 15px/1 "hinted-Geomanist-Regular", sans-serif;
  color: #3e3e2d;
  transition: color 300ms;
  -webkit-tap-highlight-color: rgba(122, 167, 127, 0.5);
}
#overlay-menu .wrapper .subLvl1 > li a:hover, #overlay-menu .wrapper .subLvl1 > li a:active, #overlay-menu .wrapper .subLvl1 > li a:focus {
  color: #7aa77f;
}
#overlay-menu .wrapper .subLvl1.open {
  opacity: 1;
  transition: padding 300ms cubic-bezier(0.215, 0.61, 0.355, 1) 0ms, opacity 300ms linear 200ms;
}

.showOverlayMenu:not(.overlayClosing) header > div {
  border-bottom-color: #7aa77f;
}
.showOverlayMenu:not(.overlayClosing) #overlay-menu {
  overflow-y: auto;
  visibility: visible;
  transition: opacity 300ms;
}
.showOverlayMenu:not(.overlayClosing) #overlay-menu::-webkit-scrollbar {
  display: none;
}
.showOverlayMenu:not(.overlayClosing) #overlay-menu .overlay {
  background: rgba(0, 0, 0, 0.3);
  visibility: visible;
  z-index: 100;
  pointer-events: all;
  transition: background 700ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
.showOverlayMenu:not(.overlayClosing) #overlay-menu .wrapper {
  left: 0px;
  transition: left 600ms cubic-bezier(0.645, 0.045, 0.355, 1);
}
.showOverlayMenu:not(.overlayClosing) #overlay-menu .wrapper > div {
  opacity: 1;
  transform: none;
  transition: opacity 500ms linear 500ms, transform 500ms cubic-bezier(0.215, 0.61, 0.355, 1) 500ms;
}

.showOverlayMenu.overlayClosing #overlay-menu .wrapper {
  transition: left 600ms cubic-bezier(0.645, 0.045, 0.355, 1);
}

#overlay-fastLinks {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  visibility: hidden;
  z-index: 110;
  pointer-events: none;
  transition: visibility 0ms ease 700ms, opacity 300ms;
}
#overlay-fastLinks .wrapper {
  display: flex;
  position: fixed;
  width: 100%;
  height: 500px;
  top: -500px;
  left: 0;
  background: #e4b787;
  align-items: center;
  overflow-x: hidden;
  overflow-y: auto;
  pointer-events: all;
  z-index: 112;
  scrollbar-width: none;
}
@media screen and (max-width: 768px) {
  #overlay-fastLinks .wrapper {
    display: block;
    height: 100vh;
    top: -100vh;
    padding-top: 70px;
    padding-bottom: 70px;
  }
}
#overlay-fastLinks .wrapper::-webkit-scrollbar {
  display: none;
}
#overlay-fastLinks .wrapper .wrapper900 {
  margin: 0 auto;
}
@media screen and (max-width: 1300px) {
  #overlay-fastLinks .wrapper .wrapper900 {
    max-width: 700px;
  }
}
@media screen and (max-width: 768px) {
  #overlay-fastLinks .wrapper .wrapper900 {
    max-width: 350px;
  }
}
#overlay-fastLinks .wrapper a {
  float: left;
  width: 20%;
  padding: 0 1%;
  font: 12px/1.2 "hinted-Geomanist-Book", sans-serif;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 20px 0;
}
@media screen and (max-width: 1300px) {
  #overlay-fastLinks .wrapper a {
    width: 25%;
  }
  #overlay-fastLinks .wrapper a:nth-child(n+9) {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  #overlay-fastLinks .wrapper a {
    width: 50%;
  }
}
#overlay-fastLinks .wrapper a .iconWrapper {
  position: relative;
  width: 80px;
  height: 80px;
  margin: 0 auto 20px;
  border: 1px solid #fff;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0);
  transition: background 300ms;
}
#overlay-fastLinks .wrapper a .iconWrapper svg {
  position: absolute;
  width: 40px;
  height: 40px;
  top: 50%;
  left: 50%;
  fill: #fff;
  transform: translate(-50%, -50%);
  transition: fill 300ms;
}
#overlay-fastLinks .wrapper a .iconWrapper svg use {
  fill: #fff;
  transition: fill 300ms;
}
#overlay-fastLinks .wrapper a:hover .iconWrapper {
  background: #fff;
}
#overlay-fastLinks .wrapper a:hover .iconWrapper svg {
  fill: #e4b787;
}
#overlay-fastLinks .wrapper a:hover .iconWrapper svg use {
  fill: #e4b787;
}

.showOverlayFastLinks .btnFastLinks {
  z-index: 114;
}

.showOverlayFastLinks:not(.overlayClosing) #overlay-fastLinks {
  overflow-y: auto;
  visibility: visible;
  transition: right 500ms ease;
}
.showOverlayFastLinks:not(.overlayClosing) #overlay-fastLinks::-webkit-scrollbar {
  display: none;
}
.showOverlayFastLinks:not(.overlayClosing) #overlay-fastLinks .overlay {
  background: rgba(0, 0, 0, 0.3);
  visibility: visible;
  z-index: 111;
  pointer-events: all;
  transition: background 700ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
.showOverlayFastLinks:not(.overlayClosing) #overlay-fastLinks .wrapper {
  top: 0px;
  transition: top 600ms cubic-bezier(0.645, 0.045, 0.355, 1);
}
@media screen and (max-width: 1300px) {
  .showOverlayFastLinks:not(.overlayClosing) #overlay-fastLinks .wrapper {
    right: 30%;
  }
}
@media screen and (max-width: 1024px) {
  .showOverlayFastLinks:not(.overlayClosing) #overlay-fastLinks .wrapper {
    top: 0%;
    right: 0;
    bottom: 0%;
    left: 0;
  }
}
.showOverlayFastLinks:not(.overlayClosing) #overlay-fastLinks .wrapper > div {
  opacity: 1;
  transform: none;
  transition: opacity 500ms linear 500ms, transform 500ms cubic-bezier(0.215, 0.61, 0.355, 1) 500ms;
}

.showOverlayFastLinks.overlayClosing #overlay-fastLinks .wrapper {
  transition: top 600ms cubic-bezier(0.645, 0.045, 0.355, 1);
}

#overlay-search {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  visibility: hidden;
  z-index: 106;
  pointer-events: none;
  transition: visibility 0ms ease 700ms, opacity 300ms;
}
#overlay-search .wrapper {
  position: fixed;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
  top: -126px;
  left: 0;
  background: #e4b787;
  overflow-x: hidden;
  overflow-y: auto;
  pointer-events: all;
  z-index: 101;
  scrollbar-width: none;
}
#overlay-search .wrapper::-webkit-scrollbar {
  display: none;
}
@media screen and (max-width: 600px) {
  #overlay-search .wrapper {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
#overlay-search .wrapper .icon {
  position: absolute;
  top: 50%;
  right: 160px;
  width: 50px;
  height: 50px;
  border: 1px solid #fff;
  border-radius: 50%;
  cursor: pointer;
  transform: translateY(-50%);
}
@media screen and (max-width: 1024px) {
  #overlay-search .wrapper .icon {
    right: 120px;
  }
}
@media screen and (max-width: 768px) {
  #overlay-search .wrapper .icon {
    right: 100px;
  }
}
@media screen and (max-width: 600px) {
  #overlay-search .wrapper .icon {
    right: 80px;
    width: 40px;
    height: 40px;
  }
}
#overlay-search .wrapper .icon svg {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  fill: #fff;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 600px) {
  #overlay-search .wrapper .icon svg {
    width: 16px;
    height: 16px;
  }
}
#overlay-search .wrapper .icon svg use {
  fill: #fff;
}
#overlay-search .wrapper .searchInput {
  position: relative;
  width: auto;
  margin: 0 160px 0 0;
}
@media screen and (max-width: 600px) {
  #overlay-search .wrapper .searchInput {
    margin: 0 110px 0 0;
  }
}
@media screen and (max-width: 600px) {
  #overlay-search .wrapper .searchInput label {
    left: 10px;
  }
}
#overlay-search .wrapper .close {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50%;
  transform: translateY(-50%);
  right: 80px;
  border-radius: 50%;
  border: 1px solid #fff;
  cursor: pointer;
}
@media screen and (max-width: 1024px) {
  #overlay-search .wrapper .close {
    right: 50px;
  }
}
@media screen and (max-width: 768px) {
  #overlay-search .wrapper .close {
    right: 30px;
  }
}
@media screen and (max-width: 600px) {
  #overlay-search .wrapper .close {
    right: 20px;
    width: 40px;
    height: 40px;
  }
}
#overlay-search .wrapper .close:before, #overlay-search .wrapper .close:after {
  content: "";
  position: absolute;
  width: 0px;
  height: 1px;
  top: 50%;
  left: 50%;
  background: #fff;
}
#overlay-search .wrapper .close:before {
  transform: translate(-50%, -50%) rotate(45deg);
}
#overlay-search .wrapper .close:after {
  transform: translate(-50%, -50%) rotate(-45deg);
}
#overlay-search .wrapper .close:hover:before, #overlay-search .wrapper .close:hover:after {
  background: #3e3e2d;
}

.showOverlaySearch #overlay-search {
  z-index: 150;
}

.showOverlaySearch:not(.overlayClosing) #overlay-search {
  overflow-y: auto;
  visibility: visible;
  transition: opacity 300ms;
}
.showOverlaySearch:not(.overlayClosing) #overlay-search::-webkit-scrollbar {
  display: none;
}
.showOverlaySearch:not(.overlayClosing) #overlay-search .overlay {
  background: rgba(0, 0, 0, 0.3);
  visibility: visible;
  z-index: 100;
  pointer-events: all;
  transition: background 700ms ease;
}
.showOverlaySearch:not(.overlayClosing) #overlay-search .wrapper {
  top: 0px;
  transition: top 600ms cubic-bezier(0.645, 0.045, 0.355, 1);
}
.showOverlaySearch:not(.overlayClosing) #overlay-search .wrapper .close:before, .showOverlaySearch:not(.overlayClosing) #overlay-search .wrapper .close:after {
  width: 18px;
  transition: width 500ms cubic-bezier(0.165, 0.84, 0.44, 1) 500ms, background 300ms;
}

.showOverlaySearch.overlayClosing #overlay-search .wrapper {
  transition: top 600ms cubic-bezier(0.645, 0.045, 0.355, 1);
}
.showOverlaySearch.overlayClosing #overlay-search .wrapper .close:before, .showOverlaySearch.overlayClosing #overlay-search .wrapper .close:after {
  width: 0px;
  transition: width 500ms cubic-bezier(0.165, 0.84, 0.44, 1), background 300ms;
}

.alertBlock {
  position: relative;
  z-index: 106;
}
.alertBlock > div {
  height: 0px;
  overflow: hidden;
  transition: height 300ms;
}
.alertBlock > div > div {
  position: relative;
  padding: 15px 80px;
  background: #da4a4a;
  z-index: 104;
}
@media screen and (max-width: 1024px) {
  .alertBlock > div > div {
    padding: 15px 50px;
  }
}
@media screen and (max-width: 768px) {
  .alertBlock > div > div {
    padding: 15px 30px;
  }
}
@media screen and (max-width: 600px) {
  .alertBlock > div > div {
    padding: 15px 20px;
  }
}
.alertBlock > div > div a, .alertBlock > div > div p {
  display: block;
  padding: 0 30px;
  font: 14px/1 "hinted-Geomanist-Regular", sans-serif;
  color: #fff;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
@media screen and (max-width: 768px) {
  .alertBlock > div > div a, .alertBlock > div > div p {
    font-size: 12px;
  }
}
@media screen and (max-width: 500px) {
  .alertBlock > div > div a, .alertBlock > div > div p {
    text-align: left;
    padding-left: 0px;
  }
}
.alertBlock > div > div a {
  transition: opacity 300ms;
}
.alertBlock > div > div a:hover {
  opacity: 0.7;
}
.alertBlock > div > div .close {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 50%;
  right: 51px;
  transform: translateY(-50%);
  cursor: pointer;
}
@media screen and (max-width: 1400px) {
  .alertBlock > div > div .close {
    right: 42px;
  }
}
@media screen and (max-width: 1024px) {
  .alertBlock > div > div .close {
    right: 36px;
  }
}
@media screen and (max-width: 700px) {
  .alertBlock > div > div .close {
    right: 20px;
  }
}
.alertBlock > div > div .close:before, .alertBlock > div > div .close:after {
  content: "";
  position: absolute;
  width: 20px;
  height: 1px;
  top: 50%;
  left: 50%;
  background: #fff;
  transition: background 300ms;
}
.alertBlock > div > div .close:before {
  transform: translate(-50%, -50%) rotate(45deg);
}
.alertBlock > div > div .close:after {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.alertBlock > div > div .close:hover:before, .alertBlock > div > div .close:hover:after {
  background: #3e3e2d;
}

.showOverlayAlert .alertBlock > div {
  height: 44px;
}
@media screen and (max-width: 768px) {
  .showOverlayAlert .alertBlock > div {
    height: 42px;
  }
}
.showOverlayAlert #page-home .bannerBlock {
  height: calc(100vh - 144px);
}
@media screen and (max-width: 768px) {
  .showOverlayAlert #page-home .bannerBlock {
    height: calc(100vh - 142px);
  }
}
@media screen and (max-height: 720px) {
  .showOverlayAlert #page-home .bannerBlock {
    height: calc(100vh - 112px);
  }
}
@media screen and (max-width: 700px) {
  .showOverlayAlert #page-home .bannerBlock {
    height: calc(100vh - 112px);
  }
}
.showOverlayAlert #overlay-dropDown .wrapper {
  top: -456px;
}
@media screen and (max-height: 720px) {
  .showOverlayAlert #overlay-dropDown .wrapper {
    top: -382px;
  }
}
.showOverlayAlert.showOverlayDropDown #overlay-dropDown .wrapper {
  top: 144px;
}
@media screen and (max-height: 720px) {
  .showOverlayAlert.showOverlayDropDown #overlay-dropDown .wrapper {
    top: 118px;
  }
}
.showOverlayAlert #overlay-menu .wrapper {
  top: 144px;
  height: calc(100vh - 144px);
}
@media screen and (max-height: 720px) {
  .showOverlayAlert #overlay-menu .wrapper {
    top: 118px;
    height: calc(100vh - 118px);
  }
}
@media screen and (max-width: 768px) {
  .showOverlayAlert #overlay-menu .wrapper {
    top: 142px;
    height: calc(100vh - 142px);
  }
}
@media screen and (max-height: 720px) {
  .showOverlayAlert #overlay-menu .wrapper {
    top: 112px;
    height: calc(100vh - 112px);
  }
}
@media screen and (max-width: 700px) {
  .showOverlayAlert #overlay-menu .wrapper {
    top: 112px;
    height: calc(100vh - 112px);
  }
}

#overlay-share {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  visibility: hidden;
  z-index: 2001;
  pointer-events: none;
  transition: visibility 0ms ease 700ms;
}
#overlay-share .wrapper {
  position: fixed;
  padding: 50px 85px;
  top: 50%;
  left: 50%;
  background: #fff;
  pointer-events: all;
  text-align: center;
  z-index: 100;
  opacity: 0;
  transform: translate(-50%, 0%);
}
@media screen and (max-width: 1024px) {
  #overlay-share .wrapper {
    padding: 50px;
  }
}
@media screen and (max-width: 600px) {
  #overlay-share .wrapper {
    width: auto;
    height: 200px;
    right: 20px;
    left: 20px;
    padding: 50px 20px;
    transform: translateY(-50%);
  }
}
#overlay-share .wrapper h3 {
  margin-bottom: 20px;
}
#overlay-share .wrapper .close {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
#overlay-share .wrapper .close:before, #overlay-share .wrapper .close:after {
  content: "";
  position: absolute;
  width: 0px;
  height: 1px;
  top: 50%;
  left: 50%;
  background: #3e3e2d;
}
#overlay-share .wrapper .close:before {
  transform: translate(-50%, -50%) rotate(45deg);
}
#overlay-share .wrapper .close:after {
  transform: translate(-50%, -50%) rotate(-45deg);
}
#overlay-share .wrapper .close:hover:before, #overlay-share .wrapper .close:hover:after {
  background: #7aa77f;
}
#overlay-share .wrapper ul {
  position: relative;
  margin-top: 16px;
}
#overlay-share .wrapper ul li {
  display: inline-block;
}
#overlay-share .wrapper ul li:not(:last-child) {
  margin-right: 20px;
}
@media screen and (max-width: 600px) {
  #overlay-share .wrapper ul li:not(:last-child) {
    margin-right: 10px;
  }
}
#overlay-share .wrapper ul li a {
  position: relative;
  width: 45px;
  height: 45px;
  background: #7aa77f;
  border-radius: 50%;
  transition: background 300ms;
}
#overlay-share .wrapper ul li a svg {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 50%;
  left: 50%;
  fill: #fff;
  transform: translate(-50%, -50%);
}
#overlay-share .wrapper ul li a svg use {
  fill: #fff;
}
#overlay-share .wrapper ul li a:hover {
  background: #e4b787;
}

.showOverlayShare:not(.overlayClosing) #overlay-share {
  overflow-y: auto;
  visibility: visible;
  transition: visibility 0ms;
}
.showOverlayShare:not(.overlayClosing) #overlay-share::-webkit-scrollbar {
  display: none;
}
.showOverlayShare:not(.overlayClosing) #overlay-share .overlay {
  background: rgba(0, 0, 0, 0.3);
  visibility: visible;
  z-index: 100;
  pointer-events: all;
  transition: background 700ms ease;
}
.showOverlayShare:not(.overlayClosing) #overlay-share .wrapper {
  opacity: 1;
  transform: translate(-50%, -50%);
  transition: transform 500ms cubic-bezier(0.165, 0.84, 0.44, 1), opacity 500ms;
}
@media screen and (max-width: 600px) {
  .showOverlayShare:not(.overlayClosing) #overlay-share .wrapper {
    transform: translateY(-50%);
  }
}
.showOverlayShare:not(.overlayClosing) #overlay-share .wrapper .close:before, .showOverlayShare:not(.overlayClosing) #overlay-share .wrapper .close:after {
  width: 18px;
  transition: width 500ms cubic-bezier(0.165, 0.84, 0.44, 1) 200ms, background 300ms;
}

.showOverlayShare.overlayClosing #overlay-share .wrapper {
  transition: transform 500ms cubic-bezier(0.165, 0.84, 0.44, 1), opacity 500ms;
}
.showOverlayShare.overlayClosing #overlay-share .wrapper .close:before, .showOverlayShare.overlayClosing #overlay-share .wrapper .close:after {
  width: 0px;
  transition: width 500ms cubic-bezier(0.165, 0.84, 0.44, 1), background 300ms;
}

#overlay-calendar {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  visibility: hidden;
  z-index: 2001;
  pointer-events: none;
  transition: visibility 0ms ease 700ms;
}
#overlay-calendar .wrapper {
  position: fixed;
  padding: 80px 40px 40px;
  top: 50%;
  left: 50%;
  background: #fff;
  pointer-events: all;
  text-align: center;
  z-index: 100;
  opacity: 0;
  transform: translate(-50%, 0%);
}
@media screen and (max-width: 600px) {
  #overlay-calendar .wrapper {
    width: auto;
    top: 50%;
    right: 0;
    left: 0;
    padding: 80px 20px 20px;
    transform: translateY(-50%);
  }
}
#overlay-calendar .wrapper h3 {
  margin-bottom: 20px;
}
#overlay-calendar .wrapper .close {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
#overlay-calendar .wrapper .close:before, #overlay-calendar .wrapper .close:after {
  content: "";
  position: absolute;
  width: 0px;
  height: 1px;
  top: 50%;
  left: 50%;
  background: #3e3e2d;
}
#overlay-calendar .wrapper .close:before {
  transform: translate(-50%, -50%) rotate(45deg);
}
#overlay-calendar .wrapper .close:after {
  transform: translate(-50%, -50%) rotate(-45deg);
}
#overlay-calendar .wrapper .close:hover:before, #overlay-calendar .wrapper .close:hover:after {
  background: #7aa77f;
}

.showOverlayCalendar:not(.overlayClosing) #overlay-calendar {
  overflow-y: auto;
  visibility: visible;
  transition: visibility 0ms;
}
.showOverlayCalendar:not(.overlayClosing) #overlay-calendar::-webkit-scrollbar {
  display: none;
}
.showOverlayCalendar:not(.overlayClosing) #overlay-calendar .overlay {
  background: rgba(0, 0, 0, 0.3);
  visibility: visible;
  z-index: 100;
  pointer-events: all;
  transition: background 700ms ease;
}
.showOverlayCalendar:not(.overlayClosing) #overlay-calendar .wrapper {
  opacity: 1;
  transform: translate(-50%, -50%);
  transition: transform 500ms cubic-bezier(0.165, 0.84, 0.44, 1), opacity 500ms;
}
@media screen and (max-width: 600px) {
  .showOverlayCalendar:not(.overlayClosing) #overlay-calendar .wrapper {
    transform: translateY(-50%);
  }
}
.showOverlayCalendar:not(.overlayClosing) #overlay-calendar .wrapper .close:before, .showOverlayCalendar:not(.overlayClosing) #overlay-calendar .wrapper .close:after {
  width: 18px;
  transition: width 500ms cubic-bezier(0.165, 0.84, 0.44, 1) 200ms, background 300ms;
}

.showOverlayCalendar.overlayClosing #overlay-calendar .wrapper {
  transition: transform 500ms cubic-bezier(0.165, 0.84, 0.44, 1), opacity 500ms;
}
.showOverlayCalendar.overlayClosing #overlay-calendar .wrapper .close:before, .showOverlayCalendar.overlayClosing #overlay-calendar .wrapper .close:after {
  width: 0px;
  transition: width 500ms cubic-bezier(0.165, 0.84, 0.44, 1), background 300ms;
}

#page-home .bannerBlock {
  position: relative;
  height: calc(100vh - 100px);
  min-height: 500px;
  transition: height 300ms;
}
@media screen and (max-height: 720px) {
  #page-home .bannerBlock {
    height: calc(100vh - 70px);
  }
}
@media screen and (max-width: 700px) {
  #page-home .bannerBlock {
    height: calc(100vh - 70px);
  }
}
@media screen and (max-width: 500px) {
  #page-home .bannerBlock {
    height: calc(100vh - 70px - 55px);
  }
  #page-home .bannerBlock .text {
    bottom: 130px !important;
  }
}
@media screen and (max-width: 375px) {
  #page-home .bannerBlock {
    height: calc(100vh - 70px - 114px);
  }
  #page-home .bannerBlock .text {
    bottom: 170px !important;
  }
}
@media screen and (max-width: 360px) {
  #page-home .bannerBlock {
    height: calc(100vh - 70px - 55px);
  }
  #page-home .bannerBlock .text {
    bottom: 170px !important;
  }
}
@media screen and (max-width: 320px) {
  #page-home .bannerBlock {
    height: calc(100vh - 70px - 75px);
    min-height: 0px;
  }
  #page-home .bannerBlock .text {
    bottom: 195px !important;
  }
}
#page-home .bannerBlock .slickNav {
  position: absolute;
  width: 120px;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: #efeae0;
}
@media screen and (max-width: 1200px) {
  #page-home .bannerBlock .slickNav {
    width: 90px;
  }
}
@media screen and (max-width: 768px) {
  #page-home .bannerBlock .slickNav {
    display: none;
  }
}
#page-home .bannerBlock .slickNav .slickArrows {
  position: absolute;
  bottom: 300px;
  left: 50%;
  transform: translateX(-50%);
}
@media screen and (max-height: 800px) {
  #page-home .bannerBlock .slickNav .slickArrows {
    bottom: 260px;
  }
}
@media screen and (max-height: 700px) {
  #page-home .bannerBlock .slickNav .slickArrows {
    bottom: 210px;
  }
}
@media screen and (max-width: 1024px) {
  #page-home .bannerBlock .slickNav .slickArrows {
    bottom: 200px;
  }
}
#page-home .bannerBlock .slickNav .slickArrows .slickArrow {
  display: block;
  position: relative;
  width: 40px;
  height: 40px;
  margin: 0 auto;
  border: 1px solid #7aa77f;
  border-radius: 50%;
  transition: background 300ms;
}
#page-home .bannerBlock .slickNav .slickArrows .slickArrow.slickPrev {
  transform: rotate(-90deg);
  margin-bottom: 20px;
}
#page-home .bannerBlock .slickNav .slickArrows .slickArrow.slickNext {
  transform: rotate(90deg);
}
#page-home .bannerBlock .slickNav .slickArrows .slickArrow svg {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 14px;
  height: 8px;
  stroke: #3c3d40;
  transform: translate(-50%, -50%);
}
#page-home .bannerBlock .slickNav .slickArrows .slickArrow svg use {
  stroke: #3c3d40;
  transition: stroke 300ms;
}
#page-home .bannerBlock .slickNav .slickArrows .slickArrow:hover {
  background: #7aa77f;
}
#page-home .bannerBlock .slickNav .slickArrows .slickArrow:hover svg, #page-home .bannerBlock .slickNav .slickArrows .slickArrow:hover svg use {
  stroke: #fff;
}
#page-home .bannerBlock .slickNav .slick-dots {
  position: absolute;
  display: block;
  padding: 0;
  margin: 0;
  width: 100%;
  bottom: 450px;
  height: 180px;
}
@media screen and (max-height: 800px) {
  #page-home .bannerBlock .slickNav .slick-dots {
    bottom: 400px;
  }
}
@media screen and (max-height: 700px) {
  #page-home .bannerBlock .slickNav .slick-dots {
    bottom: 350px;
    height: 150px;
  }
}
@media screen and (max-height: 650px) {
  #page-home .bannerBlock .slickNav .slick-dots {
    height: 100px;
  }
}
@media screen and (max-width: 1024px) {
  #page-home .bannerBlock .slickNav .slick-dots {
    bottom: 335px;
  }
}
#page-home .bannerBlock .slickNav .slick-dots li {
  position: relative;
  display: block;
  width: auto;
  height: auto;
  padding: 0;
  margin: 0;
  cursor: pointer;
}
#page-home .bannerBlock .slickNav .slick-dots li button {
  display: block;
  width: 2px;
  height: 20px;
  margin: 0 auto;
  padding: 0;
  border: 0;
  font-size: 0px;
  line-height: 0px;
  color: transparent;
  background: rgba(122, 167, 127, 0.4);
  outline: none;
  cursor: pointer;
  transition: background 300ms;
}
#page-home .bannerBlock .slickNav .slick-dots li button:before {
  display: none;
}
#page-home .bannerBlock .slickNav .slick-dots li:hover button {
  background: rgba(122, 167, 127, 0.7);
}
#page-home .bannerBlock .slickNav .slick-dots li.slick-active button {
  background: #3c3d40;
}
#page-home .bannerBlock .slickWrapper {
  position: absolute;
  top: 0;
  right: 120px;
  left: 0;
}
@media screen and (max-width: 1200px) {
  #page-home .bannerBlock .slickWrapper {
    right: 90px;
  }
}
@media screen and (max-width: 768px) {
  #page-home .bannerBlock .slickWrapper {
    right: 0;
  }
}
#page-home .bannerBlock .slickWrapper .slick {
  margin-bottom: 0;
}
#page-home .bannerBlock .slickWrapper .slick .slick-slide {
  font-size: 0;
}
#page-home .bannerBlock .slickWrapper .slick .imageWrapper {
  position: relative;
  overflow: hidden;
  min-height: 500px;
  height: calc(100vh - 100px);
}
@media screen and (max-height: 720px) {
  #page-home .bannerBlock .slickWrapper .slick .imageWrapper {
    height: calc(100vh - 70px);
  }
}
@media screen and (max-width: 700px) {
  #page-home .bannerBlock .slickWrapper .slick .imageWrapper {
    height: calc(100vh - 70px);
  }
}
#page-home .bannerBlock .slickWrapper .slick .imageWrapper .image {
  position: absolute;
  top: -50px;
  right: 0;
  bottom: -50px;
  left: 0;
}
#page-home .bannerBlock .slickWrapper .slick .imageWrapper .image:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.25);
}
#page-home .bannerBlock .text {
  position: absolute;
  top: 0;
  bottom: 260px;
  align-items: center;
}
@media screen and (max-height: 800px) {
  #page-home .bannerBlock .text {
    bottom: 230px;
  }
}
@media screen and (max-height: 700px) {
  #page-home .bannerBlock .text {
    bottom: 190px;
  }
}
@media screen and (max-width: 1024px) {
  #page-home .bannerBlock .text {
    bottom: 130px;
  }
}
@media screen and (max-width: 500px) {
  #page-home .bannerBlock .text {
    bottom: 90px;
  }
}
#page-home .bannerBlock .text h6, #page-home .bannerBlock .text h1 {
  color: #fff;
}
#page-home .bannerBlock .text h1 {
  margin: 40px 0 50px;
  font-size: 100px;
}
@media screen and (max-height: 850px) {
  #page-home .bannerBlock .text h1 {
    margin: 20px 0 30px;
  }
}
@media screen and (max-width: 1024px) {
  #page-home .bannerBlock .text h1 {
    font-size: 90px;
  }
}
@media screen and (max-height: 650px) {
  #page-home .bannerBlock .text h1 {
    font-size: 80px;
  }
}
@media screen and (max-height: 600px) {
  #page-home .bannerBlock .text h1 {
    margin: 10px 0 20px;
  }
}
@media screen and (max-height: 570px) {
  #page-home .bannerBlock .text h1 {
    font-size: 70px;
  }
}
@media screen and (max-width: 768px) {
  #page-home .bannerBlock .text h1 {
    font-size: 80px;
  }
}
@media screen and (max-width: 500px) {
  #page-home .bannerBlock .text h1 {
    font-size: 70px;
  }
}
@media screen and (max-width: 400px) {
  #page-home .bannerBlock .text h1 {
    font-size: 50px;
  }
}
#page-home .bannerBlock .newsHome {
  position: absolute;
  width: 100%;
  max-width: 620px;
  left: 80px;
  bottom: 60px;
  background: #fff;
}
#page-home .bannerBlock .newsHome:before {
  content: "";
  position: absolute;
  width: 60px;
  top: 0;
  right: 0;
  bottom: 0;
  background: #7aa77f;
  transition: width 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
#page-home .bannerBlock .newsHome .imageWrapper {
  position: absolute;
  width: 150px;
  height: 150px;
  top: 50%;
  left: 20px;
  overflow: hidden;
  border-radius: 50%;
  transform: translateY(-50%);
}
#page-home .bannerBlock .newsHome .imageWrapper:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(122, 167, 127, 0.35);
}
#page-home .bannerBlock .newsHome .imageWrapper .image {
  position: absolute;
  top: -25px;
  right: 0;
  bottom: -25px;
  left: 0;
}
#page-home .bannerBlock .newsHome .info {
  padding: 0 120px 0 200px;
  height: 200px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#page-home .bannerBlock .newsHome .info div {
  width: 100%;
}
#page-home .bannerBlock .newsHome .info h5 {
  margin-top: 10px;
}
#page-home .bannerBlock .newsHome .arrowWrapper {
  height: 14px;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
}
#page-home .bannerBlock .newsHome .arrowWrapper svg {
  stroke: #3c3d40;
}
#page-home .bannerBlock .newsHome .arrowWrapper svg use {
  stroke: #3c3d40;
}
#page-home .bannerBlock .newsHome:hover:before {
  width: 85px;
}
#page-home .bannerBlock .newsHome:hover .arrowWrapper {
  width: 30px;
}
@media screen and (max-width: 1500px) {
  #page-home .bannerBlock .newsHome {
    max-width: 550px;
  }
}
@media screen and (max-width: 1300px) {
  #page-home .bannerBlock .newsHome {
    max-width: 500px;
  }
}
@media screen and (max-height: 800px) {
  #page-home .bannerBlock .newsHome {
    bottom: 30px;
  }
}
@media screen and (max-height: 700px) {
  #page-home .bannerBlock .newsHome {
    bottom: 20px;
  }
}
@media screen and (max-width: 1200px) {
  #page-home .bannerBlock .newsHome {
    display: none;
  }
}
@media screen and (max-height: 700px) {
  #page-home .bannerBlock .newsHome .info {
    height: 170px;
  }
}
#page-home .bannerBlock .search {
  position: absolute;
  height: 200px;
  right: 0;
  bottom: 60px;
  left: 768px;
  background: #e4b787;
  z-index: 1;
}
@media screen and (max-width: 1500px) {
  #page-home .bannerBlock .search {
    left: 708px;
  }
}
@media screen and (max-width: 1400px) {
  #page-home .bannerBlock .search {
    left: 670px;
  }
}
@media screen and (max-width: 1300px) {
  #page-home .bannerBlock .search {
    left: 600px;
  }
}
@media screen and (max-height: 800px) {
  #page-home .bannerBlock .search {
    bottom: 30px;
  }
}
@media screen and (max-height: 700px) {
  #page-home .bannerBlock .search {
    height: 170px;
    bottom: 20px;
  }
}
@media screen and (max-width: 1200px) {
  #page-home .bannerBlock .search {
    left: 50px;
  }
}
@media screen and (max-width: 1024px) {
  #page-home .bannerBlock .search {
    height: 100px;
  }
}
@media screen and (max-width: 768px) {
  #page-home .bannerBlock .search {
    left: 30px;
    right: 30px;
    bottom: 30px;
  }
}
@media screen and (max-width: 600px) {
  #page-home .bannerBlock .search {
    left: 20px;
    right: 20px;
    bottom: 20px;
  }
}
@media screen and (max-width: 500px) {
  #page-home .bannerBlock .search {
    height: 70px;
  }
}
#page-home .bannerBlock .search .icon {
  position: absolute;
  top: 50%;
  left: 35px;
  width: 66px;
  height: 66px;
  border: 1px solid #fff;
  border-radius: 50%;
  transform: translateY(-50%);
}
@media screen and (max-width: 1400px) {
  #page-home .bannerBlock .search .icon {
    width: 50px;
    height: 50px;
  }
}
@media screen and (max-width: 768px) {
  #page-home .bannerBlock .search .icon {
    left: auto;
    right: 20px;
  }
}
#page-home .bannerBlock .search .icon svg {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  fill: #fff;
  transform: translate(-50%, -50%);
}
#page-home .bannerBlock .search .icon svg use {
  fill: #fff;
}
#page-home .bannerBlock .search .btn {
  position: absolute;
  top: 50%;
  right: 50px;
  transform: translateY(-50%);
}
@media screen and (max-width: 1200px) {
  #page-home .bannerBlock .search .btn {
    right: 30px;
  }
}
@media screen and (max-width: 768px) {
  #page-home .bannerBlock .search .btn {
    display: none;
  }
}
#page-home .bannerBlock .search .searchInput {
  position: absolute;
  top: 50%;
  right: 140px;
  left: 135px;
  transform: translateY(-50%);
  caret-color: white;
}
@media screen and (max-width: 1400px) {
  #page-home .bannerBlock .search .searchInput {
    left: 120px;
  }
}
@media screen and (max-width: 1200px) {
  #page-home .bannerBlock .search .searchInput {
    right: 104px;
  }
}
@media screen and (max-width: 1100px) {
  #page-home .bannerBlock .search .searchInput {
    left: 107px;
  }
}
@media screen and (max-width: 768px) {
  #page-home .bannerBlock .search .searchInput {
    left: 20px;
  }
}
#page-home .newsBlock {
  position: relative;
  background: #efeae0;
  overflow: hidden;
}
@media screen and (max-width: 768px) {
  #page-home .newsBlock {
    padding-bottom: 70px;
  }
}
#page-home .newsBlock .background {
  position: absolute;
  height: 1000px;
  bottom: 100px;
  left: 0;
  right: 0;
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
}
@media screen and (max-width: 768px) {
  #page-home .newsBlock .background {
    bottom: 0;
  }
}
#page-home .newsBlock .wrapper1400 {
  position: relative;
}
#page-home .newsBlock .wrapper1400 .row {
  width: calc(100% + 100px);
  margin-right: -50px;
  margin-left: -50px;
}
@media screen and (max-width: 1300px) {
  #page-home .newsBlock .wrapper1400 .row {
    width: calc(100% + 50px);
    margin-right: -25px;
    margin-left: -25px;
  }
}
@media screen and (max-width: 1100px) {
  #page-home .newsBlock .wrapper1400 .row {
    width: calc(100% + 30px);
    margin-right: -15px;
    margin-left: -15px;
  }
}
#page-home .newsBlock .wrapper1400 .row .col12 {
  padding: 0 50px;
}
@media screen and (max-width: 1300px) {
  #page-home .newsBlock .wrapper1400 .row .col12 {
    padding: 0 25px;
  }
}
@media screen and (max-width: 1100px) {
  #page-home .newsBlock .wrapper1400 .row .col12 {
    padding: 0 15px;
  }
}
#page-home .newsBlock .wrapper1400 .row .col12:nth-child(2), #page-home .newsBlock .wrapper1400 .row .col12:nth-child(4) {
  float: right;
}
@media screen and (max-width: 900px) {
  #page-home .newsBlock .wrapper1400 .row .col12:nth-child(2), #page-home .newsBlock .wrapper1400 .row .col12:nth-child(4) {
    float: left;
  }
}
#page-home .newsBlock .wrapper1400 .row .col12:nth-child(3) .news {
  margin-left: auto;
}
@media screen and (max-width: 900px) and (min-width: 601px) {
  #page-home .newsBlock .wrapper1400 .row .col12:nth-child(3) .news {
    margin-left: 0;
  }
}
#page-home .newsBlock .wrapper1400 .row .col12 h2, #page-home .newsBlock .wrapper1400 .row .col12:nth-child(2), #page-home .newsBlock .wrapper1400 .row .col12:nth-child(3) {
  margin-bottom: 100px;
}
@media screen and (max-width: 1200px) {
  #page-home .newsBlock .wrapper1400 .row .col12 h2, #page-home .newsBlock .wrapper1400 .row .col12:nth-child(2), #page-home .newsBlock .wrapper1400 .row .col12:nth-child(3) {
    margin-bottom: 75px;
  }
}
@media screen and (max-width: 1024px) {
  #page-home .newsBlock .wrapper1400 .row .col12 h2, #page-home .newsBlock .wrapper1400 .row .col12:nth-child(2), #page-home .newsBlock .wrapper1400 .row .col12:nth-child(3) {
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 900px) {
  #page-home .newsBlock .wrapper1400 .row .col12 h2, #page-home .newsBlock .wrapper1400 .row .col12:nth-child(2), #page-home .newsBlock .wrapper1400 .row .col12:nth-child(3) {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 600px) {
  #page-home .newsBlock .wrapper1400 .row .col12 h2 {
    display: block;
    text-align: center;
  }
  #page-home .newsBlock .wrapper1400 .row .col12 h2 span {
    text-align: center;
  }
}
@media screen and (max-width: 900px) {
  #page-home .newsBlock .wrapper1400 .row .col12.textRight {
    text-align: left;
  }
}
#page-home .newsBlock .wrapper1400 .row .col12 .btnWrapper {
  max-width: 380px;
  margin-left: auto;
  text-align: center;
}
@media screen and (max-width: 900px) {
  #page-home .newsBlock .wrapper1400 .row .col12 .btnWrapper {
    max-width: 100%;
    margin-top: 70px;
  }
}
@media screen and (max-width: 900px) {
  #page-home .newsBlock .wrapper1400 .row .col12 .news {
    max-width: 100%;
  }
}
@media screen and (max-width: 800px) {
  #page-home .newsBlock .wrapper1400 .row .col12 .news {
    padding: 70px 20px;
  }
}
@media screen and (max-width: 900px) and (min-width: 601px) {
  #page-home .newsBlock .wrapper1400 .row .col12 .news {
    padding: 0;
    background: #fff;
  }
  #page-home .newsBlock .wrapper1400 .row .col12 .news:before {
    content: "";
    position: absolute;
    width: 60px;
    top: 0;
    right: 0;
    bottom: 0;
    background: #7aa77f;
    transition: width 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
  #page-home .newsBlock .wrapper1400 .row .col12 .news .imageWrapper {
    position: absolute;
    width: 150px;
    height: 150px;
    top: 50%;
    left: 20px;
    overflow: hidden;
    border-radius: 50%;
    transform: translateY(-50%);
  }
  #page-home .newsBlock .wrapper1400 .row .col12 .news .imageWrapper:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(122, 167, 127, 0.35);
  }
  #page-home .newsBlock .wrapper1400 .row .col12 .news .imageWrapper .image {
    position: absolute;
    top: -25px;
    right: 0;
    bottom: -25px;
    left: 0;
  }
  #page-home .newsBlock .wrapper1400 .row .col12 .news .info {
    padding: 0 120px 0 200px;
    height: 200px;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  #page-home .newsBlock .wrapper1400 .row .col12 .news .info div {
    width: 100%;
  }
  #page-home .newsBlock .wrapper1400 .row .col12 .news .info h5 {
    margin-top: 10px;
  }
  #page-home .newsBlock .wrapper1400 .row .col12 .news .arrowWrapper {
    height: 14px;
    top: 50%;
    right: 30px;
    transform: translateY(-50%);
  }
  #page-home .newsBlock .wrapper1400 .row .col12 .news .arrowWrapper svg {
    stroke: #3c3d40;
  }
  #page-home .newsBlock .wrapper1400 .row .col12 .news .arrowWrapper svg use {
    stroke: #3c3d40;
  }
  #page-home .newsBlock .wrapper1400 .row .col12 .news:hover:before {
    width: 85px;
  }
  #page-home .newsBlock .wrapper1400 .row .col12 .news:hover .arrowWrapper {
    width: 30px;
  }
  #page-home .newsBlock .wrapper1400 .row .col12 .news .imageWrapper {
    margin-bottom: 0;
  }
  #page-home .newsBlock .wrapper1400 .row .col12 .news .info .subInfo {
    position: static;
  }
}
@media screen and (max-width: 600px) {
  #page-home .newsBlock .wrapper1400 .row .col12 .news {
    max-width: 400px;
    margin: 0 auto;
  }
}
#page-home .eventBlock {
  background: #efeae0;
}
#page-home .eventBlock .title {
  position: relative;
  margin-bottom: 65px;
}
@media screen and (max-width: 1200px) {
  #page-home .eventBlock .title {
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 1024px) {
  #page-home .eventBlock .title {
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 768px) {
  #page-home .eventBlock .title {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 600px) {
  #page-home .eventBlock .title {
    text-align: center;
  }
}
@media screen and (max-width: 600px) {
  #page-home .eventBlock .title h2 {
    display: block;
  }
  #page-home .eventBlock .title h2 span {
    text-align: center;
  }
}
#page-home .eventBlock .title a {
  position: absolute;
  padding-top: 20px;
  right: 0;
  bottom: 0;
  font: 12px/1 "hinted-Geomanist-Regular", sans-serif;
  color: #3e3e2d;
  text-transform: uppercase;
  letter-spacing: 1px;
}
@media screen and (max-width: 600px) {
  #page-home .eventBlock .title a {
    position: relative;
    margin-top: 20px;
  }
}
#page-home .eventBlock .title a .arrowWrapper {
  top: 0;
  right: 0;
}
@media screen and (max-width: 600px) {
  #page-home .eventBlock .title a .arrowWrapper {
    right: 47px;
  }
}
#page-home .eventBlock .title a .arrowWrapper svg {
  stroke: #3e3e2d;
}
#page-home .eventBlock .title a .arrowWrapper svg use {
  color: #3e3e2d;
}
#page-home .eventBlock .title a:hover .arrowWrapper {
  width: 30px;
}
#page-home .eventBlock .slickWrapper .textWrapper {
  padding-left: calc((100% - 1400px - 100px) / 2) !important;
  padding-right: calc((100% - 1400px - 100px) / 2) !important;
}
@media screen and (max-width: 1800px) {
  #page-home .eventBlock .slickWrapper .textWrapper {
    padding-left: 170px !important;
    padding-right: 170px !important;
  }
}
@media screen and (max-width: 1400px) {
  #page-home .eventBlock .slickWrapper .textWrapper {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }
}
@media screen and (max-width: 1200px) {
  #page-home .eventBlock .slickWrapper .textWrapper {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }
}
@media screen and (max-width: 1024px) {
  #page-home .eventBlock .slickWrapper .textWrapper {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
}
@media screen and (max-width: 768px) {
  #page-home .eventBlock .slickWrapper .textWrapper {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
}
@media screen and (max-width: 600px) {
  #page-home .eventBlock .slickWrapper .textWrapper {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
#page-home .eventBlock .slickWrapper .textWrapper .text {
  position: relative;
  margin: -60px 100px 0;
  background: #fff;
  z-index: 1;
}
@media screen and (max-width: 1024px) {
  #page-home .eventBlock .slickWrapper .textWrapper .text {
    margin: -60px 50px 0;
  }
}
@media screen and (max-width: 768px) {
  #page-home .eventBlock .slickWrapper .textWrapper .text {
    margin: -30px 30px 0;
  }
}
@media screen and (max-width: 600px) {
  #page-home .eventBlock .slickWrapper .textWrapper .text {
    margin: 0 20px;
  }
}
#page-home .eventBlock .slickWrapper .flex {
  position: relative;
  width: auto;
  height: 250px;
  align-items: center;
  z-index: 1;
}
@media screen and (max-width: 768px) {
  #page-home .eventBlock .slickWrapper .flex {
    display: block;
    height: 100%;
    margin: 0;
  }
}
#page-home .eventBlock .slickWrapper .flex .row {
  width: 100%;
  position: relative;
  padding: 60px 100px;
}
@media screen and (max-width: 1400px) {
  #page-home .eventBlock .slickWrapper .flex .row {
    padding: 50px 80px;
  }
}
@media screen and (max-width: 1200px) {
  #page-home .eventBlock .slickWrapper .flex .row {
    padding: 40px 60px;
  }
}
@media screen and (max-width: 1100px) {
  #page-home .eventBlock .slickWrapper .flex .row {
    padding: 40px 40px;
  }
}
@media screen and (max-width: 1024px) {
  #page-home .eventBlock .slickWrapper .flex .row {
    padding: 30px 30px;
  }
}
@media screen and (max-width: 768px) {
  #page-home .eventBlock .slickWrapper .flex .row {
    padding: 30px 20px;
    text-align: center;
  }
}
@media screen and (max-width: 600px) {
  #page-home .eventBlock .slickWrapper .flex .row {
    padding: 30px 15px;
  }
}
#page-home .eventBlock .slickWrapper .flex .row > div {
  padding: 0;
}
#page-home .eventBlock .slickWrapper .flex .row h3 {
  margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
  #page-home .eventBlock .slickWrapper .flex .row h3 {
    margin-bottom: 10px;
  }
}
#page-home .eventBlock .slickWrapper .flex .row .info {
  width: auto;
}
@media screen and (max-width: 768px) {
  #page-home .eventBlock .slickWrapper .flex .row .info {
    margin-bottom: 10px;
  }
}
#page-home .eventBlock .slickWrapper .flex .row .info span {
  display: block;
  font: 12px/1.3 "hinted-Geomanist-Book", sans-serif;
  color: #7aa77f;
  text-transform: uppercase;
  letter-spacing: 1px;
}
#page-home .eventBlock .slickWrapper .flex .row .btn {
  display: inline-block;
  position: absolute;
  right: 100px;
  bottom: 60px;
}
@media screen and (max-width: 1400px) {
  #page-home .eventBlock .slickWrapper .flex .row .btn {
    right: 80px;
    bottom: 50px;
  }
}
@media screen and (max-width: 1200px) {
  #page-home .eventBlock .slickWrapper .flex .row .btn {
    position: static;
    margin-top: 20px;
  }
}
@media screen and (max-width: 768px) {
  #page-home .eventBlock .slickWrapper .flex .row .btn {
    margin-top: 10px;
  }
}
#page-home .eventBlock .slickWrapper .flex:hover .btn {
  color: #fff;
  background: #7aa77f;
  border-color: #7aa77f;
}
#page-home .eventBlock .slickWrapper .slickEventsImages {
  font-size: 0;
}
#page-home .eventBlock .slickWrapper .slickEventsImages .imageWrapper .image {
  height: 600px;
}
@media screen and (max-width: 1500px) {
  #page-home .eventBlock .slickWrapper .slickEventsImages .imageWrapper .image {
    height: 500px;
  }
}
@media screen and (max-width: 1024px) {
  #page-home .eventBlock .slickWrapper .slickEventsImages .imageWrapper .image {
    height: 450px;
  }
}
@media screen and (max-width: 768px) {
  #page-home .eventBlock .slickWrapper .slickEventsImages .imageWrapper .image {
    height: 400px;
  }
}
@media screen and (max-width: 600px) {
  #page-home .eventBlock .slickWrapper .slickEventsImages .imageWrapper .image {
    height: 350px;
  }
}
@media screen and (max-width: 500px) {
  #page-home .eventBlock .slickWrapper .slickEventsImages .imageWrapper .image {
    height: 300px;
  }
}
@media screen and (max-width: 400px) {
  #page-home .eventBlock .slickWrapper .slickEventsImages .imageWrapper .image {
    height: 250px;
  }
}
#page-home .eventBlock .slickWrapper .slickEventsImages .slick-list {
  padding-left: calc((100% - 1400px - 100px) / 2) !important;
  padding-right: calc((100% - 1400px - 100px) / 2) !important;
}
@media screen and (max-width: 1800px) {
  #page-home .eventBlock .slickWrapper .slickEventsImages .slick-list {
    padding-left: 170px !important;
    padding-right: 170px !important;
  }
}
@media screen and (max-width: 1400px) {
  #page-home .eventBlock .slickWrapper .slickEventsImages .slick-list {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }
}
@media screen and (max-width: 1200px) {
  #page-home .eventBlock .slickWrapper .slickEventsImages .slick-list {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }
}
@media screen and (max-width: 1024px) {
  #page-home .eventBlock .slickWrapper .slickEventsImages .slick-list {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
}
@media screen and (max-width: 768px) {
  #page-home .eventBlock .slickWrapper .slickEventsImages .slick-list {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
}
@media screen and (max-width: 600px) {
  #page-home .eventBlock .slickWrapper .slickEventsImages .slick-list {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
#page-home .eventBlock .slickWrapper .slickEventsImages .slick-list .slick-slide {
  position: relative;
  padding: 0 50px;
}
@media screen and (max-width: 1800px) {
  #page-home .eventBlock .slickWrapper .slickEventsImages .slick-list .slick-slide {
    padding: 0 30px;
  }
}
@media screen and (max-width: 1200px) {
  #page-home .eventBlock .slickWrapper .slickEventsImages .slick-list .slick-slide {
    padding: 0 20px;
  }
}
@media screen and (max-width: 1024px) {
  #page-home .eventBlock .slickWrapper .slickEventsImages .slick-list .slick-slide {
    padding: 0 10px;
  }
}
@media screen and (max-width: 768px) {
  #page-home .eventBlock .slickWrapper .slickEventsImages .slick-list .slick-slide {
    padding: 0 5px;
  }
}
@media screen and (max-width: 600px) {
  #page-home .eventBlock .slickWrapper .slickEventsImages .slick-list .slick-slide {
    padding: 0 20px;
  }
}
#page-home .eventBlock .slickWrapper .slickEventsImages .slick-list .slick-slide:hover {
  cursor: grab;
}
#page-home .eventBlock .slickWrapper .slickEventsImages .slick-list .slick-slide:active {
  cursor: grabbing;
}
#page-home .eventBlock .slickWrapper .slickNav {
  font-size: 0;
  text-align: center;
}
#page-home .eventBlock .slickWrapper .slickNav .slickArrow {
  position: relative;
  width: 200px;
  height: 60px;
  background: #7aa77f;
}
@media screen and (max-width: 768px) {
  #page-home .eventBlock .slickWrapper .slickNav .slickArrow {
    width: 150px;
    height: 50px;
  }
}
@media screen and (max-width: 500px) {
  #page-home .eventBlock .slickWrapper .slickNav .slickArrow {
    width: 100px;
    height: 40px;
  }
}
#page-home .eventBlock .slickWrapper .slickNav .slickArrow.slickPrev:after {
  content: "";
  position: absolute;
  width: 1px;
  top: 0;
  right: -0.5px;
  bottom: 0;
  background: rgba(255, 255, 255, 0.3);
  z-index: 1;
}
#page-home .eventBlock .slickWrapper .slickNav .slickArrow.slickPrev .arrowWrapper {
  left: 75px;
  transform: translateY(-50%) rotate(180deg);
}
@media screen and (max-width: 768px) {
  #page-home .eventBlock .slickWrapper .slickNav .slickArrow.slickPrev .arrowWrapper {
    left: 50px;
  }
}
@media screen and (max-width: 500px) {
  #page-home .eventBlock .slickWrapper .slickNav .slickArrow.slickPrev .arrowWrapper {
    left: 25px;
  }
}
#page-home .eventBlock .slickWrapper .slickNav .slickArrow.slickNext .arrowWrapper {
  right: 75px;
}
@media screen and (max-width: 768px) {
  #page-home .eventBlock .slickWrapper .slickNav .slickArrow.slickNext .arrowWrapper {
    right: 50px;
  }
}
@media screen and (max-width: 500px) {
  #page-home .eventBlock .slickWrapper .slickNav .slickArrow.slickNext .arrowWrapper {
    right: 25px;
  }
}
#page-home .eventBlock .slickWrapper .slickNav .slickArrow .arrowWrapper {
  top: 50%;
  transform: translateY(-50%);
}
#page-home .eventBlock .slickWrapper .slickNav .slickArrow .arrowWrapper svg {
  stroke: #3e3e2d;
}
#page-home .eventBlock .slickWrapper .slickNav .slickArrow .arrowWrapper svg use {
  stroke: #3e3e2d;
}
#page-home .eventBlock .slickWrapper .slickNav .slickArrow:hover .arrowWrapper {
  width: 30px;
}

#page-news .headerPage {
  background: #efeae0;
  padding-bottom: 50px;
}
#page-news .headerPage .select-field {
  max-width: 300px;
  margin-bottom: 0;
}
#page-news .newsBlock {
  background: #efeae0;
}
#page-news .newsBlock .row {
  width: calc(100% + 100px);
  margin-right: -50px;
  margin-left: -50px;
}
@media screen and (max-width: 1300px) {
  #page-news .newsBlock .row {
    width: calc(100% + 50px);
    margin-right: -25px;
    margin-left: -25px;
  }
}
@media screen and (max-width: 1100px) {
  #page-news .newsBlock .row {
    width: calc(100% + 30px);
    margin-right: -15px;
    margin-left: -15px;
  }
}
#page-news .newsBlock .row .col12 {
  padding: 0 50px;
}
@media screen and (max-width: 1300px) {
  #page-news .newsBlock .row .col12 {
    padding: 0 25px;
  }
}
@media screen and (max-width: 1100px) {
  #page-news .newsBlock .row .col12 {
    padding: 0 15px;
  }
}
#page-news .newsBlock .row .col12:not(:last-child) {
  margin-bottom: 50px;
}
@media screen and (max-width: 900px) {
  #page-news .newsBlock .row .col12:not(:last-child) {
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 600px) {
  #page-news .newsBlock .row .col12:not(:last-child) {
    margin-bottom: 20px;
  }
}
#page-news .newsBlock .row .col12:nth-child(3n-1) {
  margin-top: -85px;
}
@media screen and (max-width: 900px) {
  #page-news .newsBlock .row .col12:nth-child(3n-1) {
    margin-top: 0;
  }
}
#page-news .newsBlock .row .col12:nth-child(3n+1) .news {
  margin-left: auto;
}
@media screen and (max-width: 900px) and (min-width: 601px) {
  #page-news .newsBlock .row .col12:nth-child(3n+1) .news {
    margin-left: 0;
  }
}
@media screen and (max-width: 900px) {
  #page-news .newsBlock .row .col12.textRight {
    text-align: left;
  }
}
@media screen and (max-width: 900px) {
  #page-news .newsBlock .row .col12 .news {
    max-width: 100%;
  }
}
@media screen and (max-width: 800px) {
  #page-news .newsBlock .row .col12 .news {
    padding: 70px 20px;
  }
}
@media screen and (max-width: 900px) and (min-width: 601px) {
  #page-news .newsBlock .row .col12 .news {
    padding: 0;
    background: #fff;
  }
  #page-news .newsBlock .row .col12 .news:before {
    content: "";
    position: absolute;
    width: 60px;
    top: 0;
    right: 0;
    bottom: 0;
    background: #7aa77f;
    transition: width 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
  #page-news .newsBlock .row .col12 .news .imageWrapper {
    position: absolute;
    width: 150px;
    height: 150px;
    top: 50%;
    left: 20px;
    overflow: hidden;
    border-radius: 50%;
    transform: translateY(-50%);
  }
  #page-news .newsBlock .row .col12 .news .imageWrapper:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(122, 167, 127, 0.35);
  }
  #page-news .newsBlock .row .col12 .news .imageWrapper .image {
    position: absolute;
    top: -25px;
    right: 0;
    bottom: -25px;
    left: 0;
  }
  #page-news .newsBlock .row .col12 .news .info {
    padding: 0 120px 0 200px;
    height: 200px;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  #page-news .newsBlock .row .col12 .news .info div {
    width: 100%;
  }
  #page-news .newsBlock .row .col12 .news .info h5 {
    margin-top: 10px;
  }
  #page-news .newsBlock .row .col12 .news .arrowWrapper {
    height: 14px;
    top: 50%;
    right: 30px;
    transform: translateY(-50%);
  }
  #page-news .newsBlock .row .col12 .news .arrowWrapper svg {
    stroke: #3c3d40;
  }
  #page-news .newsBlock .row .col12 .news .arrowWrapper svg use {
    stroke: #3c3d40;
  }
  #page-news .newsBlock .row .col12 .news:hover:before {
    width: 85px;
  }
  #page-news .newsBlock .row .col12 .news:hover .arrowWrapper {
    width: 30px;
  }
  #page-news .newsBlock .row .col12 .news .imageWrapper {
    margin-bottom: 0;
  }
  #page-news .newsBlock .row .col12 .news .info .subInfo {
    position: static;
  }
}
@media screen and (max-width: 600px) {
  #page-news .newsBlock .row .col12 .news {
    max-width: 400px;
    margin: 0 auto;
  }
}
#page-news .newsBlock .row .btn {
  margin-top: 20px;
}
@media screen and (max-width: 1300px) {
  #page-news .newsBlock .row .btn {
    margin-top: 75px;
  }
}
@media screen and (max-width: 1024px) {
  #page-news .newsBlock .row .btn {
    margin-top: 50px;
  }
}
@media screen and (max-width: 768px) {
  #page-news .newsBlock .row .btn {
    margin-top: 30px;
  }
}
@media screen and (max-width: 600px) {
  #page-news .newsBlock .row .btn {
    margin-top: 20px;
  }
}

#page-newsDetail .similarBlock h3 {
  margin-bottom: 30px;
}
@media screen and (max-width: 600px) {
  #page-newsDetail .similarBlock h3 {
    text-align: center;
  }
}
@media screen and (min-width: 601px) {
  #page-newsDetail .similarBlock .news {
    max-width: 100%;
    padding: 0;
    background: #fff;
  }
  #page-newsDetail .similarBlock .news:before {
    content: "";
    position: absolute;
    width: 60px;
    top: 0;
    right: 0;
    bottom: 0;
    background: #7aa77f;
    transition: width 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
  #page-newsDetail .similarBlock .news .imageWrapper {
    position: absolute;
    width: 150px;
    height: 150px;
    top: 50%;
    left: 20px;
    overflow: hidden;
    border-radius: 50%;
    transform: translateY(-50%);
  }
  #page-newsDetail .similarBlock .news .imageWrapper:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(122, 167, 127, 0.35);
  }
  #page-newsDetail .similarBlock .news .imageWrapper .image {
    position: absolute;
    top: -25px;
    right: 0;
    bottom: -25px;
    left: 0;
  }
  #page-newsDetail .similarBlock .news .info {
    padding: 0 120px 0 200px;
    height: 200px;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  #page-newsDetail .similarBlock .news .info div {
    width: 100%;
  }
  #page-newsDetail .similarBlock .news .info h5 {
    margin-top: 10px;
  }
  #page-newsDetail .similarBlock .news .arrowWrapper {
    height: 14px;
    top: 50%;
    right: 30px;
    transform: translateY(-50%);
  }
  #page-newsDetail .similarBlock .news .arrowWrapper svg {
    stroke: #3c3d40;
  }
  #page-newsDetail .similarBlock .news .arrowWrapper svg use {
    stroke: #3c3d40;
  }
  #page-newsDetail .similarBlock .news:hover:before {
    width: 85px;
  }
  #page-newsDetail .similarBlock .news:hover .arrowWrapper {
    width: 30px;
  }
  #page-newsDetail .similarBlock .news .imageWrapper {
    margin-bottom: 0;
  }
  #page-newsDetail .similarBlock .news .info .subInfo {
    position: static;
  }
}
@media screen and (max-width: 600px) {
  #page-newsDetail .similarBlock .news {
    max-width: 400px;
    margin: 0 auto;
  }
}
#page-newsDetail .similarBlock .news:not(:last-child) {
  margin-bottom: 10px;
}

#page-documents .twoColBlock .documentsBlock {
  position: relative;
}
#page-documents .twoColBlock .documentsBlock .select-field {
  margin: 80px 0 0 0;
  max-width: 300px;
}
@media screen and (max-width: 768px) {
  #page-documents .twoColBlock .documentsBlock .select-field {
    margin-top: 50px;
  }
}
#page-documents .twoColBlock .documentsBlock .documentsWrapper > div {
  margin-top: 80px;
}
@media screen and (max-width: 768px) {
  #page-documents .twoColBlock .documentsBlock .documentsWrapper > div {
    margin-top: 50px;
  }
}
#page-documents .twoColBlock .documentsBlock .documentsWrapper > div:first-child {
  margin-top: 50px;
}
#page-documents .twoColBlock .documentsBlock .documentsWrapper > div h3 {
  margin-bottom: 50px;
}
@media screen and (max-width: 1024px) {
  #page-documents .twoColBlock .documentsBlock .documentsWrapper > div h3 {
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 768px) {
  #page-documents .twoColBlock .documentsBlock .documentsWrapper > div h3 {
    margin-bottom: 30px;
  }
}

#page-rubric .twoColBlock .text .rubrics {
  margin-top: 80px;
}
@media screen and (max-width: 768px) {
  #page-rubric .twoColBlock .text .rubrics {
    margin-top: 50px;
  }
}

#page-search .headerPage {
  position: relative;
  z-index: 1;
  background: #efeae0;
}
#page-search .headerPage .select-field {
  max-width: 300px;
  margin-bottom: 0;
}
#page-search .searchBlock {
  background: #efeae0;
}
#page-search .searchBlock ul li {
  display: block;
  border-bottom: 1px solid rgba(62, 62, 45, 0.4);
}
#page-search .searchBlock ul li:not(:last-child) {
  margin-bottom: 50px;
}
#page-search .searchBlock ul li a {
  margin-bottom: 50px;
}
@media screen and (max-width: 1024px) {
  #page-search .searchBlock ul li a {
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 768px) {
  #page-search .searchBlock ul li a {
    margin-bottom: 30px;
  }
}
#page-search .searchBlock ul li a mark {
  background: rgba(122, 167, 127, 0.4);
}
#page-search .searchBlock ul li a .subInfo {
  margin-bottom: 10px;
  color: #7aa77f;
}
#page-search .searchBlock ul li a h4 {
  margin-bottom: 30px;
}
@media screen and (max-width: 768px) {
  #page-search .searchBlock ul li a h4 {
    margin-bottom: 20px;
  }
}
#page-search .searchBlock ul li a h4 mark {
  color: #3e3e2d;
}
#page-search .searchBlock ul li a p:not(.btn):not(.subInfo) {
  margin-bottom: 40px;
  line-height: 2;
}
@media screen and (max-width: 1024px) {
  #page-search .searchBlock ul li a p:not(.btn):not(.subInfo) {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 768px) {
  #page-search .searchBlock ul li a p:not(.btn):not(.subInfo) {
    margin-bottom: 20px;
  }
}
#page-search .searchBlock ul li a p:not(.btn):not(.subInfo) mark {
  color: #3e3e2d;
}
#page-search .searchBlock ul li a .btn {
  display: inline-block;
  margin-bottom: 0;
}
#page-search .searchBlock ul li a:hover .btn {
  color: #fff;
  background: #7aa77f;
  border-color: #7aa77f;
}

#page-jobs .jobsBlock {
  position: relative;
  margin-top: 80px;
  min-height: 400px;
}
@media screen and (max-width: 1024px) {
  #page-jobs .jobsBlock {
    min-height: 100%;
  }
}
@media screen and (max-width: 768px) {
  #page-jobs .jobsBlock {
    margin-top: 50px;
  }
}
#page-jobs .jobsBlock .jobsWrapper > div:not(first-child) {
  margin-top: 80px;
}
@media screen and (max-width: 768px) {
  #page-jobs .jobsBlock .jobsWrapper > div:not(first-child) {
    margin-top: 50px;
  }
}
#page-jobs .jobsBlock .jobsWrapper > div h3 {
  margin-bottom: 50px;
}
@media screen and (max-width: 1024px) {
  #page-jobs .jobsBlock .jobsWrapper > div h3 {
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 768px) {
  #page-jobs .jobsBlock .jobsWrapper > div h3 {
    margin-bottom: 30px;
  }
}

#page-job .formBlock h3 {
  margin-bottom: 40px;
}
@media screen and (max-width: 768px) {
  #page-job .formBlock h3 {
    margin-bottom: 30px;
  }
}
#page-job .formBlock .fileWrapper {
  margin-top: 60px;
}
@media screen and (max-width: 1024px) {
  #page-job .formBlock .fileWrapper {
    margin-top: 50px;
  }
}
@media screen and (max-width: 768px) {
  #page-job .formBlock .fileWrapper {
    margin-top: 30px;
  }
}
#page-job .formBlock .fileWrapper > :last-child {
  margin-top: 20px;
}
#page-job .formBlock form > :last-child {
  margin-top: 50px;
}
@media screen and (max-width: 1024px) {
  #page-job .formBlock form > :last-child {
    margin-top: 40px;
  }
}
@media screen and (max-width: 768px) {
  #page-job .formBlock form > :last-child {
    margin-top: 30px;
  }
}

#page-events .twoColBlock h1 {
  margin-bottom: 0;
}
@media screen and (max-width: 1024px) {
  #page-events .twoColBlock .eventsBlock .dateFiltered {
    margin-top: 30px;
  }
}
#page-events .twoColBlock .eventsBlock .select-field {
  display: inline-block;
}
#page-events .twoColBlock .eventsBlock .btnCalendar {
  position: relative;
  display: inline-block;
  margin-left: 20px;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background: #fff;
  vertical-align: middle;
  transition: background 300ms;
  opacity: 0;
  visibility: hidden;
}
@media screen and (max-width: 1024px) {
  #page-events .twoColBlock .eventsBlock .btnCalendar {
    opacity: 1;
    visibility: visible;
  }
}
@media screen and (max-width: 500px) {
  #page-events .twoColBlock .eventsBlock .btnCalendar {
    margin-left: 0;
    margin-top: 20px;
    display: block;
  }
}
#page-events .twoColBlock .eventsBlock .btnCalendar svg {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 30px;
  fill: #7aa77f;
  transform: translate(-50%, -50%);
}
#page-events .twoColBlock .eventsBlock .btnCalendar svg use {
  fill: #7aa77f;
  transition: fill 300ms;
}
#page-events .twoColBlock .eventsBlock .btnCalendar:hover {
  background: #7aa77f;
}
#page-events .twoColBlock .eventsBlock .btnCalendar:hover svg {
  fill: #fff;
}
#page-events .twoColBlock .eventsBlock .btnCalendar:hover svg use {
  fill: #fff;
}
#page-events .twoColBlock .eventsBlock .event {
  margin-top: 60px;
}
@media screen and (max-width: 1024px) {
  #page-events .twoColBlock .eventsBlock .event {
    margin-top: 50px;
  }
}
@media screen and (max-width: 768px) {
  #page-events .twoColBlock .eventsBlock .event {
    margin-top: 30px;
  }
}
#page-events .twoColBlock .eventsBlock .event .imageWrapper .image {
  height: 400px;
}
@media screen and (max-width: 1200px) {
  #page-events .twoColBlock .eventsBlock .event .imageWrapper .image {
    height: 350px;
  }
}
@media screen and (max-width: 1024px) {
  #page-events .twoColBlock .eventsBlock .event .imageWrapper .image {
    height: 400px;
  }
}
@media screen and (max-width: 600px) {
  #page-events .twoColBlock .eventsBlock .event .imageWrapper .image {
    height: 350px;
  }
}
@media screen and (max-width: 500px) {
  #page-events .twoColBlock .eventsBlock .event .imageWrapper .image {
    height: 300px;
  }
}
@media screen and (max-width: 400px) {
  #page-events .twoColBlock .eventsBlock .event .imageWrapper .image {
    height: 250px;
  }
}
#page-events .twoColBlock .eventsBlock .event .flex {
  margin: 0;
}
#page-events .twoColBlock .eventsBlock .event .flex .row {
  padding: 45px 60px;
}
@media screen and (max-width: 1200px) {
  #page-events .twoColBlock .eventsBlock .event .flex .row {
    padding: 40px 60px;
  }
}
@media screen and (max-width: 1100px) {
  #page-events .twoColBlock .eventsBlock .event .flex .row {
    padding: 40px 40px;
  }
}
@media screen and (max-width: 1024px) {
  #page-events .twoColBlock .eventsBlock .event .flex .row {
    padding: 30px 30px;
  }
}
@media screen and (max-width: 768px) {
  #page-events .twoColBlock .eventsBlock .event .flex .row {
    padding: 30px 20px;
    text-align: left;
  }
}
@media screen and (max-width: 600px) {
  #page-events .twoColBlock .eventsBlock .event .flex .row {
    padding: 30px 15px;
  }
}
#page-events .twoColBlock .eventsBlock .event .flex .row .btn {
  margin-top: 20px;
  display: inline-block;
}
#page-events .twoColBlock .eventsBlock #eventsMoreBtn {
  padding-top: 60px;
}
#page-events .twoColBlock .sideNav {
  background: #fff;
  width: 36%;
}
@media screen and (max-width: 1400px) {
  #page-events .twoColBlock .sideNav {
    width: 40%;
  }
}
#page-events .twoColBlock .sideNav .calendar {
  position: relative;
  padding: 80px;
  z-index: 1;
}
@media screen and (max-width: 1500px) {
  #page-events .twoColBlock .sideNav .calendar {
    padding: 80px 50px;
  }
}
#page-events .twoColBlock .sideNav .sideNavWrapper {
  position: absolute;
  top: 450px;
  right: 0;
  bottom: 0;
  left: 0;
}

#page-event .twoColBlock .headerPage .eventInfo {
  margin-bottom: 40px;
}
@media screen and (max-width: 768px) {
  #page-event .twoColBlock .headerPage .eventInfo {
    margin-bottom: 30px;
  }
}
#page-event .twoColBlock .headerPage .eventInfo p {
  font: 16px/1 "hinted-Geomanist-Book", sans-serif;
  color: #7aa77f;
  text-transform: uppercase;
  letter-spacing: 1px;
}
#page-event .twoColBlock .headerPage .eventInfo p:not(:last-child) {
  margin-bottom: 5px;
}
#page-event .twoColBlock .headerPage .eventInfo p:last-child {
  color: #3e3e2d;
}

#page-section .headerPage {
  background: #efeae0;
}
#page-section .headerPage h1 {
  margin-bottom: 0;
}
#page-section .sectionBlock {
  background: #efeae0;
}
#page-section .sectionBlock .sectionWrapper {
  display: flex;
  flex-flow: row wrap;
}
#page-section .sectionBlock .sectionWrapper .flexItem {
  width: 25%;
  padding: 0 1%;
}
#page-section .sectionBlock .sectionWrapper .flexItem:nth-child(-n+4) {
  margin-top: 0;
}
#page-section .sectionBlock .sectionWrapper .flexItem:not(:nth-child(-n+4)) {
  margin-top: 50px;
}
@media screen and (max-width: 1600px) {
  #page-section .sectionBlock .sectionWrapper .flexItem {
    width: 33.3333%;
  }
  #page-section .sectionBlock .sectionWrapper .flexItem:nth-child(-n+3) {
    margin-top: 0;
  }
  #page-section .sectionBlock .sectionWrapper .flexItem:not(:nth-child(-n+3)) {
    margin-top: 50px;
  }
}
@media screen and (max-width: 1300px) {
  #page-section .sectionBlock .sectionWrapper .flexItem:nth-child(-n+3) {
    margin-top: 0;
  }
  #page-section .sectionBlock .sectionWrapper .flexItem:not(:nth-child(-n+3)) {
    margin-top: 40px;
  }
}
@media screen and (max-width: 900px) {
  #page-section .sectionBlock .sectionWrapper .flexItem {
    width: 50%;
  }
  #page-section .sectionBlock .sectionWrapper .flexItem:nth-child(-n+2) {
    margin-top: 0;
  }
  #page-section .sectionBlock .sectionWrapper .flexItem:not(:nth-child(-n+2)) {
    margin-top: 30px;
  }
}
@media screen and (max-width: 600px) {
  #page-section .sectionBlock .sectionWrapper .flexItem {
    width: 100%;
  }
  #page-section .sectionBlock .sectionWrapper .flexItem:nth-child(-n+1) {
    margin-top: 0;
  }
  #page-section .sectionBlock .sectionWrapper .flexItem:not(:nth-child(-n+1)) {
    margin-top: 20px;
  }
}
#page-section .sectionBlock .sectionWrapper .flexItem a {
  display: block;
}
#page-section .sectionBlock .sectionWrapper .flexItem a .imageWrapper {
  position: relative;
  height: 225px;
}
#page-section .sectionBlock .sectionWrapper .flexItem a .imageWrapper .image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
#page-section .sectionBlock .sectionWrapper .flexItem a .imageWrapper .image:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(122, 167, 127, 0);
  transition: background 300ms;
}
#page-section .sectionBlock .sectionWrapper .flexItem a span {
  display: block;
  margin-top: 10px;
  font: 25px/1.1 "Financier_Display_Semibold", serif;
  color: #3e3e2d;
  transition: color 300ms;
}
#page-section .sectionBlock .sectionWrapper .flexItem:hover .imageWrapper .image:after {
  background: rgba(122, 167, 127, 0.4);
}
#page-section .sectionBlock .sectionWrapper .flexItem:hover span {
  color: #7aa77f;
}

#page-contact .contactBlock {
  position: relative;
  background: #efeae0;
}
#page-contact .contactBlock .text {
  min-height: 1000px;
}
@media screen and (max-width: 1024px) {
  #page-contact .contactBlock .text {
    min-height: 100%;
  }
}
#page-contact .contactBlock .text .contactInfo {
  margin-bottom: 90px;
}
@media screen and (max-width: 1024px) {
  #page-contact .contactBlock .text .contactInfo {
    margin-bottom: 60px;
  }
}
@media screen and (max-width: 768px) {
  #page-contact .contactBlock .text .contactInfo {
    margin-bottom: 50px;
  }
}
#page-contact .contactBlock .text .contactInfo .tel {
  margin-bottom: 30px;
  font: 25px/1 "hinted-Geomanist-Medium", sans-serif;
  color: #7aa77f;
}
@media screen and (max-width: 1024px) {
  #page-contact .contactBlock .text .contactInfo .tel {
    margin-bottom: 20px;
  }
}
#page-contact .contactBlock .text .contactInfo h5 {
  margin-bottom: 30px;
}
@media screen and (max-width: 1024px) {
  #page-contact .contactBlock .text .contactInfo h5 {
    margin-bottom: 20px;
  }
}
#page-contact .contactBlock .text .contactInfo .adresse {
  margin-bottom: 40px;
}
@media screen and (max-width: 1024px) {
  #page-contact .contactBlock .text .contactInfo .adresse {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 768px) {
  #page-contact .contactBlock .text .contactInfo .adresse {
    margin-bottom: 20px;
  }
}
#page-contact .contactBlock .text .contactInfo .btn {
  margin-top: 50px;
}
@media screen and (max-width: 1024px) {
  #page-contact .contactBlock .text .contactInfo .btn {
    margin-top: 40px;
  }
}
@media screen and (max-width: 768px) {
  #page-contact .contactBlock .text .contactInfo .btn {
    margin-top: 30px;
  }
}
#page-contact .contactBlock .form {
  position: absolute;
  width: 45%;
  top: 150px;
  right: 0;
  bottom: 0;
}
@media screen and (max-width: 1024px) {
  #page-contact .contactBlock .form {
    position: relative;
    width: calc(100% + 100px);
    margin: 80px -50px 0 -50px;
    top: 0;
  }
}
@media screen and (max-width: 768px) {
  #page-contact .contactBlock .form {
    width: calc(100% + 60px);
    margin: 70px -30px 0 -30px;
  }
}
@media screen and (max-width: 600px) {
  #page-contact .contactBlock .form {
    width: calc(100% + 40px);
    margin: 70px -20px 0 -20px;
  }
}
#page-contact .contactBlock .form > div {
  padding: 80px 100px;
  background: #fff;
}
@media screen and (max-width: 1500px) {
  #page-contact .contactBlock .form > div {
    padding: 60px 70px;
  }
}
@media screen and (max-width: 1200px) {
  #page-contact .contactBlock .form > div {
    padding: 60px 40px;
  }
}
@media screen and (max-width: 1024px) {
  #page-contact .contactBlock .form > div {
    padding: 60px 50px;
  }
}
@media screen and (max-width: 768px) {
  #page-contact .contactBlock .form > div {
    padding: 50px 30px;
  }
}
@media screen and (max-width: 600px) {
  #page-contact .contactBlock .form > div {
    padding: 40px 20px;
  }
}
#page-contact .contactBlock .form > div h3 {
  margin-bottom: 60px;
}
@media screen and (max-width: 1024px) {
  #page-contact .contactBlock .form > div h3 {
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 768px) {
  #page-contact .contactBlock .form > div h3 {
    margin-bottom: 30px;
  }
}
#page-contact .contactBlock .form > div .btn {
  margin-top: 60px;
}
@media screen and (max-width: 1024px) {
  #page-contact .contactBlock .form > div .btn {
    margin-top: 40px;
  }
}
@media screen and (max-width: 768px) {
  #page-contact .contactBlock .form > div .btn {
    margin-top: 30px;
  }
}

#page-error .twoColBlock {
  z-index: 2;
}
#page-error .twoColBlock h4 {
  margin-bottom: 30px;
  max-width: 500px;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}
.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}
.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}
[dir=rtl] .slick-prev {
  left: auto;
  right: -25px;
}
.slick-prev:before {
  content: "\2190";
}
[dir=rtl] .slick-prev:before {
  content: "\2192";
}

.slick-next {
  right: -25px;
}
[dir=rtl] .slick-next {
  left: -25px;
  right: auto;
}
.slick-next:before {
  content: "\2192";
}
[dir=rtl] .slick-next:before {
  content: "\2190";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "\2022";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}
